export default (axios) => ({
  // CRUD functions
  list(payload) {
    return axios.get('v1/agreements', { params: payload })
  },
  create(data) {
    return axios.post('v1/agreements', data)
  },
  update(id, data) {
    return axios.put(`v1/agreements/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/agreements/${id}`)
  },
  // NO-CRUD functions
  availableClients(id, payload) {
    return axios.get(`v1/agreements/${id}/clients/available`, { params: payload })
  },
  listClients(id, payload) {
    return axios.get(`v1/agreements/${id}/clients`, { params: payload })
  },
  addClient(id, data) {
    return axios.post(`v1/agreements/${id}/clients`, data)
  },
  updateClient(id, clientId, data) {
    return axios.put(`v1/agreements/${id}/clients/${clientId}`, data)
  },
  destroyClient(id, clientId) {
    return axios.delete(`v1/agreements/${id}/clients/${clientId}`)
  },
  selectCreditNote() {
    return axios.post('v1/agreements/select_credit_note')
  },
  getTotals(id, payload) {
    return axios.get(`v1/agreements/${id}/totals`, { params: payload })
  },
  markCreditNoteAsAttended(id, client_id) {
    return axios.post(`v1/agreements/${id}/clients/${client_id}/credit_note`)
  }
})
