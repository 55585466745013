// useInternetStatus.js
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Swal from 'sweetalert2'

export default function useInternetStatus() {
  const online = ref(navigator.onLine)

  const updateOnlineStatus = (e) => {
    online.value = e.type === 'online'
  }

  watch(online, (newStatus) => {
    Swal.fire({
      timer: 2000,
      position: 'top',
      showConfirmButton: false,
      text: newStatus
        ? 'De nuevo en línea, conexión a internet funcionando'
        : 'No se detecta conexión a internet.',
      icon: newStatus ? 'success' : 'error'
    })
  })

  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
  })

  onUnmounted(() => {
    window.removeEventListener('online', updateOnlineStatus)
    window.removeEventListener('offline', updateOnlineStatus)
  })

  return { online, updateOnlineStatus }
}
