<template>
  <div>
    <div ref="myDropdown" class="dropdown dropdownTableActions" id="myDropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <font-awesome-icon class="text-secondary" :icon="['fa', 'ellipsis']" size="lg" />
      </button>
      <ul class="dropdown-menu">
        <template
          v-for="(option, index) in iconsRender"
          :key="'DIV' + index + option.key + Math.floor(Math.random() * 999)"
        >
          <li
            class="d-flex align-items-center justify-content-end cursor-pointer px-4"
            @click="$emit('action', option.action, info)"
            :class="option?.class"
          >
            {{ option.text }}
            <font-awesome-icon
              :icon="validIcon(option)"
              :class="[activeClass(option, info)]"
              class="cursor-pointer ms-3"
              :size="option.iconSize"
            />
          </li>
          <hr
            v-if="index < iconsRender.length - 1"
            :key="'HR' + index + option.key + Math.floor(Math.random() * 999)"
          />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointsMenu',
  props: {
    tableLength: {
      type: Number,
      required: false
    },
    info: {
      type: Object,
      required: false,
      default: () => {}
    },
    iconsRender: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.$refs.myDropdown.addEventListener('shown.bs.dropdown', this.adjustDropdownPosition)
    this.$refs.myDropdown.addEventListener('hide.bs.dropdown', this.resetDropdownPosition)
  },
  methods: {
    validIcon(option) {
      return [option.iconType, option.icon]
    },
    adjustDropdownPosition() {
      const tdOfDropdown = this.$refs.myDropdown.closest('td')

      if (tdOfDropdown) {
        tdOfDropdown.style.position = 'absolute'
        const tdIndex = Array.from(tdOfDropdown.parentNode.children).indexOf(tdOfDropdown)
        const thOfDropdown = tdOfDropdown.closest('table').querySelector('thead tr').children[
          tdIndex
        ]
        const boundsOfTh = thOfDropdown.getBoundingClientRect()
        const halfWidthTh = boundsOfTh.width / 2
        tdOfDropdown.style.right = `${boundsOfTh.width - 10 - halfWidthTh}px`
      }
    },
    resetDropdownPosition() {
      const tdOfDropdown = this.$refs?.myDropdown?.closest('td')

      if (tdOfDropdown) {
        tdOfDropdown.style.position = ''
        tdOfDropdown.style.right = ''
      }
    },
    activeClass(option, info) {
      if (option?.activeClass) {
        return info[option?.activeClass] ? 'text-green-active' : 'text-500'
      }
      return 'text-dark'
    }
  }
}
</script>
