<template>
  <div class="d-lg-block d-none col-md-4 col-lg row flex-column align-items-center">
    <div class="text-start">
      <div class="text-start text-xl-center">
        <img
          :src="logoFooter"
          alt=""
          class="mb-4 img-fluid logoFooter"
          :class="{ 'whiteImage': brandName === 'fastenal' }"
        />
      </div>
      <div class="d-flex justify-content-around align-items-center">
        <div></div>
        <div v-for="network in socialNetworks" :key="`${network.title}-${network.icon}`">
          <a :href="network.url" target="_blank">
            <h1 class="footerComponent__main--white cursor-pointer scaleHover">
              <font-awesome-icon :icon="network.icon" />
            </h1>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-lg-block d-none col-md-4 col-lg d-flex flex-column align-items-center"
    v-for="section in sectionOrder"
    :key="`${section}-${Math.floor(Math.random() * 999)}`"
  >
    <div class="text-start">
      <h5 class="footerComponent__main--title mb-4">{{ sections[section].titleSection }}</h5>
      <h5
        class="mb-3 cursor-pointer"
        v-for="option in sections[section].options"
        :key="`${option.key}-${option.key}-${Math.floor(Math.random() * 999)}`"
      >
        <router-link
          v-if="!option?.anotherLogic"
          :to="{ name: routeNameByAuth(option), query: option?.queryParams || '' }"
          >{{ option.title }}</router-link
        >
        <span v-else @click="actionEmit(option.action)">{{ option.title }}</span>
      </h5>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useConfigStore } from '@/stores/config'

const brandName = import.meta.env.VITE_BRAND_NAME
const configStore = useConfigStore()
const authStore = useAuthStore()
const logoFooter = ref('')
const isLoggedIn = computed(() => authStore.isLoggedIn)
const props = defineProps({
  sections: { type: Object, required: true },
  socialNetworks: { type: Object, required: true },
  sectionOrder: { type: Array, required: true }
})

const { sections, socialNetworks, sectionOrder } = toRefs(props)

const routeNameByAuth = (option) => {
  if (isLoggedIn.value) {
    return option.routeName
  }
  return option.routeNamePublic
}

const emit = defineEmits(['infoVideo'])

const actionEmit = (action) => {
  emit(action)
}

onMounted(async () => {
  const logoPath = await configStore.findLogoImage('logoFooter')
  logoFooter.value = logoPath || '@/assets/images/default.png'
})
</script>
