<script setup></script>

<template>
  <a href="https://wa.me/5218111059093" class="whatsapp" target="_blank">
    <div class="whatsAppButton scaleHover">
      <div class="layoutContainer whatsAppContainer">
        <h1 class="m-0 cursor-pointer">
          <font-awesome-icon size="2x" icon="fa-brands fa-whatsapp" />
        </h1>
      </div>
    </div>
  </a>
</template>
