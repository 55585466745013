<template>
  <CardBase
    :classCard="'border-0 shadow-sm rounded-4 p-0 vh-40 position-relative'"
    :classCardBody="'position-relative'"
    @click="toggleShowBackorderBG"
  >
    <template #body>
      <OrdersAccountList
        ref="ordersAccountList"
        :totalRecords="totalRecords"
        :activeView="activeView"
        :limit="limit"
        :info="info"
        :records="records"
        :backOrderSelected="backOrderSelected"
        @action="actionPoint"
        @changePage="changePage"
        @bgBackorder="toggleShowBackorderBG"
      />
    </template>
  </CardBase>
</template>

<script setup>
import OrdersAccountList from '@/components/account/OrdersAccountList.vue'
import { computed, ref, toRefs } from 'vue'
import CardBase from '@/components/shared/CardBase.vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const ordersAccountList = ref(null)
const isSuperadmin = computed(() => userStore.isSuperadmin)
const props = defineProps({
  totalRecords: { type: Number, required: true },
  activeView: { type: String, required: true },
  limit: { type: Number, required: true },
  info: { type: Object, required: true },
  records: { type: Array, required: true },
  backOrderSelected: { type: Array, required: false, default: () => [] }
})

const emit = defineEmits(['action', 'changePage', 'bgBackorder'])

const { totalRecords, limit, records, activeView, backOrderSelected } = toRefs(props)

let clickCount = 0
const CLICK_THRESHOLD = 4 // Number of clicks required
const bgBackorderToogle = ref(false)

const toggleShowBackorderBG = () => {
  if (!isSuperadmin.value || activeView.value !== 'orders') return
  clickCount++
  if (clickCount === CLICK_THRESHOLD) {
    bgBackorderToogle.value = !bgBackorderToogle.value
    emit('bgBackorder', { bgBackorder: bgBackorderToogle.value })
    clickCount = 0 // Reset click count after action
  }
}

const changePage = (page) => {
  emit('changePage', page)
  window.scrollTo(0, 0)
}

const resetSelectedBackorders = () => {
  ordersAccountList.value.resetSelectedBackorders()
}

const actionPoint = (data) => {
  emit('action', data)
}

defineExpose({
  resetSelectedBackorders
})
</script>
