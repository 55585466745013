<template>
  <GeneralModal
    ref="modalRefBackorder"
    @shown="onShownModal"
    @hidden="onHideModal"
    hideHeader
    hideTitle
    size="xl"
    modalContentClass="p-5"
    hideHeaderClose
    hideFooter
    noCloseOnBackdrop
    noCloseOnEsc
  >
    <template #bodyContent>
      <div v-if="isHelpVisible">
        <VideoContainer :videoSrc="videoSrc" title="Video de ayuda" />
        <div>
          <button class="btn btn-primary-app" @click="hideVideoHelp">Regresar</button>
        </div>
      </div>
      <OrdersAccountList
        v-show="!isHelpVisible"
        ref="ordersAccountList"
        hidePointsMenu
        hidePagination
        @deactiveBackOrders="deactiveOrdersSelected"
        @showHelp="showHelp"
        :totalRecords="totalOrders"
        :activeView="activeView"
        :limit="limit"
        :records="orders"
        :backOrderSelected="backOrderSelected"
      />
    </template>
  </GeneralModal>
</template>
<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import OrdersAccountList from '@/components/account/OrdersAccountList.vue'
import VideoContainer from '@/components/shared/VideoContainer.vue'
import { instance, factories } from '@/api'
import { ref, toRefs } from 'vue'

const props = defineProps({
  totalOrders: { type: Number, required: true },
  orders: { type: Array, required: true }
})

const { totalOrders, orders } = toRefs(props)

const api = factories(instance, ['order'])
const limit = ref(0)
const activeView = ref('backorder')
const backOrderSelected = ref([])
const modalRefBackorder = ref(null)
const ordersAccountList = ref(null)
const isHelpVisible = ref(false)
const videoSrc = ref('')

const showHelp = () => {
  videoSrc.value = 'https://www.youtube.com/embed/FMUxnAm71Tg?autoplay=1'
  isHelpVisible.value = true
}

const hideVideoHelp = () => {
  videoSrc.value = ''
  isHelpVisible.value = false
}

const onShownModal = () => {
  backOrderSelected.value = []
  ordersAccountList.value.resetSelectedBackorders()
}

const deactiveOrdersSelected = (payload) => {
  api.order.deactiveBackOrders(payload).then(() => {
    modalRefBackorder.value.hide()
  })
}

const onHideModal = () => {
  backOrderSelected.value = []
  ordersAccountList.value.resetSelectedBackorders()
}

defineExpose({
  openModal: () => {
    modalRefBackorder.value.show()
  },
  hideModal: () => {
    modalRefBackorder.value.hide()
  }
})
</script>
