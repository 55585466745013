import numeric from '@/helpers/directives/numeric'
import uppercase from '@/helpers/directives/uppercase'
import lowercase from '@/helpers/directives/lowercase'
import debounce from '@/helpers/directives/debounce'
import float from '@/helpers/directives/float'
import minValue from '@/helpers/directives/minValue'
import limitChars from '@/helpers/directives/limitChars'
import phoneNumber from '@/helpers/directives/phoneNumber'
import clearInput from '@/helpers/directives/clearInput'
import phoneInput from '@/helpers/directives/phoneInput'

const directives = {
  numeric,
  uppercase,
  lowercase,
  debounce,
  float,
  minValue,
  limitChars,
  phoneNumber,
  clearInput,
  phoneInput
}

export default {
  install: (app) => {
    for (const directive in directives) {
      app.directive(directive, directives[directive])
    }
  }
}
