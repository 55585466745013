<template>
  <transition name="fade">
    <div v-if="isVisible" class="backdrop" @click="toggleSidebar"></div>
  </transition>
  <transition name="slide">
    <div v-if="isVisible" class="shopping-cart-sidebar">
      <div class="sidebar-content d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="me-3">{{ sideCartMenuConfig.title }}</h4>
            <a class="link-offset-2 cursor-pointer" @click="clearCart">
              {{ sideCartMenuConfig.labelEmptyCart }}
            </a>
          </div>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="toggleSidebar"
          ></button>
        </div>
        <ProgressBar
          v-if="progressBarRequired && !getBalanceLogicState"
          minimunLogic
          :containerBaseClasses="'alert p-3 mx-2 mt-2'"
          :progressClass="'progress mb-3 mt-2'"
          :logicClass="'alert-warning'"
          :logicAcomplishedClass="'alert-success'"
          :logicData="minimumPurchase"
          :total="shoppingStore.cartTotalPrice"
          :messageLogicAcomplished="messageLogicAcomplished"
          :formatCurrency="formatCurrency"
        />
        <ProgressBar
          v-if="requireProgressBarCredit && getBalanceLogicState"
          remainLogic
          :containerBaseClasses="'alert p-3 mx-2 mt-2 fs-5'"
          :progressClass="'progress mb-3 mt-2'"
          :logicClass="'alert-light'"
          :logicAcomplishedClass="'alert-warning'"
          :logicData="remainingBalance"
          :total="totalToPay"
          :userBalance="userBalance"
          :messageLogicAcomplished="messageLogicAcomplishedCredit"
          :messageLogicNotAcomplished="messageLogicNotAcomplishedCredit"
          :formatCurrency="formatCurrency"
        />

        <div class="flex-grow-1 p-3 overflow-auto">
          <ProductCartList
            hideOriginalFooter
            @showProductDetail="showProductDetail"
            @addOne="addOne"
            @changeAmount="changeAmount"
            @subtractOne="subtractOne"
            @removeProduct="removeProduct"
            splitProductListEnd
          />
        </div>

        <CartSideMenuFooter :configFooterContent="footerContent" :classFooter="'p-3 border-top'" />
      </div>
    </div>
  </transition>
</template>

<script setup>
import ProductCartList from '@/components/products/ProductCartList.vue'
import Swal from 'sweetalert2'
import { computed, getCurrentInstance } from 'vue'
import { useShoppingStore } from '@/stores/shopping'
import { useConfigStore } from '@/stores/config'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import CartSideMenuFooter from '@/components/layout/cart/CartSideMenuFooter.vue'
import router from '@/router'

const configStore = useConfigStore()
const creditLogicState = computed(
  () => isLogicCreditBalanceActive.value && getBalanceLogicState.value
)
const minimumPurchase = computed(() => configStore.getMinimumPurchasePriceByBrand)
const footerValues = computed(() => sideCartMenuConfig?.value?.footerValues)
const sideCartMenuConfig = computed(() => configStore.getSideCartMenuConfig)
const isLogicCreditBalanceActive = computed(() => configStore.getIsLogicCreditBalanceByUserActive)
const progressBarRequired = computed(() => sideCartMenuConfig?.value?.progressBarRequired)
const requireProgressBarCredit = computed(() => configStore.getRequiredProgressBarCredit)
const messageLogicAcomplished = computed(() => {
  return sideCartMenuConfig?.value?.messageMinimumPurchasePriceAcomplished
})
const messageLogicAcomplishedCredit = computed(() => {
  return `<span>${sideCartMenuConfig?.value?.messageCreditBalanceByUserPart1} <strong>${formatCurrency(totalToPay.value)}</strong>${sideCartMenuConfig?.value?.messageCreditBalanceByUserPart2}</span>`
})
const messageLogicNotAcomplishedCredit = computed(
  () =>
    `<span>${sideCartMenuConfig?.value?.messageCreditBalanceByUserAvailable} <strong>${formatCurrency(
      remainingBalance.value
    )}</strong></span>`
)
const footerContent = computed(() => {
  const content = []
  let configData = []

  const discount = creditLogicState.value
    ? shoppingStore.cartTotalDiscount + (userBalance.value - remainingBalance.value)
    : shoppingStore.cartTotalDiscount
  const labelAndValue = {
    subtotal: {
      label: 'Subtotal',
      value: shoppingStore.cartSubTotalPrice
    },
    discount: {
      label: 'Descuento',
      classLabel: 'text-primary-app',
      classValue: 'text-primary-app',
      value: discount
    },
    total: {
      label: 'Total a pagar',
      classLabel: 'fw-bold',
      classValue: 'fw-bold',
      value: shoppingStore.cartTotalPrice
    },
    userBalance: {
      label: 'Saldo de bono',
      value: remainingBalance.value
    }
  }
  if (creditLogicState.value) {
    configData = ['userBalance', 'subtotal', 'discount', 'total']
  } else {
    configData = footerValues.value
  }
  configData.forEach((item) => {
    if (item === 'total' && creditLogicState.value) {
      content.push({
        label: labelAndValue[item].label,
        classLabel: labelAndValue[item]?.classLabel || '',
        value: formatCurrency(totalToPay.value < 0 ? 0 : totalToPay.value),
        classValue: labelAndValue[item]?.classValue || ''
      })
      return
    }
    content.push({
      label: labelAndValue[item].label,
      classLabel: labelAndValue[item]?.classLabel || '',
      value: formatCurrency(labelAndValue[item].value),
      classValue: labelAndValue[item]?.classValue || ''
    })
  })

  return content
})
const globalProperties = getCurrentInstance().appContext.config.globalProperties
const formatCurrency = globalProperties.$formatcurrency

const shoppingStore = useShoppingStore()

const primaryColor = computed(() => configStore.getPrimaryColorByBrand)

const isVisible = computed({
  get: () => shoppingStore.cartIsVisible,
  set: (newValue) => {
    shoppingStore.updateCartVisibility(newValue)
  }
})
const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)

const totalToPay = computed(() => shoppingStore.cartTotalPrice - userBalance.value)

const addOne = (id) => {
  shoppingStore.addOne(id)
}

const changeAmount = (id, amount) => {
  shoppingStore.changeAmount(id, amount)
}

const subtractOne = (id) => {
  shoppingStore.subtractOne(id)
}

const removeProduct = (index) => {
  shoppingStore.deleteItem(index)
}

const toggleSidebar = () => {
  isVisible.value = !isVisible.value
}

const showProductDetail = (id) => {
  router.push({ name: 'productDetail', params: { id } })
}

const clearCart = () => {
  const messageEmptyCart =
    sideCartMenuConfig?.value?.messageEmptyCart || '¿Estás seguro de vaciar el carrito?'
  const messageEmptyCartBtn = sideCartMenuConfig?.value?.messageEmptyCartBtn || 'Vaciar carrito'
  Swal.fire({
    title: messageEmptyCart,
    html: `
      <p>Todos los productos agregados serán eliminados y esta acción no se puede deshacer.</p>
      <p>¿Deseas continuar?</p>
    `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: primaryColor.value,
    cancelButtonColor: '#9499a1',
    confirmButtonText: messageEmptyCartBtn,
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      shoppingStore.emptyCart()
      shoppingStore.hideCart()
    }
  })
}
</script>
