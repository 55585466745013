const authHeaderBlackList = []

const loaderBlackList = []

const publicRoutes = [
  'login',
  'signup',
  'signupprospect',
  'loginpropects',
  'example_mail_order',
  'privacy_policy_public',
  'privacy_policy_public_2',
  'terms_and_conditions_public',
  'terms_and_conditions_public_2',
  'product_detail_public',
  'product_detail_public_pack_content',
  'product_detail_only_list_pack_public',
  'category_public',
  'about_public',
  'contact_public',
  'job_board_public',
  'not_found',
]

const userTypes = {}

export { loaderBlackList, publicRoutes, authHeaderBlackList, userTypes }
