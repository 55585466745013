export default {
  beforeMount(el) {
    // Definir handleInput fuera de beforeMount
    const handleInput = (event) => {
      const input = event.target.value.trim()
      if (input !== event.target.value) {
        event.target.value = input
        event.target.dispatchEvent(new Event('input'))
      }
    }

    // Agregar handleInput como propiedad de el para que sea accesible en unmounted
    el._handleInput = handleInput

    // Escuchar eventos
    el.addEventListener('input', handleInput)
    el.addEventListener('blur', handleInput)
  },
  unmounted(el) {
    // Remover listeners usando el._handleInput
    el.removeEventListener('input', el._handleInput)
    el.removeEventListener('blur', el._handleInput)

    // Limpiar la referencia
    delete el._handleInput
  }
}
