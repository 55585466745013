<template>
  <div>
    <div class="card border border-primary-app shadow-sm rounded-3 p-0 mt-5 position-relative">
      <div class="pb-4 px-4 bg-primary-app rounded-3 rounded-bottom-0 text-white">
        <p class="fw-semibold ms-3 pt-4 mb-0">Notas de crédito</p>
      </div>

      <div class="card-body px-1 m-0 pb-0 pt-2 position-relative">
        <TableHelper :fields="tableFields" :records="records">
          <template #cell(client_credit_date)="record">
            {{ $formatdatetime(record.client_credit_date, 'DD-MM-YYYY') }}
          </template>
          <template #cell(balance)="record"> {{ $formatcurrency(record.balance) }} </template>
          <template #cell(actions)="record">
            <PointsMenu @action="actionPoint" :iconsRender="iconsRenderByStatus" :info="record" />
          </template>
        </TableHelper>
        <PaginationHelper
          :showCountText="false"
          :activeClass="true"
          :justifyClass="'end'"
          :totalRecords="totalRecords"
          :limit="limit"
          @changePage="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'
import PointsMenu from '@/components/shared/PointsMenu.vue'
import TableHelper from '@/components/shared/TableHelper.vue'
import PaginationHelper from '@/components/shared/PaginationHelper.vue'

const props = defineProps({
  totalRecords: { type: Number, required: true },
  info: { type: Object, required: true },
  limit: { type: Number, required: true },
  records: { type: Array, required: true }
})

const emit = defineEmits(['changePage', 'actionPoint'])

const { totalRecords, limit, records } = toRefs(props)

const tableFields = [
  {
    key: 'client_credit_date',
    label: 'Fecha Nota de crédito',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'concept',
    label: 'Concepto',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'system_reference',
    label: 'Folio de sistema',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'dinamica_reference',
    label: 'Folio Dinámica',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'days',
    label: 'Días transcurridos',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'balance',
    label: 'Saldo',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'actions',
    label: 'Acciones',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  }
]

const iconsRenderByStatus = [
  {
    key: 'downloadInvoice',
    text: 'Descarga nota de crédito',
    icon: 'file-pdf',
    iconSize: 'lg',
    iconType: 'far',
    action: 'downloadInvoice'
  },
  {
    key: 'downloadPDFXML',
    text: 'Descargar zip PDF & XML',
    icon: 'file-zipper',
    iconSize: 'lg',
    iconType: 'fa',
    action: 'downloadPDFXML'
  }
]

function actionPoint(action, info) {
  emit('actionPoint', { action, info })
}

function changePage(page) {
  emit('changePage', { page })
  window.scrollTo(0, 0)
}
</script>

<style lang="scss" scoped>
.status-circle {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.status-circle-2 {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}
</style>
