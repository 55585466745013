export default {
  beforeMount(el) {
    el.addEventListener('input', () => {
      const value = parseFloat(el.value)
      // Solo actualizamos si el valor es menor a 1 para evitar ciclos innecesarios
      if (value < 1 || isNaN(value)) {
        el.value = 1
        setTimeout(() => {
          el.dispatchEvent(new Event('input')) // Dispatch after any Vue updates
        }, 0)
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
