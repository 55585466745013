<template>
  <h5 v-if="!hideTitle">{{ title }}</h5>
  <div class="video-container">
    <iframe
      width="auto"
      height="315"
      :src="videoSrc"
      :title="title"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  videoSrc: String,
  title: String,
  hideTitle: {
    type: Boolean,
    default: false
  }
})

const { videoSrc, title } = toRefs(props)
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin-bottom: 1rem;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
