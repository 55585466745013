<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <!-- Lottie Animation -->
        <Vue3Lottie ref="vue3LottieRef" :animationData="NotFoundLottie" v-if="!showDanceDuck" />
        <img :src="danceduck" alt="duckdance" class="img-fluid mb-1" v-else  />

        <!-- Not Found Message -->
        <h2 class="mb-4">
          - Página
          <span @click="toggleDanceDuck" @dblclick.prevent>no</span> encontrada -
        </h2>

        <!-- Botón de Regresar -->
        <router-link to="/" class="btn btn-primary-app" v-if="!hideBackBtn">
          <font-awesome-icon :icon="['fas', 'arrow-left']" class="me-2"></font-awesome-icon>
          Regresar a la Tienda
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue'
import NotFoundLottie from '@/assets/lotties/404lottie.json'
import danceduck from '@/assets/images/shared/duck-meme.gif'

const props = defineProps({
  hideBackBtn: { type: Boolean, required: false, default: false }
})

const { hideBackBtn } = toRefs(props)

const showDanceDuck = ref(false)

let clickCount = 0
const CLICK_THRESHOLD = 10000 // Number of clicks required

function toggleDanceDuck() {
  clickCount++
  if (clickCount === CLICK_THRESHOLD) {
    showDanceDuck.value = !showDanceDuck.value
    clickCount = 0 // Reset click count after action
  }
}
</script>
