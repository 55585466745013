<template>
  <div>
    <div class="card border border-primary-app shadow-sm rounded-3 p-0 position-relative">
      <div class="pb-4 px-4 bg-primary-app rounded-3 rounded-bottom-0 text-white">
        <p class="fw-semibold ms-3 pt-4 mb-0">Saldo de la cuenta</p>
      </div>

      <div class="card-body px-1 m-0 pb-0 pt-5 position-relative">
        <div class="position-absolute top-0 end-0 me-1 mt-1 p-1 py-2 rounded text-app">
          <template v-if="!info.updated_cres">
            <p class="fs-8 mb-1">Sin actualización</p>
          </template>
          <template v-else>
            <p class="fs-8 mb-1">
              <span class="me-2">Última actualización:</span>
              <span class="me-2">{{ $formatdatetime(info.updated_cres_date) }}</span>
              <span
                class="status-circle-2 border-0 rounded-circle mb-1"
                :class="{ 'bg-danger': !info.updated_cres, 'bg-green-active': info.updated_cres }"
              >
                <span class="visually-hidden">New alerts</span>
              </span>
            </p>
          </template>
        </div>
        <TableHelper :fields="dynamicTableFields" :records="records">
          <template #cell(date_billing)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.date_billing }}
            </span>
            <span v-else>{{ record.date_billing }}</span>
          </template>
          <template #cell(total)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.total }}
            </span>
            <span v-else>{{ record.total }}</span>
          </template>
          <template #cell(unexpired)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.unexpired }}
            </span>
            <span v-else>{{ record.unexpired }}</span>
          </template>
          <template #cell(days)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.days }}
            </span>
            <span v-else>{{ record.days }}</span>
          </template>
          <template #cell(expired_3)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.expired_3 }}
            </span>
            <span v-else>{{ record.expired_3 }}</span>
          </template>
          <template #cell(expired_10)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.expired_10 }}
            </span>
            <span v-else>{{ record.expired_10 }}</span>
          </template>
          <template #cell(expired_20)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.expired_20 }}
            </span>
            <span v-else>{{ record.expired_20 }}</span>
          </template>
          <template #cell(expired_more)="record">
            <span class="fw-bold" v-if="record.index === records.length">
              {{ record.expired_more }}
            </span>
            <span v-else>{{ record.expired_more }}</span>
          </template>
        </TableHelper>
        <PaginationHelper
          :showCountText="false"
          :activeClass="true"
          :justifyClass="'end'"
          :totalRecords="totalRecords"
          :limit="limit"
          @changePage="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import TableHelper from '@/components/shared/TableHelper.vue'
import PaginationHelper from '@/components/shared/PaginationHelper.vue'

const props = defineProps({
  totalRecords: { type: Number, required: true },
  info: { type: Object, required: true },
  limit: { type: Number, required: true },
  records: { type: Array, required: true }
})

const { totalRecords, limit, records } = toRefs(props)

const atLeastOneRecordHasOrderWebNum = computed(() => {
  return records.value.some((record) => record.order_web_num)
})

const tableFields = [
  {
    key: 'order_num',
    label: 'No. Venta',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'invoice_num',
    label: 'Factura',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'date_billing',
    label: 'Fecha de facturación',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'total',
    label: 'Importe',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'balance',
    label: 'Saldo',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'date_expiration',
    label: 'Vence',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'days',
    label: 'Días',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'unexpired',
    label: 'Sin vencer',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'expired_3',
    label: '0 a 3',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'expired_10',
    label: '4 a 10',
    thClass: 'text-center fw-semibold text-danger',
    tdClass: 'text-center align-middle text-danger'
  },
  {
    key: 'expired_20',
    label: '11 a 20',
    thClass: 'text-center fw-semibold text-danger',
    tdClass: 'text-center align-middle text-danger'
  },
  {
    key: 'expired_more',
    label: '21 a más',
    thClass: 'text-center fw-semibold text-danger',
    tdClass: 'text-center align-middle text-danger'
  }
]

const dynamicTableFields = computed(() => {
  const fields = JSON.parse(JSON.stringify(tableFields))
  if (atLeastOneRecordHasOrderWebNum.value) {
    fields.unshift({
      key: 'order_web_num',
      label: 'No. Venta',
      thClass: 'text-center fw-semibold text-app',
      tdClass: 'text-center align-middle'
    })
  }
  return fields
})

function changePage(page) {
  this.$emit('changePage', { page })
  window.scrollTo(0, 0)
}
</script>

<style lang="scss" scoped>
.status-circle {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.status-circle-2 {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}
</style>
