<template>
  <GeneralModal
    ref="modalInfoVideo"
    title="Ayuda"
    size="lg"
    modalContentClass="p-5"
    @shown="onShown"
    hideHeaderClose
    hideFooter
    noCloseOnBackdrop
    noCloseOnEsc
  >
    <template #bodyContent>
      <VideoContainer v-if="!allVideos" :videoSrc="videoSrc" title="Video de ayuda" hideTitle />
      <div v-else>
        <div v-for="video in listVideos" :key="video.title">
          <VideoContainer :videoSrc="video.src" :title="video.title" />
          <hr />
        </div>
      </div>

      <div>
        <button v-if="showContinueButton" @click="hideModal" class="btn btn-primary-app float-end">
          Continuar
        </button>
      </div>
    </template>
  </GeneralModal>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue'
import GeneralModal from '@/components/shared/GeneralModal.vue'
import VideoContainer from '@/components/shared/VideoContainer.vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const props = defineProps({
  videoSrcProps: String,
  allVideos: {
    type: Boolean,
    default: false
  },
  hideButtonContinue: {
    type: Boolean,
    default: false
  },
  hasActionHideModal: {
    type: Boolean,
    default: false
  },
  actionHideModal: Function
})

const { videoSrcProps, allVideos, hideButtonContinue, hasActionHideModal, actionHideModal } =
  toRefs(props)
const listVideos = ref([
  {
    title: 'Tutorial Dinamicaenlinea.com 2.0',
    src: ''
  },
  {
    title: 'Backorder tutorial',
    src: ''
  },
  {
    title: 'Proceso de compra Dinámica en línea',
    src: ''
  }
])
const urlVideos = [
  'https://www.youtube.com/embed/mQuMQ5RgiZk',
  'https://www.youtube.com/embed/FMUxnAm71Tg',
  'https://www.youtube.com/embed/k3aT1qgmFyA'
]
const videoSrc = ref('')
const hasSeenVideo = computed({
  get: () => userStore.getHasSeenVideo,
  set: (newValue) => {
    userStore.updateHasSeenVideo(newValue)
  }
})

const modalInfoVideo = ref(null)
const showContinueButton = ref(true)

const showModal = () => {
  modalInfoVideo.value.show()
}

const hideModal = () => {
  if (allVideos.value) {
    listVideos.value.forEach((video) => {
      video.src = ''
    })
  } else {
    videoSrc.value = '' // Detener la reproducción del video
  }
  if (hasActionHideModal.value) {
    actionHideModal.value()
  }
  modalInfoVideo.value.hide()
}

const onShown = () => {
  // videoSrc.value = 'https://www.youtube.com/embed/PcukFxM-6WM?autoplay=1'
  if (allVideos.value) {
    listVideos.value.forEach((video, index) => {
      video.src = urlVideos[index]
    })
  } else {
    videoSrc.value = videoSrcProps.value
  }
  if (hasSeenVideo.value && hideButtonContinue.value) {
    showContinueButton.value = true
    return
  }
  if (!hasSeenVideo.value && hideButtonContinue.value) {
    showContinueButton.value = false
    setTimeout(() => {
      showContinueButton.value = true
      hasSeenVideo.value = true
    }, 20000)
  }
}

defineExpose({
  showModal,
  hideModal
})
</script>

<style scoped>
button {
  display: block;
  margin: 0 auto;
}
</style>
