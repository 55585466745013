<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import LgViewNavbar from '@/components/layout/navigation/navbar/LgViewNavbar.vue'
import MdViewNavbar from '@/components/layout/navigation/navbar/MdViewNavbar.vue'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import { useShoppingStore } from '@/stores/shopping'
import { useAuthStore } from '@/stores/auth'

const route = useRoute()
const configStore = useConfigStore()
const userStore = useUserStore()
const authStore = useAuthStore()
const shoppingStore = useShoppingStore()
const agreementState = computed(() => userStore.agreementStateByRoute)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const userName = computed(() => userStore.userName)
const isLoggedIn = computed(() => authStore.isLoggedIn)
const searchValue = computed({
  get: () => userStore.searchValue,
  set: (newValue) => {
    userStore.updateSearch(newValue)
  }
})
const publicRoutesWithoutSearch = [
  'orderDetail',
  'privacy_policy_public_2',
  'terms_and_conditions_public_2',
  'contact_public',
  'job_board_publico'
]

const isRouteWithoutSearch = computed(() => {
  return publicRoutesWithoutSearch.includes(route.name)
})

const search = () => {
  if (searchValue.value === '') {
    if (agreementState.value) {
      router.push({
        name: 'agreement'
      })
    } else {
      router.push({ name: 'home' })
    }
    return
  }
  if (route.name === 'productsByCategory') return
  userStore.updateToggleSearch()
  const routeName = agreementState.value ? 'agreement.search_products' : 'searchProducts'
  router.push({ name: routeName, query: { search: searchValue.value } })
}

const navConfig = {
  isLoggedIn,
  isRouteWithoutSearch,
  userName,
  agreementState,
  remainingBalance,
  getBalanceLogicState,
  toggleSidebar: configStore.toggleSidebar,
  search
}
</script>

<template>
  <div class="z-4">
    <!-- LG to bigger screen views -->
    <LgViewNavbar :config="navConfig" />
    <!-- LG to bigger screen views -->
    <!-- MD to smaller screen views -->
    <MdViewNavbar :config="navConfig" />
    <!-- MD to smaller screen views -->
  </div>
</template>
