import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    alerts: ref({})
  }),
  getters: {
    getAlerts() {
      return this.alerts
    }
  },
  actions: {
    setAlerts(alert) {
      if (!alert) this.alerts = {}
      const id = Date.now().toString() + Math.random().toString()
      const newAlert = { [id]: alert }
      this.alerts = Object.assign({}, this.alerts, newAlert)
    },
    removeAlertByKey(key) {
      if (!this.alerts[key]) return
      const update = Object.assign({}, this.alerts)
      delete update[key]
      this.alerts = Object.assign({}, update)
    }
  }
})
