// src/directives/limitChars.js
export default {
  beforeMount(el, binding) {
    const maxChars = binding.value

    el.addEventListener('input', () => {
      if (el.value.length > maxChars) {
        el.value = el.value.slice(0, maxChars)
        el.dispatchEvent(new Event('input')) // Dispatch the input event to update the v-model
      }
    })
  }
}
