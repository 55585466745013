<template>
  <GeneralModal
    ref="modalRef"
    title="Recuperación de contraseña"
    size="md"
    modalContentClass="p-4"
    @shown="onShown"
    hideHeaderClose
    hideFooter
    noCloseOnEsc
  >
    <template #bodyContent>
      <PasswordRecoveryForm
        @saveForm="saveForm"
        @cancelForm="hideModal"
        ref="passwordRecoveryFormRef"
      />
    </template>
  </GeneralModal>
</template>
<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import PasswordRecoveryForm from '@/components/forms/PasswordRecoveryForm.vue'
import { ref } from 'vue'
import { instance, factories } from '@/api'

const api = factories(instance, ['user'])

const modalRef = ref(null)
const passwordRecoveryFormRef = ref(null)

function onShown() {
  passwordRecoveryFormRef.value.onShow()
}

const showModal = () => {
  modalRef.value.show()
}

function saveForm(payload) {
  api.user.recovery_password(payload).then(() => {})
  modalRef.value.hide()
}

// eslint-disable-next-line no-unused-vars
const hideModal = () => {
  modalRef.value.hide()
}

defineExpose({
  showModal,
  hideModal
})
</script>
