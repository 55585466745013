const statusList = {
  quotation: {
    label: 'Cotización',
    class: 'bg-600 text-white'
  },
  complete: {
    label: 'Orden Completa',
    class: 'bg-600 text-white'
  },
  pending: {
    label: 'Pendiente',
    class: 'bg-yellow-600 text-white'
  },
  cancelled: {
    label: 'Cancelado',
    class: 'bg-red-600 text-white'
  },
  blocked: {
    label: 'En revisión',
    class: 'bg-red-600 text-white'
  },
  in_progress: {
    label: 'En proceso',
    class: 'bg-cyan-500 text-white'
  },
  invoiced: {
    label: 'Facturado',
    class: 'bg-blue-600 text-white'
  },
  sent: {
    label: 'En tránsito',
    class: 'bg-orange-500 text-white'
  },
  delivered: {
    label: 'Entregado',
    class: 'bg-green-600 text-white'
  },
  backorder: {
    label: 'Backorder',
    class: 'bg-600 text-white'
  }
}

const defaultStatus = {
  label: 'N/A',
  class: 'bg-app'
}

const getStatusDetails = (status) => {
  return statusList[status] || defaultStatus
}

const actionsStatusBadge = {
  getClass: (status) => getStatusDetails(status).class,
  getLabel: (status) => getStatusDetails(status).label
}

export { actionsStatusBadge }
