<template>
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <template v-if="showCategories">
      <div ref="dropdownCategory" class="dropdown col">
        <button
          class="btn w-100 btn-light bg-white h-100"
          type="button"
          data-bs-auto-close="outside"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <font-awesome-icon
            class="me-2"
            :class="{
              'text-primary-app': showCategories,
              'text-black': !showCategories
            }"
            :icon="['fa', 'bars']"
            size="lg"
          />
          Todas las categorias
        </button>
        <ul class="dropdown-menu allCategoriesMenu">
          <div>
            <li class="p-0 my-3 mx-4 text-black cursor-pointer fw-light">
              <ShowCategories :categories="allCategories" v-if="showAllCategories" />
            </li>
          </div>
        </ul>
      </div>
    </template>
    <div :class="colOptions" v-for="option in configOptions" :key="option.name">
      <button @click="emit('goTo', option)" class="btn w-100 h-100" :class="classByState(option)">
        <font-awesome-icon :icon="option.icon" class="me-3" v-if="option?.hasIcon" />
        {{ option.name }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { toRefs, ref, onMounted, computed } from 'vue'
import { instance, factories } from '@/api'
import ShowCategories from '@/components/layout/navigation/ShowCategories.vue'

const api = factories(instance, ['category'])
const props = defineProps({
  configOptions: { type: Array, required: true, default: () => [] },
  colOptions: { type: String, required: false, default: 'col' },
  classButtons: { type: String, required: false, default: 'btn-primary-app' },
  showCategories: { type: Boolean, required: false, default: false }
})

const { configOptions, classButtons, colOptions } = toRefs(props)
const emit = defineEmits(['goTo'])
const route = useRoute()
const isHome = computed(() => route.name === 'home')
const allCategories = ref([])
const dropdownCategory = ref(null)
const showAllCategories = ref(false)

function getCategories() {
  api.category.all_categories().then((res) => {
    res.data.categories.forEach((category) => {
      category.active = true
    })
    allCategories.value = res.data.categories
  })
}

onMounted(() => {
  if (isHome.value) {
    dropdownCategory.value.addEventListener('shown.bs.dropdown', () => {
      showAllCategories.value = true
    })
    dropdownCategory.value.addEventListener('hide.bs.dropdown', () => {
      showAllCategories.value = false
    })
    getCategories()
  }
})

function classByState(option) {
  const classReturn = [classButtons.value, 'btn-light bg-white']
  return classReturn[option.class_active ? 0 : 1]
}
</script>

<style lang="scss" scoped></style>
