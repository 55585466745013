<template>
  <div class="card border-0 shadow-sm rounded-4 mb-3">
    <div class="card-body p-4">
      <div class="row px-2 g-1">
        <div class="col d-flex align-items-center justify-content-start align-content-center">
          <font-awesome-icon
            @click="configStore.toogleAdminSidebar()"
            class="text-app scaleHover cursor-pointer me-3"
            :icon="['fa', 'bars']"
            size="lg"
          />
          <h3 class="headerapp pt-1 mb-0 d-flex justify-content-start align-items-center text-app">
            {{ titleByRoute }}
          </h3>
        </div>

        <div class="col text-end userIDE d-flex justify-content-end align-items-center">
          <div class="userIDE-Box d-flex justify-content-center align-items-center rounded-3">
            <p class="m-0">{{ firstLetterName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import options from '@/constants/config/menu.js'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const userStore = useUserStore()
const configStore = useConfigStore()
const route = useRoute()

const firstLetterName = computed(() => {
  if (!userStore.firstLetterName) return ''
  return userStore.firstLetterName
})

const titleByRoute = computed(() => {
  if (!route.name) return ''
  const hasDeclaredRoute = options.find((option) => option.key.includes(route.name))
  if (!hasDeclaredRoute) return ''
  if (hasDeclaredRoute.hasSubMenu) {
    const getSubRouteData = hasDeclaredRoute.subMenuOptions.find((option) =>
      option.key.includes(route.name)
    )
    return getSubRouteData?.labelNav || getSubRouteData.label
  }
  return hasDeclaredRoute?.labelNav || hasDeclaredRoute.label
})
</script>
