<template>
  <div>
    <div class="table-responsive">
      <table class="table" :class="tableClass">
        <thead>
          <tr>
            <th
              v-for="field in fields"
              :key="generateUniqueKey(field.key)"
              :class="field?.thClass"
              @click="sort(field.key, field.sortable)"
            >
              <p class="mb-0">
                {{ field.label }}
                <span v-if="field.sortable" class="ms-2">
                  <font-awesome-icon
                    :icon="
                      sortOrder < 0 && sortBy === field.key
                        ? 'fa-solid fa-caret-up'
                        : 'fa-solid fa-caret-down'
                    "
                    size="lg"
                    :class="sortOrder > 0 && sortBy === field.key ? ' text-dark' : ' text-app'"
                  />
                </span>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(record, index) in sortedList"
            :key="generateUniqueKey(record.id)"
            class="table-row"
            :class="record?.trClass"
          >
            <td
              v-for="field in fields"
              :key="generateUniqueKey(field.key)"
              :class="field?.tdClass"
              @click="rowClickHandler(field?.rowClickActive, record)"
            >
              <slot
                :name="'cell(' + field.key + ')'"
                :index="index"
                v-bind="field.requireIndex ? { index, record } : record"
                >{{ record[field.key] }}</slot
              >
            </td>
          </tr>
        </tbody>
      </table>

      <p class="text-center" v-if="records.length < 1">- No hay registros -</p>
    </div>
    <PaginationHelper
      v-if="pagination"
      :totalRecords="totalRecords || 0"
      :limit="paginationLimit"
      @changePage="changePage"
    />
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid'
import { ref, computed } from 'vue'
import PaginationHelper from './PaginationHelper.vue'

const props = defineProps({
  fields: { type: Array, required: true, default: () => [] },
  records: { type: Array, required: true, default: () => [] },
  rowClickActive: { type: Boolean, required: false, default: false },
  rowClickFunction: { type: Function, required: false, default: () => false },
  exposeSort: { type: Boolean, required: false, default: false },
  tableClass: { type: String, required: false, default: '' },
  pagination: { type: Boolean, required: false, default: false },
  totalRecords: { type: Number, required: false, default: 0 },
  paginationLimit: { type: Number, required: false, default: 10 }
})

const emit = defineEmits(['actionSort', 'changePage'])

const sortOrder = ref(1)
const sortBy = ref('')
const activePage = ref(1)

const generateUniqueKey = (id) => `${id}-${uuidv4()}`

const rowClickHandler = (clickActive = true, record) => {
  if (!props.rowClickActive || !clickActive) return
  props.rowClickFunction(record)
}

const sort = (field, sortable) => {
  if (!sortable) return
  if (props.exposeSort) {
    sortOrder.value = -sortOrder.value
    sortBy.value = field
    emit('actionSort', field, sortOrder.value > 0 ? 'asc' : 'desc')
    return
  }
  sortOrder.value = sortBy.value === field ? -sortOrder.value : 1
  sortBy.value = field
}

const sortedList = computed(() => {
  if (!sortBy.value || props.exposeSort) return props.records
  return [...props.records].sort((a, b) => {
    const aValue = a[sortBy.value]
    const bValue = b[sortBy.value]

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder.value * (aValue - bValue)
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder.value * aValue.localeCompare(bValue)
    } else if (aValue instanceof Date && bValue instanceof Date) {
      return sortOrder.value * (aValue - bValue)
    }
    return 0
  })
})

const changePage = (page) => {
  activePage.value = page
  emit('changePage', page)
}

defineExpose({
  activePage
})
</script>
