<template>
  <div class="row mt-0 g-3">
    <div v-for="(option, index) in options" :key="index" class="col-12 col-md-4">
      <div
        class="flip-card"
        :class="{ selected: isSelected(option) }"
        @click="selectOption(option)"
      >
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <div class="row mt-3">
              <Vue3Lottie
                :animationData="optionLottie[option.lottieKey]"
                class="w-50"
                :class="{ 'mt-5': option.lottieKey === 'credit_note' }"
              />
            </div>
            <div class="row">
              <h5 class="mt-2">{{ option.title }}</h5>
            </div>
          </div>
          <div class="flip-card-back">
            <div class="row mt-3">
              <Vue3Lottie
                :animationData="optionLottie[option.lottieKey]"
                class="w-50"
                :class="{ 'mt-5': option.lottieKey === 'credit_note' }"
              />
            </div>
            <div class="row">
              <h5 class="mt-2">{{ option.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, toRefs } from 'vue'
import CreditNoteLottie from '@/assets/lotties/credit_note.json'
import SouvenirsLottie from '@/assets/lotties/souvenirs.json'
import DiscontinuedProductLottie from '@/assets/lotties/discontinued_product.json'

const props = defineProps({
  options: {
    type: Array,
    required: true,
    default: () => []
  },
  multiple: {
    type: Boolean,
    default: false
  }
})

const { options, multiple } = toRefs(props)
const emit = defineEmits(['optionSelected'])

const optionLottie = ref({
  credit_note: CreditNoteLottie,
  souvenirs: SouvenirsLottie,
  discontinued: DiscontinuedProductLottie
})

// En selección múltiple, seleccionamos múltiples opciones; de lo contrario, solo una
const selectedOptions = ref(multiple.value ? [] : null)

const isSelected = (option) => {
  if (multiple.value) {
    return selectedOptions.value.includes(option)
  } else {
    return selectedOptions.value === option
  }
}

const resetSelection = () => {
  selectedOptions.value = multiple.value ? [] : null
}

const selectOption = (option) => {
  if (multiple.value) {
    // En selección múltiple, agregamos o removemos la opción seleccionada
    if (selectedOptions.value.includes(option)) {
      selectedOptions.value = selectedOptions.value.filter((o) => o !== option)
    } else {
      selectedOptions.value.push(option)
    }
  } else {
    // En selección simple, solo permitimos seleccionar una opción a la vez
    selectedOptions.value = option
  }
  emit('optionSelected', selectedOptions.value)
}

defineExpose({ selectOption, resetSelection })
</script>

<style scoped>
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.selected .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flip-card-front {
  background-color: #f8f9fa;
  color: black;
}

.flip-card-back {
  background-color: #d3d3d3;
  color: black;
  transform: rotateY(180deg);
}
</style>
