export default (axios) => ({
  list(payload) {
    return axios.get('v1/client_addresses', { params: payload })
  },
  create(data) {
    return axios.post('v1/client_addresses', data)
  },
  show(id) {
    return axios.get(`v1/client_addresses/${id}`)
  },
  update(id, data) {
    return axios.put(`v1/client_addresses/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/client_addresses/${id}`)
  }
})
