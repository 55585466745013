export default (axios) => ({
  getCountries(payload) {
    return axios.get('v1/countries', { params: payload })
  },
  getStates(payload) {
    return axios.get('v1/states', { params: payload })
  },
  getCities(payload) {
    return axios.get('v1/cities', { params: payload })
  },
  zones(payload) {
    return axios.get('v1/zones', { params: payload })
  },
  zonesCities(payload) {
    return axios.get('v1/zones/cities', { params: payload })
  },
  zonesManagments(payload) {
    return axios.get('v1/zones/managments', { params: payload })
  }
})
