<template>
  <div :class="dynamicBaseClass">
    <!-- Mensaje de progreso para alcanzar el mínimo -->
    <slot name="messageLogicNotAcomplished">
      <div
        v-if="!logicStateAcomplished"
        class="d-flex justify-content-between align-items-center"
        v-html="dynamicMessageLogic"
      ></div>
    </slot>

    <!-- Mensaje de progreso cuando se ha alcanzado o superado el mínimo -->
    <slot name="messageLogicAcomplished">
      <div v-if="logicStateAcomplished" class="d-flex justify-content-between align-items-center">
        <div v-html="dynamicMessageLogicAcomplished" v-if="total > 0"></div>
      </div>
    </slot>

    <!-- Barra de progreso -->
    <div :class="progressClass" v-if="!logicStateAcomplished">
      <div
        class="progress-bar"
        :style="{ width: progressPercentage + '%' }"
        role="progressbar"
        :aria-valuenow="progressPercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'

// Define props
const props = defineProps({
  minimunLogic: { type: Boolean, required: false, default: false },
  remainLogic: { type: Boolean, required: false, default: false },
  containerBaseClasses: { type: String, required: false, default: 'alert' },
  progressClass: { type: String, required: false, default: 'progress' },
  userBalance: { type: Number, required: false, default: 0 },
  logicClass: { type: String, required: false, default: 'alert-warning' },
  logicAcomplishedClass: { type: String, required: false, default: 'alert-success' },
  logicData: { type: Number, required: false, default: 4000 },
  total: { type: Number, required: false, default: 0 },
  messageLogicNotAcomplished: { type: String, required: false, default: '' },
  messageLogicAcomplished: { type: String, required: false, default: '' },
  formatCurrency: { type: Function, required: false, default: (value) => value.toFixed(2) } // Prop opcional para formateo de moneda
})

// Referencias
const {
  minimunLogic,
  remainLogic,
  containerBaseClasses,
  progressClass,
  userBalance,
  logicClass,
  logicAcomplishedClass,
  logicData,
  total,
  messageLogicNotAcomplished,
  messageLogicAcomplished,
  formatCurrency
} = toRefs(props)

// Clase dinámica del contenedor basado en el progreso
const dynamicBaseClass = computed(() => {
  return !logicStateAcomplished.value
    ? `${containerBaseClasses.value} ${logicClass.value}`
    : `${containerBaseClasses.value} ${logicAcomplishedClass.value}`
})

const logicStateAcomplished = computed(() => {
  if (remainLogic.value) {
    if (total.value > 0) return true
    if (total.value <= 0) return false
  }
  if (minimunLogic.value) {
    if (total.value >= logicData.value) return true
    if (total.value < logicData.value) return false
  }
  return total.value >= logicData.value
})

// Cálculo del progreso basado en la prop `logicData`
const progressPercentage = computed(() => {
  const logicMinimun = Math.min((total.value / logicData.value) * 100, 100)
  const logicRemain = Math.min((logicData.value / userBalance.value) * 100, 100)
  return remainLogic.value ? logicRemain : logicMinimun
})

// Mensaje dinámico cuando el total es menor al mínimo
const dynamicMessageLogic = computed(() => {
  return messageLogicNotAcomplished.value
    ? messageLogicNotAcomplished.value
    : `<span>Agrega <strong>${formatCurrency.value(logicData.value - total.value)}</strong> para poder realizar tu pedido.</span>`
})

// Mensaje dinámico cuando se ha alcanzado el mínimo
const dynamicMessageLogicAcomplished = computed(() => {
  return messageLogicAcomplished.value
    ? messageLogicAcomplished.value
    : `<span>¡Felicidades! Tu carrito supera los <strong>${formatCurrency.value(logicData.value)}</strong>, sigue comprando para completar tu pedido.</span>`
})
</script>
