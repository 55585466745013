export default (axios) => ({
  loginProspects(payload) {
    return axios.post('v1/client_prospect_login', payload)
  },
  login(username, password) {
    return axios.post("/oauth/token", {
      grant_type: 'password',
      username,
      password
    });
  },
  refreshToken(token) {
    return axios.post("/oauth/token", {
      grant_type: 'refresh_token',
      refresh_token: token
    })
  }
})
