<template>
  <div class="position-relative m-4">
    <div
      class="progress"
      role="progressbar"
      aria-label="Progress"
      :aria-valuenow="progress"
      aria-valuemin="0"
      aria-valuemax="100"
      style="height: 1px"
    >
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    </div>
    <div
      v-for="(step, index) in steps"
      :key="index"
      :style="{ left: `${(index / (steps.length - 1)) * 100}%` }"
      class="position-absolute top-0 translate-middle btn btn-sm rounded-circle"
      :class="{
        'btn-primary-app': currentStep === index + 1,
        'btn-secondary': currentStep !== index + 1
      }"
      style="width: 2rem; height: 2rem"
      @click="goToStep(index + 1)"
    >
      {{ index + 1 }}
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'

const props = defineProps({
  steps: {
    type: Array,
    required: true
  },
  initialStep: {
    type: Number,
    default: 1
  }
})

const emit = defineEmits(['update:step'])

const currentStep = ref(props.initialStep)

const progress = computed(() => {
  return ((currentStep.value - 1) / (props.steps.length - 1)) * 100
})

const goToStep = (step) => {
  if (step > 0 && step <= props.steps.length) {
    currentStep.value = step
    emit('update:step', step)
  }
}

watch(
  () => props.initialStep,
  (newVal) => {
    currentStep.value = newVal
  }
)
</script>

