<template>
  <GeneralModal
    ref="modalRefPromotionalProduct"
    @shown="onShown"
    title="Gracias por su compra"
    :hideHeader="true"
    size="lg"
    modalContentClass="p-5 confirmationModal"
    hideHeaderClose
    hideFooter
    noCloseOnEsc
    noCloseOnBackdrop
  >
    <template #bodyContent>
      <div class="position-relative">
        <div class="position-absolute top-0 start-100" v-if="isShown">
          <Vue3Lottie
            ref="vue3LottieRef"
            :loop="1"
            @onComplete="vue3LottieRef.destroy()"
            :animationData="Confetti"
          />
        </div>
        <div class="position-absolute top-0 start-0" v-if="isShown">
          <Vue3Lottie
            ref="vue3LottieRef2"
            :loop="1"
            @onComplete="vue3LottieRef2.destroy()"
            :animationData="Confetti"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center position-relative">
          <div>
            <span class="fs-2 fw-semibold text-black text-center"
              >¡¡Enhorabuena! Elige tu regalo exclusivo!</span
            >
          </div>
        </div>
        <hr />
        <p>
          Selecciona uno de estos productos promocionales como agradecimiento por tu primera compra
          en nuestra plataforma. ¡Es completamente gratis!
        </p>
        <div class="row" v-if="products.length > 0">
          <div class="col" v-for="(product, index) in products" :key="product">
            <ProductCard
              :product="product"
              :isSelected="product_selected[index]"
              hidePrice
              hideAddBtn
              showBorder
              hideDiscount
              selectProduct
              hasWatermark
              @selectProduct="selectProduct"
            />
          </div>
        </div>
        <div class="mt-2 text-center z-5" v-if="productSelected">
          <button @click="sendOrder" class="fs-5 btn btn-primary-app mt-5 py-3 px-5 rounded z-5">
            <span class="px-5 py-3">Continuar</span>
          </button>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>
<script setup>
import ProductCard from '@/components/products/ProductCard.vue'
import GeneralModal from '@/components/shared/GeneralModal.vue'
import Confetti from '@/assets/lotties/Confetti.json'
import { onMounted, ref, computed } from 'vue'
import { instance, factories } from '@/api'

const api = factories(instance, ['product'])
const modalRefPromotionalProduct = ref(null)
const vue3LottieRef = ref(null)
const vue3LottieRef2 = ref(null)
const isShown = ref(false)
const products = ref([])
const product_selected = ref([false, false])
const emit = defineEmits(['sendOrder'])
const titlesProducts = ['1 Gorra azul marino', '1 Playera blanca, tallas a disponibilidad']
const keyProducts = ['PROM-GORRA', 'PROM-CAMISETA']
const waterMarkProducts = ['1 gorra', '1 playera']

const showModal = () => {
  modalRefPromotionalProduct.value.show()
}

const hideModal = () => {
  isShown.value = false
  modalRefPromotionalProduct.value.hide()
}

const selectProduct = (id) => {
  product_selected.value = product_selected.value.map(() => false)
  const index = products.value.findIndex((product) => product.id === id)
  product_selected.value[index] = !product_selected.value[index]
}

const productSelected = computed(() => {
  const indexSelected = product_selected.value.findIndex((selected) => selected)
  return products.value[indexSelected] || false
})

const sendOrder = () => {
  emit('sendOrder', productSelected.value.id)
  modalRefPromotionalProduct.value.hide()
}

const onShown = () => {
  isShown.value = true
}

const getProductDetail = (id, position = 0) => {
  api.product
    .detail(id)
    .then((res) => {
      products.value[position] = res.data
      products.value[position].title = titlesProducts[position]
      products.value[position].key = keyProducts[position]
      products.value[position].watermark = waterMarkProducts[position]
    })
    .catch((error) => {
      console.log(error)
    })
}

onMounted(() => {
  getProductDetail(9365)
  getProductDetail(5525, 1)
})

defineExpose({
  showModal,
  hideModal
})
</script>
