<template>
  <div>
    <transition name="fade">
      <SelectClientComponent
        v-if="canViewProductsByClientNum"
        ref="selectClientComponent"
        @clientSelectedAction="getProducts"
      />
    </transition>
    <nav aria-label="breadcrumb" v-if="!isRoutePublic">
      <ol class="breadcrumb">
        <li
          v-for="(category, index) in categories"
          :key="'CATEGORY' + index"
          class="breadcrumb-item"
          :class="index == categories.length - 1 ? ' text-primary' : ''"
        >
          <span class="cursor-pointer" @click="goToCategory(category.id)">{{ category.name }}</span>
        </li>
      </ol>
    </nav>
    <!-- Crear componente que muestre el stack de las categorias ejemplo Accesorios / Para Construción / Gotas para nivel -->
    <!-- <CategoriesStacks /> -->
    <ProductList
      paginationTop
      paginationBottom
      :products="products"
      :limit="limit"
      :total="total"
      @getProducts="renderProductByRoute"
    />
  </div>
</template>

<script setup>
import ProductList from '@/components/products/ProductList.vue'
import { useRoute } from 'vue-router'
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { instance, factories } from '@/api'
import { useUserStore } from '@/stores/user'
import router from '@/router'
import SelectClientComponent from '@/components/account/SelectClientComponent.vue'

const api = factories(instance, ['product'])

const route = useRoute()
const publicRoutesRequiredValidations = [
  'product_detail_public',
  'product_detail_only_list_pack_public',
  'category_public'
]
const products = ref([])
const categories = ref([])
const page = ref(1)
const total = ref(0)
const limit = ref(20)

const userStore = useUserStore()

const isRoutePublic = computed(() => publicRoutesRequiredValidations.includes(route.name))

const canViewProductsByClientNum = computed(() => userStore.canViewProductsByClientNum)
const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

const searchValue = computed(() => {
  return userStore.searchValue
})

watch(searchValue, () => {
  renderProductByRoute(1)
})

watch(
  () => route.params.id,
  (newId, oldId) => {
    if (newId !== oldId) {
      renderProductByRoute(1)
    }
  }
)

function getProductsPublic(pageSet = null) {
  page.value = pageSet ? pageSet : 1
  const payload = {
    limit: limit.value,
    page: page.value,
    search: searchValue.value,
    category_id: route.params.id
  }
  if (clientSelected.value && canViewProductsByClientNum.value)
    payload.client_id = clientSelected.value
  api.product.listPublic(payload).then((res) => {
    products.value = res.data.products
    categories.value = res.data.categories_arr
    total.value = res.data.total
  })
}

function getProducts(pageSet = null) {
  page.value = pageSet ? pageSet : 1
  const payload = {
    limit: limit.value,
    page: page.value,
    search: searchValue.value,
    category_id: route.params.id
  }
  if (clientSelected.value && canViewProductsByClientNum.value)
    payload.client_id = clientSelected.value
  api.product.listStore(payload).then((res) => {
    products.value = res.data.products
    categories.value = res.data.categories_arr
    total.value = res.data.total
  })
}

function goToCategory(id) {
  document.body.scrollTop = 0 // Para Safari
  document.documentElement.scrollTop = 0 // Para Chrome, Firefox, IE y Opera
  router.push({ name: 'productsByCategory', params: { id } })
}

function sizeWindow() {
  if (router.currentRoute.value.name !== 'productsByCategory') return
  if (window.innerWidth <= 767 && limit.value !== 14) {
    limit.value = 14
    renderProductByRoute()
  }
  if (window.innerWidth >= 768 && window.innerWidth < 992 && limit.value !== 24) {
    limit.value = 24
    renderProductByRoute()
  }
  if (window.innerWidth > 992 && limit.value !== 24) {
    limit.value = 24
    renderProductByRoute()
  }
  if (window.innerWidth > 1400 && limit.value !== 28) {
    limit.value = 28
    renderProductByRoute()
  }
}

function renderProductByRoute(params = null) {
  if (!isRoutePublic.value) {
    getProducts(params)
  } else {
    getProductsPublic(params)
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', sizeWindow)
})

onMounted(() => {
  window.addEventListener('resize', sizeWindow)
  sizeWindow()
  renderProductByRoute()
})
</script>

<style lang="scss" scoped></style>
