<script setup>
import { toRefs, computed, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { object, string, number } from 'yup'

const route = useRoute()

const props = defineProps({
  action: { type: String, required: false, default: 'new' },
  btnActionLabel: { type: String, required: false, default: 'Guardar' },
  clientData: { type: Object, required: false, default: null },
  prices: { type: Array, required: false, default: () => [] }
})
const emit = defineEmits(['saveForm', 'cancelForm'])

const { action, btnActionLabel, prices } = toRefs(props)

const phoneRegex = /^[0-9]{10}$/ // Suponiendo que los números de teléfono tengan 10 dígitos

const getValidationSchema = computed(() => {
  const schema = {}
  schema.client_num = number()
    .required('El número de cliente es requerido')
    .typeError('El número de cliente debe ser numérico')

  schema.contact_name = string()
    .required('El nombre de contacto es requerido')
    .min(3, 'El nombre de contacto debe tener mínimo 3 caracteres')

  schema.phone_number = string()
    .required('El número de teléfono es requerido')
    .matches(phoneRegex, 'El número de teléfono debe ser válido y tener 10 dígitos')

  schema.whatsapp_name = string().required('El Nombre de Contacto de whatsApp es requerido')

  schema.whatsapp_number = string()
    .required('El número de WhatsApp es requerido')
    .matches(phoneRegex, 'El número de WhatsApp debe ser válido y tener 10 dígitos')

  schema.name = string()
    .required('La razón social es requerida')
    .min(3, 'La razón social debe tener mínimo 3 caracteres')

  schema.email = string()
    .required('El correo electrónico es requerido')
    .email('Debes usar un correo electrónico válido')
  if (action.value === 'new' || route.name === 'signUp') {
    schema.email_confirmation = string()
      .required('La confirmación de correo electrónico es requerida')
      .email('Debes usar un correo electrónico válido')
      .test('emails-match', 'Los correos electrónicos deben ser iguales', function (value) {
        return this.parent.email === value
      })
  }

  if (route.name !== 'signUp') {
    schema.price_id = string().required('El precio es requerido')
  }

  if (action.value === 'new') {
    schema.password = string()
      .required('La contraseña es requerida')
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
    schema.password_confirmation = string()
      .required('La confirmación de contraseña es requerida')
      .min(6)
      .test('passwords-match', 'Las contraseñas deben ser iguales', function (value) {
        return this.parent.password === value
      })
  }

  return object(schema)
})

const validationSchema = getValidationSchema

const { resetForm, handleSubmit, errors, validateField, setFieldTouched } = useForm({
  validationSchema
})

const inputPassword = ref('password')
const inputConfirmationPassword = ref('password')

const { value: client_num } = useField('client_num')
const { value: contact_name } = useField('contact_name')
const { value: phone_number } = useField('phone_number')
const { value: whatsapp_name } = useField('whatsapp_name')
const { value: whatsapp_number } = useField('whatsapp_number')
const { value: name } = useField('name')
const { value: email } = useField('email')
const { value: email_confirmation } = useField('email_confirmation')
const { value: password } = useField('password')
const { value: password_confirmation } = useField('password_confirmation')
const { value: price_id } = useField('price_id')

const submit = handleSubmit(() => {
  emit('saveForm', { data: generatePayload(), id: props.clientData?.id || null })
})

const formattedPhone = computed(() => {
  // Formatea el número telefónico para mostrar en el input, aquí opcionalmente puedes aplicar el formato también
  if (phone_number?.value?.length > 0) {
    return `${phone_number.value?.slice(0, 2)} ${phone_number.value?.slice(2, 4)} ${phone_number.value?.slice(4, 8)} ${phone_number.value?.slice(8, 12)}`
  }
  return ''
})

const formattedWhatsapp = computed(() => {
  // Formatea el número telefónico para mostrar en el input, aquí opcionalmente puedes aplicar el formato también
  if (whatsapp_number?.value?.length > 0) {
    return `${whatsapp_number.value?.slice(0, 2)} ${whatsapp_number.value?.slice(2, 4)} ${whatsapp_number.value?.slice(4, 8)} ${whatsapp_number.value?.slice(8, 12)}`
  }
  return ''
})

const updatePhone = async (cleanNumber) => {
  phone_number.value = cleanNumber // Almacena el número limpio
  await validateField('phone_number') // Valida el campo
  setFieldTouched('phone_number', true) // Marca el campo como "touched"
}

const updateWhatsapp = async (cleanNumber) => {
  whatsapp_number.value = cleanNumber // Almacena el número limpio
  await validateField('whatsapp_number') // Valida el campo
  setFieldTouched('whatsapp_number', true) // Marca el campo como "touched"
}

function generatePayload() {
  const payload = {
    client_num: client_num.value,
    name: name.value,
    email: email.value,
    contact_name: contact_name.value,
    phone_number: phone_number.value,
    whatsapp_name: whatsapp_name.value,
    whatsapp_number: whatsapp_number.value
  }
  if (route.name !== 'signUp') {
    payload.price_id = price_id.value
  }
  if (action.value === 'new') {
    payload.password = password.value
    payload.password_confirmation = password_confirmation.value
  }
  return payload
}

function onShow() {
  if (action.value === 'new') {
    cleanForm()
  }
  if (action.value !== 'new' && props.clientData) {
    client_num.value = props.clientData.client_num
    name.value = props.clientData.name
    email.value = props.clientData.email
    contact_name.value = props.clientData.contact_name
    phone_number.value = props.clientData.phone_number
    whatsapp_name.value = props.clientData.whatsapp_name
    whatsapp_number.value = props.clientData.whatsapp_number
    price_id.value = props.clientData.price_id
  }
}

function cancelForm() {
  emit('cancelForm')
  cleanForm()
}

function cleanForm() {
  resetForm({
    values: {
      client_num: '',
      name: '',
      email: '',
      contact_name: '',
      phone_number: '',
      whatsapp_name: '',
      whatsapp_number: '',
      password: '',
      password_confirmation: '',
      price_id: ''
    }
  })
}

defineExpose({
  onShow
})
</script>

<template>
  <form @submit.prevent="submit()">
    <div class="mb-2">
      <label for="client_num" class="text-app">Número de cliente</label>
      <input
        type="number"
        class="form-control"
        :class="{ 'is-invalid': errors.client_num }"
        name="client_num"
        id="client_num"
        placeholder="120432"
        v-model="client_num"
      />
      <small class="text-danger" v-text="errors.client_num"></small>
    </div>
    <div class="mb-2">
      <label for="name" class="text-app">Razón social(Nombre a quien se factura)</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.name }"
        name="name"
        id="name"
        placeholder="Razón social"
        v-model="name"
      />
      <small class="text-danger" v-text="errors.name"></small>
    </div>
    <div class="mb-2">
      <label for="email" class="text-app">Correo electrónico</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.email }"
        name="email"
        id="email"
        placeholder="example@mail.com"
        v-model="email"
        v-clearInput
      />
      <small class="text-danger" v-text="errors.email"></small>
    </div>
    <div class="mb-2" v-if="action === 'new' || route.name === 'signUp'">
      <label for="email" class="text-app">Confirmar correo electrónico</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.email_confirmation }"
        name="email_confirmation"
        id="email_confirmation"
        placeholder="example@mail.com"
        v-model="email_confirmation"
        v-clearInput
      />
      <small class="text-danger" v-text="errors.email_confirmation"></small>
    </div>
    <div class="mb-2">
      <label for="contact_name" class="text-app">Nombre de contacto para tienda en línea</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.contact_name }"
        name="contact_name"
        id="contact_name"
        placeholder="Nombre de contacto"
        v-model="contact_name"
      />
      <small class="text-danger" v-text="errors.contact_name"></small>
    </div>
    <div class="mb-2">
      <label for="phone_number" class="text-app">Teléfono móvil</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.phone_number }"
        name="phone_number"
        id="phone_number"
        placeholder="Teléfono móvil"
        :value="formattedPhone"
        v-phoneInput="updatePhone"
      />
      <small class="text-danger" v-text="errors.phone_number"></small>
    </div>
    <div class="mb-2">
      <label for="whatsapp_name" class="text-app">Nombre de Contacto Whatsapp</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.whatsapp_name }"
        name="whatsapp_name"
        id="whatsapp_name"
        placeholder="NOMBRE"
        v-model="whatsapp_name"
      />
      <small class="text-danger" v-text="errors.whatsapp_name"></small>
    </div>
    <div class="mb-2">
      <label for="whatsapp_number" class="text-app">Teléfono con whatsapp</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.whatsapp_number }"
        name="whatsapp_number"
        id="whatsapp_number"
        placeholder="Teléfono móvil"
        :value="formattedWhatsapp"
        v-phoneInput="updateWhatsapp"
      />
      <small class="text-danger" v-text="errors.whatsapp_number"></small>
    </div>

    <div class="mb-2" v-if="route.name !== 'signUp'">
      <label for="price_id" class="text-app">Precio asignado al cliente:</label>
      <select
        :class="{ 'is-invalid': errors.price_id }"
        class="form-control"
        name="price_id"
        id="price_id"
        v-model="price_id"
      >
        <option selected disabled>Selecciona un precio</option>
        <option v-for="price in prices" :key="price.id" :value="price.id">
          Precio {{ price.price_num }} {{ price.percentage ? `- ${price.percentage}%` : '' }}
        </option>
      </select>
      <small class="text-danger" v-text="errors.price_id"></small>
    </div>

    <!-- Campos de contraseña para nuevas cuentas -->
    <template v-if="action === 'new'">
      <div class="mb-2">
        <label for="password" class="text-app">Contraseña:</label>
        <input
          :type="inputPassword"
          class="form-control"
          :class="{ 'is-invalid': errors.password }"
          id="password"
          v-model="password"
          placeholder="Contraseña"
        />
        <small class="text-danger" v-text="errors.password"></small>
      </div>

      <div class="mb-2">
        <label for="password_confirmation" class="text-app">Confirmar Contraseña:</label>
        <input
          :type="inputConfirmationPassword"
          class="form-control"
          :class="{ 'is-invalid': errors.password_confirmation }"
          id="password_confirmation"
          v-model="password_confirmation"
          placeholder="Confirmar contraseña"
        />
        <small class="text-danger" v-text="errors.password_confirmation"></small>
      </div>
    </template>

    <div class="d-flex col my-4">
      <button @click="cancelForm" type="button" class="me-3 btn btn-login-app w-100">
        Cancelar
      </button>
      <button class="btn btn-primary-app w-100" type="submit">{{ btnActionLabel }}</button>
    </div>
  </form>
</template>
