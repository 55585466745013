// lowercase.js (Directiva Vue)
export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      const value = event.target.value
      const lowercaseValue = value.toLowerCase()

      // Solo actualiza si el valor ha cambiado
      if (value !== lowercaseValue) {
        event.target.value = lowercaseValue
        event.target.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
