<template>
  <div v-if="isVisible" class="offcanvas-backdrop fade show"></div>
  <div
    ref="offcanvasResponsive"
    class="offcanvas offcanvas-start"
    :class="{ show: isVisible, classCard }"
    tabindex="-1"
    id="offcanvasResponsive"
    aria-labelledby="offcanvasResponsiveLabel"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="isVisible = false"
      ></button>
    </div>
    <div v-if="$slots.header" class="offcanvas-header" :class="classCardHeader">
      <slot name="header"></slot>
    </div>
    <div class="offcanvas-body" :class="classCardBody" @click.stop="actionCardBody">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, ref, watch } from 'vue'
import { useConfigStore } from '@/stores/config'

const props = defineProps({
  classCard: {
    type: String,
    default: ''
  },
  classCardHeader: {
    type: String,
    default: ''
  },
  classCardBody: {
    type: String,
    default: ''
  },
  actionCardBody: {
    type: Function,
    default: () => {}
  }
})

const { classCard, classCardHeader, classCardBody, actionCardBody } = toRefs(props)

const configStore = useConfigStore()

const isVisible = computed({
  get: () => configStore.getSidebarVisibleAdmin,
  set: (newValue) => {
    configStore.updateAdminSidebarVisibility(newValue)
  }
})

watch(isVisible, (newValue, oldValue) => {
  if (newValue && oldValue !== newValue) {
    show()
  } else if (!newValue && oldValue !== newValue) {
    hide()
  }
})

const offcanvasResponsive = ref(null)
let offCanvasInstance = null

const toggle = () => {
  offCanvasInstance?.toggle()
}

const show = () => {
  offCanvasInstance?.show()
}

const hide = () => {
  isVisible.value = false
  offCanvasInstance?.hide()
}

defineExpose({
  toggle,
  show,
  hide
})
</script>

<style lang="scss" scoped></style>
