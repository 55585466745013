<script setup>
import { computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import { string, object } from 'yup'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/../firebaseConfig'

const emit = defineEmits(['saveForm', 'cancelForm'])

const environment = import.meta.env.VITE_ENVIRONMENT
const getValidationSchema = computed(() => {
  const schema = {}
  schema.email = string().required('El email es requerido').email('Se debe usar un email valido')
  return object(schema)
})

const validationSchema = getValidationSchema

const { resetForm, handleSubmit, errors } = useForm({
  validationSchema
})

const { value: email } = useField('email')

const submit = handleSubmit(() => {
  emit('saveForm', generatePayload())
})

function generatePayload() {
  if (environment === 'production') {
    logEvent(analytics, 'user_rq_reset_pwd', {
      email: email.value
    })
  }
  const payload = {
    email: email.value
  }
  return payload
}

function onShow() {
  cleanForm()
}

function cancelForm() {
  emit('cancelForm')
  cleanForm()
}

function cleanForm() {
  resetForm({
    values: {
      email: ''
    }
  })
}

defineExpose({
  onShow
})
</script>

<template>
  <form @submit.prevent="submit()">
    <div class="mb-2">
      <label for="client_num" class="text-app">Correo electrónico</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.email }"
        name="email"
        id="email-recovery"
        placeholder="example@mail.com"
        v-model="email"
        v-clearInput
      />
      <small class="text-danger" v-text="errors.email"></small>
    </div>
    <div class="d-flex col mt-4">
      <button @click="cancelForm" type="button" class="me-3 btn btn-login-app w-100">
        Cancelar
      </button>
      <button class="btn btn-primary-app w-100" type="submit">Recuperar</button>
    </div>
  </form>
</template>
