export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      const input = event.target.value.replace(/\D/g, '') // Mantener solo dígitos
      if (input !== event.target.value) {
        event.target.value = input
        // Utilizamos setTimeout para que Vue procese el evento después de las actualizaciones pendientes
        setTimeout(() => {
          event.target.dispatchEvent(new Event('input'))
        }, 0)
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
