<template>
  <transition name="fade" :enter-class="transition" :leave-to-class="transition">
    <div
      v-if="show"
      :class="['notification-card alert', generateBgByStatus]"
      :style="{ width: width }"
      @click="emit('hide', id)"
    >
      <div class="notification-content">
        <font-awesome-icon v-if="icon" :icon="icon" class="me-2" />
        <span v-html="message"></span>
      </div>
      <font-awesome-icon :icon="['fa', 'times']" size="lg" @click="emit('hide', id)" />
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, toRefs, computed, watch } from 'vue'

const show = ref(true)
const props = defineProps({
  permanent: {
    type: Boolean,
    default: false
  },
  message: {
    type: String
  },
  icon: {
    type: Array
  },
  status: {
    type: String,
    default: 'success'
  },
  timeout: {
    type: Number,
    default: 4000
  },
  transition: {
    type: String,
    default: 'fade-right'
  },
  width: {
    type: String,
    default: '350px'
  },
  id: {
    type: [String, Number]
  }
})

const { permanent, message, icon, status, timeout, transition, width, id } = toRefs(props)
const timeoutId = ref(null)

const generateBgByStatus = computed(() => {
  return `alert-${status.value}`
})

const emit = defineEmits(['hide'])

const startTimeout = () => {
  if (!permanent.value) {
    timeoutId.value = setTimeout(() => {
      show.value = false
      emit('hide')
    }, timeout.value)
  }
}

const clearExistingTimeout = () => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value)
    timeoutId.value = null
  }
}

onMounted(() => {
  startTimeout()
})

onBeforeUnmount(() => {
  clearExistingTimeout()
})

watch(timeout, () => {
  clearExistingTimeout()
  startTimeout()
})
</script>
