<template>
  <div>
    <li class="p-0 my-3 mx-4 text-black cursor-pointer fw-light">
      <div
        @click="toggleCategories"
        class="d-flex flex-row h-100 py-3 px-4 optionHover"
        :class="{
          'text-primary-app': showCategories
        }"
      >
        <font-awesome-icon
          class="me-2"
          :class="{
            'text-primary-app': showCategories,
            'text-black': !showCategories
          }"
          :icon="['fa', 'bars']"
          size="lg"
        />Todas las Categorias
        <span class="text-end flex-fill">
          <font-awesome-icon class="text-primary-app me-2" :icon="['fa', iconByState]" size="lg" />
        </span>
      </div>
      <ShowCategories :categories="allCategories" v-if="showCategories" />
    </li>
  </div>
</template>

<script setup>
import ShowCategories from '@/components/layout/navigation/ShowCategories.vue'
import { ref, onMounted, computed } from 'vue'
import { instance, factories } from '@/api'

const api = factories(instance, ['category'])

const showCategories = ref(false)
const allCategories = ref([])

const iconByState = computed(() => (showCategories.value ? 'caret-down' : 'caret-right'))

function getCategories() {
  api.category.all_categories().then((res) => {
    res.data.categories.forEach((category) => {
      category.active = true
    })
    allCategories.value = res.data.categories
  })
}

function toggleCategories() {
  showCategories.value = !showCategories.value
}

onMounted(() => {
  getCategories()
})
</script>
