<template>
  <div class="d-lg-none d-block accordion" id="accordionExample">
    <template
      v-for="section in sectionOrder"
      :key="`${section}-${Math.floor(Math.random() * 999)}`"
    >
      <div class="accordion-item">
        <div class="accordion-header">
          <h5
            class="accordion-button collapsed footerComponent__main--title text-black mb-0 cursor-pointer"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${section}`"
            aria-expanded="true"
            :aria-controls="`collapse${section}`"
          >
            {{ sections[section].titleSection }}
          </h5>
        </div>
        <div
          :id="`collapse${section}`"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body text-app pb-1">
            <template
              v-for="option in sections[section].options"
              :key="`${option.key}-${option.key}-${Math.floor(Math.random() * 999)}`"
            >
              <h5 class="mb-3 ms-3 cursor-pointer">
                <router-link
                  v-if="!option?.anotherLogic"
                  :to="{ name: routeNameByAuth(option), query: option?.queryParams || '' }"
                  >{{ option.title }}</router-link
                >
                <span v-else @click="actionEmit(option.action)">{{ option.title }}</span>
              </h5>
              <hr />
            </template>
          </div>
        </div>
      </div>
      <hr />
    </template>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
const isLoggedIn = computed(() => authStore.isLoggedIn)
const props = defineProps({
  sections: { type: Object, required: true },
  socialNetworks: { type: Object, required: true },
  sectionOrder: { type: Array, required: true }
})

const { sections, sectionOrder } = toRefs(props)

const routeNameByAuth = (option) => {
  if (isLoggedIn.value) {
    return option.routeName
  }
  return option.routeNamePublic
}

const emit = defineEmits(['infoVideo'])

const actionEmit = (action) => {
  emit(action)
}
</script>
