import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

const environment = import.meta.env.VITE_ENVIRONMENT
// Tu configuración de Firebase aquí, puedes encontrar estos datos en la consola de Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBoxteUz3-voilxeYvidXyiha-sX6hQDFY',
  authDomain: 'dinamicastore-ce0a5.firebaseapp.com',
  projectId: 'dinamicastore-ce0a5',
  storageBucket: 'dinamicastore-ce0a5.appspot.com',
  messagingSenderId: '214697889066',
  appId: '1:214697889066:web:6f35dd2f9741e94dd66253',
  measurementId: 'G-XJHK7JLCR6'
}

let analytics = null
let performance = null

if (environment === 'production') {
  // Inicializa Firebase
  const app = initializeApp(firebaseConfig)

  // Inicializa Google Analytics y exportalo
  analytics = getAnalytics(app)
  performance = getPerformance(app)
}

export { analytics, performance }
