export default {
  beforeMount(el, { value: updatePhone }, vnode) {
    const formatPhoneNumber = (number) => {
      // Elimina todo lo que no sea dígito
      const cleanNumber = number.replace(/\D+/g, '')

      // Formato: +LADA XX XXXX XXXX (solo para visualización)
      let formattedNumber = cleanNumber
      if (cleanNumber.length > 0) {
        formattedNumber = `${cleanNumber.slice(0, 2)} `
      }
      if (cleanNumber.length > 2) {
        formattedNumber += `${cleanNumber.slice(2, 4)} `
      }
      if (cleanNumber.length > 4) {
        formattedNumber += `${cleanNumber.slice(4, 8)} `
      }
      if (cleanNumber.length > 8) {
        formattedNumber += cleanNumber.slice(8, 12)
      }

      return { cleanNumber, formattedNumber }
    }

    const handleInput = (event) => {
      const input = event.target
      const rawValue = input.value

      // Guarda la posición del cursor antes de formatear
      const cursorPosition = input.selectionStart
      const beforeCursorValue = rawValue.slice(0, cursorPosition).replace(/\D+/g, '').length

      // Formatea el número
      const { cleanNumber, formattedNumber } = formatPhoneNumber(rawValue)

      // Actualiza el valor visualmente en el input
      input.value = formattedNumber

      // Ajusta la posición del cursor después de formatear
      const afterCursorValue = formattedNumber.slice(0, cursorPosition).replace(/\D+/g, '').length
      const diff = beforeCursorValue - afterCursorValue

      let newCursorPosition = cursorPosition + diff
      newCursorPosition = newCursorPosition < 0 ? 0 : newCursorPosition

      // Mantiene la posición del cursor sin saltar al final
      input.setSelectionRange(newCursorPosition, newCursorPosition)

      // Llamada a la función de actualización del modelo
      if (typeof updatePhone === 'function') {
        updatePhone(cleanNumber)
      }
    }

    const handleBackspace = (event) => {
      const input = event.target
      const cursorPosition = input.selectionStart
      if (cursorPosition > 0 && input.value[cursorPosition - 1] === ' ') {
        event.preventDefault()
        input.setSelectionRange(cursorPosition - 1, cursorPosition - 1)
      }
    }

    el.addEventListener('input', handleInput)
    el.addEventListener('keydown', (event) => {
      if (event.key === 'Backspace') {
        handleBackspace(event)
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
    el.removeEventListener('keydown', null)
  }
}
