<script setup>
import { computed, onMounted, ref } from 'vue'
import { useShoppingStore } from '@/stores/shopping'
import { useRoute } from 'vue-router'
// import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import checklist from '@/assets/lotties/checklist.json'

const configStore = useConfigStore()
// const userStore = useUserStore()
const shoppingStore = useShoppingStore()

const route = useRoute()

const isEmptyCart = computed(() => shoppingStore.isEmptyCart)
const totalPrice = computed(() => shoppingStore.cartTotalPrice)
const primaryColor = computed(() => configStore.getPrimaryColor)

function openCart() {
  if (isEmptyCart.value || route.name === 'shoppingCart') return
  shoppingStore.showCart()
}

const getBackgroundStyle = computed(() => {
  const percentage = Math.min((totalPrice.value / 1500) * 100, 100)
  const color = `linear-gradient(90deg, ${primaryColor.value} ${percentage}%, #fff ${percentage}%)`
  return {
    background: color,
    transition: 'background 0.5s ease-in-out',
    right: positionByRoute.value
  }
})

// const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
// const userBalance = computed({
//   get: () => shoppingStore.getUserBalance,
//   set: (newValue) => {
//     shoppingStore.updateUserBalance(newValue)
//   }
// })
// const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)

// const bacgkroundCalculatorByBalance = computed(() => {
//   const percentage = Math.min((remainingBalance.value / userBalance.value) * 100, 100)
//   const color = `linear-gradient(90deg, #fff ${percentage}%, ${primaryColor.value} ${percentage}%)`
//   return {
//     background: color,
//     transition: 'background 0.5s ease-in-out',
//     right: positionByRoute.value
//   }
// })

const bgActive = computed(() => {
  return getBackgroundStyle.value
})

const positionByRoute = computed(() => {
  if (route.name === 'orderDetail' || route.name === 'backorder') return '70px !important'
  return '20px !important'
})

const lottieSrc = ref(checklist)

onMounted(async () => {
  lottieSrc.value = await configStore.findLottieFile('CartButton')
})
</script>

<template>
  <transition name="fade">
    <div class="cartButton" v-if="!isEmptyCart" :style="bgActive" @click="openCart">
      <div class="layoutContainer">
        <Vue3Lottie class="lottie-icon" ref="vue3LottieRef" :animationData="lottieSrc" />
        <div
          class="itemsCounter"
          v-if="shoppingStore.cartTotalItems > 0"
          v-text="shoppingStore.cartTotalItems"
        ></div>
      </div>
    </div>
  </transition>
</template>
