<template>
  <div>
    <div class="card shadow rounded-3 border-0 mb-3 position-relative">
      <div class="position-absolute top-0 end-0 me-3 mt-3 text-white">
        <template v-if="!info.updated_cres">
          <p class="fs-8 mb-1">Sin actualización</p>
        </template>
        <template v-else>
          <p class="fs-8 mb-1">Última actualización:</p>
          <p class="fs-8">
            <span class="me-2">{{ $formatdatetime(info.updated_cres_date) }}</span>
            <span
              class="status-circle-2 border-0 rounded-circle"
              :class="{ 'bg-danger': !info.updated_cres, 'bg-green-active': info.updated_cres }"
            >
              <span class="visually-hidden">New alerts</span>
            </span>
          </p>
        </template>
      </div>
      <div class="pb-4 px-4 bg-primary-app rounded-3 rounded-bottom-0 text-white">
        <p class="fw-semibold ms-3 pt-4 mb-0">Cuenta</p>
      </div>
      <div class="container-fluid p-0">
        <div class="row px-4 ms-1 pt-4">
          <div class="col-6 col-md-2">
            <p class="fs-7 mb-0">No. cliente:</p>
            <p class="fw-semibold">{{ info?.client_num || 'Sin datos' }}</p>
          </div>
          <div class="col-6 col-md-4">
            <p class="fs-7 mb-0">Razón Social(Nombre a quien se factura):</p>
            <p class="fw-semibold">{{ info?.name || 'Sin datos' }}</p>
          </div>
          <div class="col-6 col-md-3">
            <p class="fs-7 mb-0">Nombre Comercial:</p>
            <p class="fw-semibold">{{ info?.brand_name || 'Sin datos' }}</p>
          </div>
          <div class="col-6 col-md-3">
            <p class="fs-7 mb-0">Giro Comercial:</p>
            <p class="fw-semibold">{{ info?.line_business_name || 'Sin datos' }}</p>
          </div>
        </div>
        <hr />
        <div class="row px-4 ms-1 py-3">
          <div class="col-6 col-md-2 d-flex justify-content-start">
            <div style="width: 100%; max-width: 100px" class="position-relative">
              <DoughnutChart :chartData="infoGraph" :options="options" />
              <div class="position-absolute pricePositionDonut" v-if="canViewMyAccountByClientNum">
                <p class="m-0 fw-normal fs-5 text-app">{{ info?.price_num }}</p>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <p class="fs-7 mb-0">
              {{ info?.days === 0 ? 'Línea de compra:' : 'Límite de crédito:' }}
            </p>
            <p class="fw-semibold text-green-active">
              {{ $formatcurrency(info?.credit_limit) || 'Sin datos' }}
            </p>
            <p class="fs-7 mb-0">Línea comprometida:</p>
            <p class="fw-semibold text-600">
              {{ $formatcurrency(getUnavailableCredit) || 'Sin datos' }}
            </p>
            <p class="fs-7 mb-0">Línea disponible:</p>
            <p class="fw-semibold text-green-active">
              {{ $formatcurrency(info?.credit_available) || 'Sin datos' }}
            </p>
          </div>
          <div class="col-6 col-md-3">
            <p class="fs-7 mb-0">Días de plazo</p>
            <p class="fw-semibold">{{ info?.days !== null ? info?.days : 'Sin datos' }}</p>
            <p class="fs-7 mb-0">Días Reales</p>
            <p class="fw-semibold" :class="{ 'text-danger': info?.days_real > info?.days }">
              {{ info?.days_real !== null ? info?.days_real : 'Sin datos' }}
            </p>
          </div>
          <div class="col-6 col-md-3">
            <p class="fs-7">Estado de la cuenta</p>
            <p class="fw-semibold m-0">
              <span class="status-circle border-0 rounded-circle" :class="stateAccount.bg">
                <span class="visually-hidden">New alerts</span> </span
              >{{ info?.blocked_note || '' }}
              <span class="ms-2">{{ stateAccount.label }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Addresses -->
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0">
        <div class="card shadow rounded-3 border-0 h-100">
          <div class="pb-4 px-4 bg-primary-app rounded-3 rounded-bottom-0 text-white border-0">
            <p class="fw-semibold ms-3 pt-4 mb-0">Dirección Fiscal:</p>
          </div>
          <div class="px-5 py-4">
            <template v-if="addressesFiscal">
              <p class="mb-2">
                Calle: <span class="fw-semibold">{{ addressesFiscal.street || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Número: <span class="fw-semibold">{{ addressesFiscal.num || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Interior:
                <span class="fw-semibold">{{ addressesFiscal.num_int || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Colonia:
                <span class="fw-semibold">{{ addressesFiscal.suburb || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Ciudad:
                <span class="fw-semibold">{{ addressesFiscal.city_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Estado:
                <span class="fw-semibold">{{ addressesFiscal.state_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                País:
                <span class="fw-semibold">{{ addressesFiscal.country_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                C.P.: <span class="fw-semibold">{{ addressesFiscal.zip || 'Sin datos' }}</span>
              </p>
            </template>
            <p v-else>No hay información</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card shadow rounded-3 border-0 h-100">
          <div class="pb-4 px-4 bg-700 rounded-3 rounded-bottom-0 text-white border">
            <p class="fw-semibold ms-3 pt-4 mb-0">Dirección de Entrega:</p>
          </div>
          <template v-if="addressesDelivery">
            <div class="px-5 py-4">
              <p class="mb-2">
                Calle:
                <span class="fw-semibold">{{ addressesDelivery.street || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Número: <span class="fw-semibold">{{ addressesDelivery.num || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Interior:
                <span class="fw-semibold">{{ addressesDelivery.num_int || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Colonia:
                <span class="fw-semibold">{{ addressesDelivery.suburb || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Ciudad:
                <span class="fw-semibold">{{ addressesDelivery.city_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                Estado:
                <span class="fw-semibold">{{ addressesDelivery.state_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                País:
                <span class="fw-semibold">{{ addressesDelivery.country_name || 'Sin datos' }}</span>
              </p>
              <p class="mb-2">
                C.P.: <span class="fw-semibold">{{ addressesDelivery.zip || 'Sin datos' }}</span>
              </p>
            </div>
          </template>
          <p v-else>No hay información</p>
        </div>
      </div>
    </div>
    <!-- Contactos del cliente -->
    <h3 class="text-center mt-5 fw-semibold">Contactos del cliente</h3>
    <div class="card shadow rounded-3 border-0 mb-3">
      <div class="row justify-content-between py-1 fs-7 g-1 px-0">
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 mt-0">
          <div class="row m-0 py-1 bg-primary-app rounded-top-3 rounded-end-0 text-white">
            <p class="fw-semibold ms-3 mb-0 py-2">Contacto fiscal</p>
          </div>
          <div class="ms-4 my-4">
            <p class="mb-1 ms-1">
              Nombre: <span class="fw-semibold">{{ info?.contact_name }}</span>
            </p>
            <p class="mb-1 ms-1">
              Email: <span class="fw-semibold">{{ info?.email }}</span>
            </p>
            <p class="mb-1 ms-1">
              Teléfono: <span class="fw-semibold">{{ info?.phone_number }}</span>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 mt-0">
          <div class="row m-0 py-1 bg-700 rounded-0 text-white">
            <p class="fw-semibold ms-1 mb-0 py-2">Contacto Tienda en línea</p>
          </div>
          <div class="ms-3 my-4">
            <p class="mb-1">
              Nombre: <span class="fw-semibold">{{ info?.contact_name_web || 'Sin datos' }}</span>
            </p>
            <p class="mb-1">
              Email: <span class="fw-semibold">{{ info?.contact_email_web || 'Sin datos' }}</span>
            </p>
            <p class="mb-1">
              Teléfono:
              <span class="fw-semibold">{{ info?.contact_phone_web || 'Sin datos' }}</span>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 mt-0 pe-0">
          <div class="row m-0 py-1 bg-primary-app rounded-top-3 rounded-start-0 text-white">
            <p class="fw-semibold ms-1 mb-0 py-2">Contacto Whatsapp</p>
          </div>
          <div class="ms-3 my-4">
            <p class="mb-1">
              Nombre: <span class="fw-semibold">{{ info?.whatsapp_name }}</span>
            </p>
            <p class="mb-1">
              Whatsapp: <span class="fw-semibold">{{ info?.whatsapp_number }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Datos Dinámica Ferretera -->
    <h3 class="text-center mt-5 fw-semibold">Datos Dinámica Ferretera</h3>
    <div class="card shadow rounded-4 border border-primary-app p-4 py-5 h-100">
      <p class="mb-2 fs-7">
        Zona: <span class="fw-semibold">{{ info.zone || 'Sin datos' }}</span>
      </p>
      <div class="row">
        <div class="col-12 col-lg-4 mb-3 mb-lg-0">
          <div>
            <p class="mb-0 fs-7">
              Vendedor: <span class="fw-semibold">{{ info.seller_name || 'Sin datos' }}</span>
            </p>
            <p class="mb-0 fs-7">
              Contacto: <span class="fw-semibold">{{ info.seller_phone || 'Sin datos' }}</span>
            </p>
            <p class="mb-0 fs-7">
              Email: <span class="fw-semibold">{{ info.seller_email || 'Sin datos' }}</span>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 pe-3">
          <p class="fw-semibold mb-0 fs-7 ps-2">
            Gerente: <span class="fw-semibold">{{ info.seller_manager_name || 'Sin datos' }}</span>
          </p>
          <p class="fw-semibold mb-0 fs-7 ps-2">
            Contacto:
            <span class="fw-semibold">{{ info.seller_manager_phone || 'Sin datos' }}</span>
          </p>
          <p class="fw-semibold mb-0 fs-7 ps-2">
            Email: <span class="fw-semibold">{{ info.seller_manager_email || 'Sin datos' }}</span>
          </p>
        </div>
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 pe-1 ps-4">
          <p class="fw-semibold mb-0 fs-7">
            Cobranza: <span class="fw-semibold">{{ info.collection_name || 'Sin datos' }}</span>
          </p>
          <p class="fw-semibold mb-0 fs-7">
            Contacto: <span class="fw-semibold">{{ info.collection_phone || 'Sin datos' }}</span>
          </p>
          <p class="fw-semibold mb-0 fs-7">
            Email: <span class="fw-semibold">{{ info.collection_email || 'Sin datos' }}</span>
          </p>
        </div>
      </div>
    </div>
    <p class="text-center text-app pt-4">
      * Para cualquier cambio o aclaración de la información presentada favor de comunicarse a
      pgonzalez@dinamicaferretera.com
    </p>
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs, computed } from 'vue'
import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import { useUserStore } from '@/stores/user'

Chart.register(...registerables)

const userStore = useUserStore()
const props = defineProps({
  info: { type: Object, required: true }
})
const { info } = toRefs(props)
const classOnSmallerScreen = ref('')

const options = ref({
  responsive: true
})

const canViewMyAccountByClientNum = computed(() => userStore.canViewMyAccountByClientNum)

const stateAccount = computed(() => {
  if (!info.value || Object.keys(info.value).length === 0 || info.value?.blocked === null) {
    return { label: 'Sin datos', bg: 'bg-secondary' }
  }
  if (info.value?.blocked) {
    return { label: 'Inactivo', bg: 'bg-danger' }
  }
  return { label: 'Activo', bg: 'bg-green-active' }
})

const addressesFiscal = computed(() => {
  if (!info.value || Object.keys(info.value).length === 0 || !info.value?.addresses?.length)
    return false
  const address = info.value.addresses.find((address) => address.address_name === 'Fiscal')
  return address || false
})

const addressesDelivery = computed(() => {
  if (!info.value || Object.keys(info.value).length === 0 || !info.value?.addresses?.length)
    return false
  const address = info.value.addresses.find((address) => address.address_name === 'Entrega')
  return address || false
})

const getUnavailableCredit = computed(() => info.value.credit_limit - info.value.credit_available)

const percentageAvailableCredit = computed(() =>
  Math.round((info.value.credit_available / info.value.credit_limit) * 100)
)

const percentageUnavailableCredit = computed(() =>
  Math.round((getUnavailableCredit.value / info.value.credit_limit) * 100)
)

const infoGraph = computed(() => {
  return {
    labels: [],
    datasets: [
      {
        data: [percentageAvailableCredit.value, percentageUnavailableCredit.value],
        backgroundColor: ['#36e13b', '#e13b36']
      }
    ]
  }
})

function sizeWindow() {
  if (window.innerWidth <= 991) {
    classOnSmallerScreen.value = 'mb-4'
  } else {
    classOnSmallerScreen.value = ''
  }
}

onMounted(() => {
  window.addEventListener('resize', sizeWindow)
  sizeWindow()
})
</script>

<style lang="scss" scoped>
.status-circle {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.status-circle-2 {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}
.pricePositionDonut {
  top: 27% !important;
  left: 47%;
}
</style>
