<template>
  <ul class="options_dinamica_store">
    <li
      v-if="!onlyLastOption && agreementState"
      class="cursor-pointer pulse-animation"
      @click="goToRoute('agreement')"
      :class="{ active: route?.name === 'agreement' }"
    >
      <div class="d-flex align-content-center justify-content-evenly m-0">
        <button class="btn btn-primary-app-3 fw-semibold">Convenio</button>
      </div>
    </li>
    <li
      v-if="!onlyLastOption && isLoggedIn && !isSeller"
      class="cursor-pointer"
      @click="goToRoute('home')"
      :class="{ active: route?.name === 'home' }"
    >
      Tienda
    </li>
    <li
      v-if="!onlyLastOption && isLoggedIn && !isSeller && !isClientFastenal"
      class="cursor-pointer position-relative"
      @click="openCart"
      :class="{ active: route?.name === 'shoppingCart' }"
    >
      Carrito<span
        v-if="shoppingStore.cartTotalItems > 0"
        class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border-none rounded-circle text-white"
      >
        <span class="visually-hidden">{{ shoppingStore.cartTotalItems }}</span>
      </span>
    </li>
    <li class="cursor-pointer">
      <div class="dropdown" v-if="!onlyLastOption">
        <button
          class="btn btn-primary-app dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <font-awesome-icon class="scaleHover" :icon="['far', 'user']" size="lg" />
          {{ labelBtn }}
        </button>
        <ul class="dropdown-menu">
          <li
            v-for="service in servicesFiltered"
            :key="`${service}-${Math.floor(Math.random() * 999)}`"
          >
            <router-link
              class="dropdown-item"
              :to="{ name: routeNameByAuth(service), query: service?.queryParams || '' }"
            >
              {{ service.title }}
            </router-link>
          </li>
          <li v-if="isLoggedIn">
            <a class="dropdown-item" @click="logout">Salir</a>
          </li>
        </ul>
      </div>
      <div v-else class="d-flex align-content-center justify-content-evenly">
        <div class="me-5">
          <font-awesome-icon
            v-if="agreementState"
            @click="goToRoute('agreement')"
            class="scaleHover pulse-animation"
            :icon="['fas', 'gift']"
            size="lg"
          />
        </div>

        <font-awesome-icon
          @click="goToRoute(optionByUserRoute())"
          class="text-app scaleHover"
          :icon="['far', 'user']"
          size="lg"
        />
      </div>
    </li>
  </ul>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useShoppingStore } from '@/stores/shopping'
import navbarConfigDinamica from '@/constants/navigation/navbar/navbarConfigDinamica'

const { services, servicesOptionsByUserType } = navbarConfigDinamica

const props = defineProps({
  onlyLastOption: { type: Boolean, required: false, default: false }
})

const { onlyLastOption } = toRefs(props)

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const userStore = useUserStore()
const shoppingStore = useShoppingStore()
const userType = computed(() => userStore.userType)
const agreementState = computed(() => userStore.agreementState)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const isClientFastenal = computed(() => userStore.isClientFastenal)
const isSeller = computed(() => userStore.isSeller)
services.options[0].routeName = userStore.routeToDasboardByUserType

const isLoggedIn = computed(() => authStore.isLoggedIn)
const servicesByLoggedUserType = computed(
  () => servicesOptionsByUserType[userType.value] || servicesOptionsByUserType['noUser']
)

const servicesFiltered = computed(() => {
  let dataFilter = JSON.parse(JSON.stringify(services.options))
  if (servicesByLoggedUserType.value.includes('allOptions')) {
    dataFilter = dataFilter.filter(
      (service) => service.key !== 'signUpProspect' && service.key !== 'login'
    )
    return dataFilter
  }
  dataFilter = dataFilter.filter((service) => servicesByLoggedUserType.value.includes(service.key))
  return dataFilter
})

const labelBtn = computed(() => {
  if (!isLoggedIn.value) {
    return 'Menú'
  } else if (getBalanceLogicState.value) {
    return 'Pedidos'
  }
  return 'Mi cuenta'
})

const routeNameByAuth = (option) => {
  if (isLoggedIn.value) {
    return option.routeName
  }
  return option.routeNamePublic
}

const logout = () => {
  authStore.logout()
}

const goToRoute = (routeName) => {
  router.push({
    name: routeName
  })
}

const optionByUserRoute = () => {
  if (!isLoggedIn.value) {
    return 'login'
  }
  return userStore.routeToDasboardByUserType
}

const openCart = () => {
  if (shoppingStore.isEmptyCart || route.name === 'shoppingCart') return
  shoppingStore.showCart()
}
</script>

<style lang="scss" scoped></style>
