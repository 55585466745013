<template>
  <div class="footer" :class="classFooter">
    <template v-for="content in configFooterContent" :key="content.label + content.value">
      <hr v-if="content.label.includes('Total') && splitLineAfterTotal" />
      <div
        class="d-flex justify-content-between align-items-center"
        :class="{
          'text-black': content.label.includes('Total') && !cartTotalPriceIsLessThanMinPurchase,
          'text-danger': content.label.includes('Total') && cartTotalPriceIsLessThanMinPurchase
        }"
      >
        <span :class="content.classLabel">{{ content.label }}:</span>
        <span :class="content.classValue">{{ content.value }}</span>
      </div>
    </template>

    <button
      v-if="!noBtnContinue"
      class="btn btn-primary-app w-100 mt-3"
      @click="goToShoppingCartView"
    >
      Continuar
    </button>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import router from '@/router'
import { useShoppingStore } from '@/stores/shopping'
import { useUserStore } from '@/stores/user'

const shoppingStore = useShoppingStore()

const props = defineProps({
  configFooterContent: { type: Object, required: false, default: () => {} },
  noBtnContinue: { type: Boolean, required: false, default: false },
  splitLineAfterTotal: { type: Boolean, required: false, default: false },
  classFooter: { type: String, required: false, default: '' }
})

const { configFooterContent, noBtnContinue, splitLineAfterTotal } = toRefs(props)

const userStore = useUserStore()
const agreementState = computed(() => userStore.agreementStateByRoute)
const goToShoppingCartView = () => {
  if (agreementState.value) {
    router.push({ name: 'agreement.shopping_cart' })
  } else {
    router.push({ name: 'shoppingCart' })
  }
  shoppingStore.hideCart()
}

const cartTotalPriceIsLessThanMinPurchase = computed(() => {
  return shoppingStore.cartTotalPriceIsLessThanMinPurchase
})
</script>
