<template>
  <div>
    <div class="card border-0 shadow-sm rounded-4 mb-2">
      <div class="card-body">
        <label for="client" class="form-label">Cliente</label>
        <v-select
          class="bg-white"
          v-model="selectItem"
          :filterable="false"
          :options="clientList"
          placeholder="Buscar cliente..."
          @search="onSearch"
        >
          <template v-slot:no-options> Buscar cliente... </template>
          <template v-slot:option="option">
            {{ `${option.name}-${option.client_num}-${option.price_id}` }}
          </template>
          <template v-slot:selected-option="option">
            {{ `${option.name}-${option.client_num}-${option.price_id}` }}
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { instance, factories } from '@/api'
import { computed, watch } from 'vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const api = factories(instance, ['clients'])

const onSearch = (search) => {
  const payload = {
    search,
    limit: 10
  }
  api.clients.list(payload).then((res) => {
    clientList.value = res.data.clients
  })
}

const selectItem = computed({
  get: () => userStore.getFilterByClient['item'],
  set: (newValue) => {
    userStore.setFilterByClient('item', newValue)
  }
})

const clientList = computed({
  get: () => userStore.getFilterByClient['list'],
  set: (newValue) => {
    userStore.setFilterByClient('list', newValue)
  }
})

const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

const emits = defineEmits(['clientSelectedAction'])

const onChangeClient = () => {
  emits('clientSelectedAction')
}

watch(selectItem, () => {
  clientSelected.value = selectItem.value?.id || false
  onChangeClient()
})

defineExpose({
  clientSelected
})
</script>

<style lang="scss" scoped></style>
