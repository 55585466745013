export default (axios) => ({
  // CRUD functions
  list(data = {}) {
    return axios.get('v1/login_images', { params: data })
  },
  create(data) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axios.post('v1/login_images', data, { headers })
  },
  update(id, data) {
    return axios.put(`v1/login_images/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/login_images/${id}`)
  },

  // No CRUD functions
  toggle_login_image(id) {
    return axios.put(`v1/login_images/${id}/toggle_login_image`)
  }
})
