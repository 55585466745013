export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      const input = event.target.value
      const formattedInput = input.replace(/[^0-9.]/g, '') // Permitir solo dígitos y el punto decimal

      // Asegurar que solo haya un punto decimal
      const parts = formattedInput.split('.')
      if (parts.length > 2) {
        event.target.value = parts[0] + '.' + parts.slice(1).join('')
      } else {
        event.target.value = formattedInput
      }

      // Emitir un evento input para actualizaciones en Vue
      if (input !== event.target.value) {
        event.target.dispatchEvent(new Event('input'))
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
