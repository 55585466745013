<script setup>
import SelectClientComponent from '@/components/account/SelectClientComponent.vue'
import ProductList from '@/components/products/ProductList.vue'
import OptionsMenuComponentCarousel from '@/components/shared/OptionsMenuComponentCarousel.vue'
import CarouselComponent from '@/components/shared/CarouselComponent.vue'
import BackorderActionSelection from '@/components/backorder/BackorderActionSelection.vue'
import ModalInfoClientsCredit from '@/components/shared/ModalInfoClientsCredit.vue'
import router from '@/router'
import InfoVideo from '@/components/shared/InfoVideo.vue'
import { instance, factories } from '@/api'
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { useUserStore } from '@/stores/user'
import { useShoppingStore } from '@/stores/shopping'

const api = factories(instance, ['clients', 'product', 'banner_option', 'category'])
const optionsMenuConfig = ref([])
const category_id = ref(null)
const backorderActionSelection = ref(null)

const shoppingStore = useShoppingStore()
const userStore = useUserStore()

const canViewProductsByClientNum = computed(() => userStore.canViewProductsByClientNum)

const searchValue = computed({
  get: () => userStore.searchValue,
  set: (newValue) => {
    userStore.updateSearch(newValue)
  }
})

const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

const categorySelected = computed(() => {
  return optionsMenuConfig.value.find((option) => option.id === category_id.value)
})

const products = ref([])
const infoVideoModal = ref(null)
const infoVideoModalClientCredit = ref(null)
const banner_options = ref([])
const page = ref(1)
const total = ref(0)
const limit = ref(20)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const isClientFastenal = computed(() => userStore.isClientFastenal)
const isClient = computed(() => userStore.isClient)
const isAlreadyReadCreditInfo = computed(() => userStore.getIsReadedClientCreditConfirm)
const requireChangePassword = computed(() => userStore.requireChangePassword)
const isBalanceActive = computed(() => userStore.isBalanceActive)
const totalOrders = ref(0)
const orders = ref([])
const modalRefInfoClientsCredit = ref(null)

const getOrders = () => {
  const payload = {
    backorder: 1
  }
  api.clients.orders(false, payload).then((res) => {
    totalOrders.value = res.data.total
    orders.value = res.data.orders
    if (orders.value.length > 0) {
      openBackorderActionSelection()
    }
  })
}

function getProductsByClient() {
  getProducts()
}

function getProducts(pageSet = null) {
  products.value = []
  nextTick(() => {
    page.value = pageSet ? pageSet : 1
    const payload = {
      limit: limit.value,
      page: page.value,
      search: searchValue.value,
      category_id: category_id.value
    }
    if (clientSelected.value && canViewProductsByClientNum.value)
      payload.client_id = clientSelected.value
    api.product.listStore(payload).then((res) => {
      products.value = res.data.products
      total.value = res.data.total
    })
  })
}

function getBannerOptions() {
  api.banner_option.list().then((res) => {
    banner_options.value = res.data.banner_options
  })
}

function changeActiveView(section) {
  const id = section.id
  const isDifferent = category_id.value !== id
  if (!isDifferent) return
  optionsMenuConfig.value.forEach((option) => {
    option.class_active = option.id === id
  })
  if (category_id.value !== id) {
    category_id.value = id
  }
  const someHasActive = optionsMenuConfig.value.some((option) => option.class_active)
  if (!someHasActive) {
    optionsMenuConfig.value[0].class_active = true
    category_id.value = optionsMenuConfig.value[0].id
  }
  getProducts(1)
}

function getSections() {
  api.category.index_sections().then((res) => {
    optionsMenuConfig.value = res.data.categories.filter((category) => !category.agreement_id)
    changeActiveView(optionsMenuConfig.value[0])
  })
}

function sizeWindow(getData = true) {
  if (router.currentRoute.value.name !== 'home') return
  if (window.innerWidth <= 767 && limit.value !== 14) {
    limit.value = 14
    if (getData) getProducts()
  }
  if (window.innerWidth >= 768 && window.innerWidth < 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 1400 && limit.value !== 28) {
    limit.value = 28
    if (getData) getProducts()
  }
}

const openBackorderActionSelection = () => {
  if (!backorderActionSelection?.value?.openModal) return
  backorderActionSelection?.value?.openModal()
}

const openInfoClientsCredit = () => {
  modalRefInfoClientsCredit.value.showModal()
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', sizeWindow)
})

onMounted(() => {
  if (isClient.value && !requireChangePassword.value) {
    getOrders()
  }
  searchValue.value = ''
  window.addEventListener('resize', sizeWindow)
  sizeWindow(false)
  if (!getBalanceLogicState.value && !isClientFastenal.value) {
    getBannerOptions()
    getSections()
  }
  if (getBalanceLogicState.value || isClientFastenal.value) {
    getProducts()
  }
  if (isClientFastenal.value) {
    getSections()
  }
  if (
    getBalanceLogicState.value &&
    !isAlreadyReadCreditInfo.value &&
    !requireChangePassword.value &&
    isBalanceActive.value
  ) {
    infoVideoModalClientCredit.value.showModal()
  }
})
</script>

<template>
  <div>
    <InfoVideo
      ref="infoVideoModal"
      :videoSrcProps="'https://www.youtube.com/embed/FMUxnAm71Tg?autoplay=1'"
      :actionHideModal="openBackorderActionSelection"
      hideButtonContinue
      hasActionHideModal
    />
    <InfoVideo
      ref="infoVideoModalClientCredit"
      :videoSrcProps="'https://www.youtube.com/embed/PcukFxM-6WM?autoplay=1'"
      :actionHideModal="openInfoClientsCredit"
      hideButtonContinue
      hasActionHideModal
    />
    <BackorderActionSelection
      ref="backorderActionSelection"
      :totalOrders="totalOrders"
      :orders="orders"
    />
    <template v-if="canViewProductsByClientNum">
      <transition name="fade">
        <SelectClientComponent
          ref="selectClientComponent"
          @clientSelectedAction="getProductsByClient"
        />
      </transition>
      <template v-if="clientSelected">
        <OptionsMenuComponentCarousel
          v-if="!getBalanceLogicState"
          :configOptions="optionsMenuConfig"
          class="mb-3 d-none d-md-none d-lg-flex"
          @goTo="changeActiveView"
          :showCategories="true"
        />
        <CarouselComponent v-if="!getBalanceLogicState" :banner_options="banner_options" />
        <ProductList
          paginationTop
          paginationBottom
          :products="products"
          :categorySelected="categorySelected"
          :limit="limit"
          :total="total"
          @getProducts="getProducts"
        />
      </template>
    </template>
    <template v-if="!canViewProductsByClientNum">
      <OptionsMenuComponentCarousel
        v-if="!getBalanceLogicState"
        :configOptions="optionsMenuConfig"
        class="mb-3 d-none d-md-none d-lg-flex"
        @goTo="changeActiveView"
        :showCategories="true"
      />
      <CarouselComponent
        v-if="!getBalanceLogicState && !isClientFastenal"
        :banner_options="banner_options"
      />
      <ProductList
        paginationTop
        paginationBottom
        :products="products"
        :categorySelected="categorySelected"
        :limit="limit"
        :total="total"
        @getProducts="getProducts"
      />
    </template>

    <ModalInfoClientsCredit v-if="getBalanceLogicState" ref="modalRefInfoClientsCredit" />
  </div>
</template>
