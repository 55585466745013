import { ref, watch } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export function useVersionCheck(
  initialHash = '{{POST_BUILD_ENTERS_HASH_HERE}}',
  checkInterval = 1000 * 60 * 2
) {
  const currentTimestamp = ref(initialHash)
  const timestampChanged = ref(false)
  const newTimestamp = ref('')

  const checkVersion = async (url) => {
    try {
      const response = await axios.get(`${url}?t=${new Date().getTime()}`)
      newTimestamp.value = response.data.timestamp
      timestampChanged.value =
        currentTimestamp.value !== newTimestamp.value &&
        currentTimestamp.value !== '{{POST_BUILD_ENTERS_HASH_HERE}}'
      if (timestampChanged.value || currentTimestamp.value === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        currentTimestamp.value = newTimestamp.value
      }
    } catch (error) {
      console.error('Error checking version:', error)
    }
  }

  watch(timestampChanged, (newVal) => {
    if (newVal) {
      Swal.fire({
        title: 'Actualización disponible',
        text: 'Una nueva versión está disponible. Por favor, recarga la página.',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Recargar',
      }).then((result) => {
        if (result.isConfirmed) {
          reloadApp()
        }
      })
    }
  })

  const initVersionCheck = (url) => {
    checkVersion(url) // First check immediately
    setInterval(() => {
      checkVersion(url)
    }, checkInterval)
  }

  const reloadApp = () => {
    currentTimestamp.value = newTimestamp.value
    window.location.reload(true)
  }

  return {
    timestampChanged,
    initVersionCheck,
    reloadApp
  }
}
