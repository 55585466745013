<template>
  <div class="card card_product border-0 p-2 my-3 h-100">
    <div class="card-body d-flex align-items-center justify-content-center">
      <div class="row">
        <div class="col-12 col-md-4 mb-3 d-flex justify-content-center align-content-center">
          <img class="imgCardDetailProduct rounded m-auto" :src="product.image" alt="" />
        </div>
        <div class="col-12 col-md-8">
          <h4 class="mb-1" v-text="product.title"></h4>
          <p class="fw-normal" v-text="`Código: ${product.code}`"></p>
          <p class="fw-semibold mb-1" v-text="'Información del producto'"></p>
          <p class="fw-normal mb-0" v-text="`Clave de producto: ${product.key}`"></p>
          <p class="fw-normal" v-text="product.description"></p>
          <a
            class="fw-semibold text-decoration-underline text-black cursor-pointer"
            :href="product.data_sheet"
            target="_blank"
          >
            Ficha Técnica
          </a>
          <p class="fw-semibold mb-1">Cantidad: {{ product.quantity }}</p>
          <p class="fw-semibold mb-1">Precio Total: {{ product.price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  product: {
    type: Object,
    required: true
  }
})

const { product } = toRefs(props)
</script>

<style lang="scss">
.imgCardDetailProduct {
  max-height: 180px;
  width: auto;
}
</style>
