import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@/helpers/auth'
import { analytics } from '@/../firebaseConfig'

// Rutas criticas sin lazy loading
import SignUpView from '@/views/SignUpView.vue'
import HomeView from '@/views/HomeView.vue'
import ProductDetailView from '@/views/ProductDetailView.vue'
import ProductsByCategoryView from '@/views/ProductsByCategoryView.vue'
import ShoppingCartView from '@/views/ShoppingCartView.vue'
import MyAccountView from '@/views/MyAccountView.vue'
import HomeAgreementView from '@/views/HomeAgreementView.vue'
import LoginView from '@/views/LoginView.vue'
import SearchProducts from '@/views/SearchProducts.vue'

const environment = import.meta.env.VITE_ENVIRONMENT

const loadView = (view) => {
  if (view.includes('/')) {
    const [subdirectory, main] = view.split('/')
    return () =>
      import(/* webpackChunkName: "view-[request]" */ `@/views/${subdirectory}/${main}.vue`)
  }
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const publicRoutes = [
  {
    path: '/registro',
    name: 'signUp',
    component: SignUpView
  },
  {
    path: '/registro_prospectos',
    name: 'signUpProspect',
    component: loadView('SignUpViewProspects')
  },
  {
    path: '/aviso_de_privacidad_publico',
    name: 'privacy_policy_public',
    component: loadView('PrivacyPolicy')
  },
  {
    path: '/aviso_de_privacidad_publico_2',
    name: 'privacy_policy_public_2',
    component: loadView('PrivacyPolicy')
  },
  {
    path: '/terminos_y_condiciones_publico',
    name: 'terms_and_conditions_public',
    component: loadView('TermsAndConditions')
  },
  {
    path: '/terminos_y_condiciones_publico_2',
    name: 'terms_and_conditions_public_2',
    component: loadView('TermsAndConditions')
  },
  {
    path: '/acerca_de_nosotros_publico',
    name: 'about_public',
    component: loadView('AboutView')
  },
  {
    path: '/contacto_publico',
    name: 'contact_public',
    component: loadView('ContactView')
  },
  {
    path: '/job_board_publico',
    name: 'job_board_public',
    component: loadView('JobBoardPublic')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: loadView('NotFoundView')
  }
]

const protectedRoutes = [
  {
    path: '/acceso',
    name: 'login',
    component: LoginView
  },
  {
    path: '/acceso_prospectos',
    name: 'loginPropects',
    component: loadView('LoginProspectsView')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/producto_publico_contenido_paquete/:id',
    name: 'product_detail_public_pack_content',
    component: loadView('ProductDetailPackContentPublic')
  },
  {
    path: '/producto_publico/:id',
    name: 'product_detail_public',
    component: loadView('ProductDetailPublicView')
  },
  {
    path: '/producto_publico_solo_lista_paquete/:id',
    name: 'product_detail_only_list_pack_public',
    component: loadView('ProductDetailOnlyListPackPublicView')
  },
  {
    path: '/categoria_publica/:id',
    name: 'category_public',
    component: loadView('CategoryPublicView')
  },
  {
    path: '/ejemplo_correo_de_orden',
    name: 'example_mail_order',
    component: loadView('OrderMailPreview')
  },
  {
    path: '/aviso_de_privacidad',
    name: 'privacy_policy',
    component: loadView('PrivacyPolicy')
  },
  {
    path: '/terminos_y_condiciones',
    name: 'terms_and_conditions',
    component: loadView('TermsAndConditions')
  },
  {
    path: '/categoria/:id',
    name: 'productsByCategory',
    component: ProductsByCategoryView
  },
  {
    path: '/producto/:id',
    name: 'productDetail',
    component: ProductDetailView,
    props: true
  },
  {
    path: '/buscar_productos',
    name: 'searchProducts',
    component: SearchProducts
  },
  {
    path: '/carrito_de_compras',
    name: 'shoppingCart',
    component: ShoppingCartView
  },
  {
    path: '/acerca_de_nosotros',
    name: 'about',
    component: loadView('AboutView')
  },
  {
    path: '/sucursales',
    name: 'branches',
    component: loadView('BranchesView')
  },
  {
    path: '/contacto',
    name: 'contact',
    component: loadView('ContactView')
  },
  {
    path: '/orden/:id',
    name: 'orderDetail',
    component: loadView('OrderDetail')
  },
  {
    path: '/backorder/:id',
    name: 'backorder',
    component: loadView('OrderDetail')
  },
  {
    path: '/mi_cuenta',
    name: 'myAccount',
    component: MyAccountView
  },
  {
    path: '/convenio',
    name: 'agreement',
    component: HomeAgreementView,
    children: [
      {
        path: '/convenio/shopping_cart',
        name: 'agreement.shopping_cart',
        component: ShoppingCartView
      },
      {
        path: '/convenio/buscar_productos',
        name: 'agreement.search_products',
        component: SearchProducts
      },
      {
        path: '/convenio/producto/:id',
        name: 'agreement.productDetail',
        component: ProductDetailView,
        props: true
      }
    ]
  },
  {
    path: '/administrador',
    name: 'admin.users',
    component: loadView('admin/AdminHomeView'),
    children: [
      {
        path: 'client_account',
        name: 'admin.client_account',
        component: loadView('admin/ClientAccountView')
      },
      {
        path: 'productPdf',
        name: 'admin.product_pdf',
        component: loadView('admin/ProductPdfView')
      },
      {
        path: 'generatepdf',
        name: 'admin.generate_pdf',
        component: loadView('admin/PdfGeneratorView')
      },
      {
        path: 'clients',
        name: 'admin.clients',
        component: loadView('admin/HomeClientsView')
      },
      {
        path: 'orders/lista',
        name: 'admin.orders_list',
        component: loadView('admin/OrdersAdminView')
      },
      {
        path: 'convenio/events/list',
        name: 'admin.agreement_event_list',
        component: loadView('admin/AgreementsEventsAdminView')
      },
      {
        path: 'convenio/orders/lista',
        name: 'admin.agreement_orders_list',
        component: loadView('admin/OrdersAdminView')
      },
      {
        path: 'convenio/notascredito/lista',
        name: 'admin.agreement_credit_notes_list',
        component: loadView('admin/CreditNotesAdminView')
      },
      {
        path: 'convenio/informes',
        name: 'admin.agreement_reports',
        component: loadView('admin/AgreementReportsAdminView')
      },
      {
        path: 'cotizaciones/lista',
        name: 'admin.quoations_list',
        component: loadView('admin/OrdersAdminView')
      },
      {
        path: 'informes',
        name: 'admin.reports',
        component: loadView('admin/ReportsAdminView')
      },
      {
        path: 'activos',
        name: 'admin.client_active',
        component: loadView('admin/ClientActiveAdminView')
      },
      {
        path: '/orders/:id',
        name: 'admin.order_detail',
        component: loadView('admin/OrdersDetailAdminView')
      },
      {
        path: 'orders/callbooks',
        name: 'admin.orders',
        component: loadView('admin/OrdersCallbookView')
      },
      {
        path: 'products/list',
        name: 'admin.products_list',
        component: loadView('admin/ProductAdminView')
      },
      {
        path: 'convenio/products/list',
        name: 'admin.agreement_products_list',
        component: loadView('admin/ProductAdminView')
      },
      {
        path: 'categories/list',
        name: 'admin.categories_list',
        component: loadView('admin/CategoryAdminView')
      },
      {
        path: 'configuration',
        name: 'admin.configuration',
        component: loadView('admin/ConfigHomeView')
      }
    ]
  }
]

const routes = [...publicRoutes, ...protectedRoutes].map((route) => {
  if (!publicRoutes.includes(route)) {
    route.beforeEnter = authGuard
  }
  return route
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

if (environment === 'production') {
  router.afterEach((to) => {
    if (window.gtag) {
      window.gtag('config', analytics.measurementId, {
        page_path: to.fullPath
      })
    }
  })
}

export default router
