<template>
  <div>
    <OptionsMenuComponentCarousel
      :configOptions="optionsMenuConfig"
      class="mb-3 d-none d-md-none d-lg-flex"
      @goTo="changeActiveView"
    />
    <ModalSelectAgreement ref="modalSelectAgreement" credit />
    <ProductList
      paginationTop
      paginationBottom
      :products="products"
      :categorySelected="categorySelected"
      :limit="limit"
      :total="total"
      @getProducts="getProducts"
    />
  </div>
</template>

<script setup>
import ModalSelectAgreement from '@/components/agreement/ModalSelectAgreement.vue'
import OptionsMenuComponentCarousel from '@/components/shared/OptionsMenuComponentCarousel.vue'
import ProductList from '@/components/products/ProductList.vue'
import { instance, factories } from '@/api'
import router from '@/router'
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useShoppingStore } from '@/stores/shopping'

const api = factories(instance, ['product', 'category'])
const route = useRoute()
const optionsMenuConfig = ref([])
const category_id = ref(null)
const userStore = useUserStore()
const shoppingStore = useShoppingStore()
const clientOptions = computed(() => userStore.getAgreementClientOptions)
const agreementProductTypeAddedToCart = computed(
  () => shoppingStore.getAgreementProductTypeAddedToCart
)

watch(route, () => {
  if (route.query?.agreement_id) {
    changeActiveView({ id: route.query?.agreement_id })
  } else {
    changeActiveView({ id: optionsMenuConfig.value[0].id })
  }
})

watch(category_id, (newValue) => {
  userStore.updateAgreementCategory(newValue)
})

const categorySelected = computed(() => {
  return optionsMenuConfig.value.find((option) => option.id === category_id.value)
})

const searchValue = computed({
  get: () => userStore.searchValue,
  set: (newValue) => {
    userStore.updateSearch(newValue)
  }
})

watch(searchValue, () => getProducts(1))

const products = ref([])
const page = ref(1)
const total = ref(0)
const limit = ref(20)
const modalSelectAgreement = ref(null)

function getProducts(pageSet = null) {
  page.value = pageSet ? pageSet : 1
  const payload = {
    limit: limit.value,
    page: page.value,
    search: searchValue.value,
    category_id: category_id.value
  }
  api.product.listStore(payload).then((res) => {
    products.value = res.data.products
    total.value = res.data.total
  })
}

function changeActiveView(section) {
  const id = parseInt(section.id)
  if (id === 99999) {
    modalSelectAgreement.value.showModal()
    return
  }
  const isDifferent = category_id.value !== id
  if (!isDifferent) return
  optionsMenuConfig.value.forEach((option) => {
    option.class_active = option.id === id
  })
  if (category_id.value !== id) {
    category_id.value = id
  }
  const someHasActive = optionsMenuConfig.value.some((option) => option.class_active)
  if (!someHasActive) {
    optionsMenuConfig.value[0].class_active = true
    category_id.value = optionsMenuConfig.value[0].id
  }
  getProducts(1)
}

function getSections() {
  api.category.index_sections().then((res) => {
    optionsMenuConfig.value = res.data.categories.filter((category) => category.agreement_id)
    userStore.updateAgreementCategories(
      optionsMenuConfig.value.map((category) => category.id),
      optionsMenuConfig.value
    )
    const agreement_id = optionsMenuConfig.value[0].id
    optionsMenuConfig.value.push({
      id: 99999,
      name: 'Nota de crédito',
      section: true,
      active: true,
      agreement_id: agreement_id,
      option_type: 'credit_note'
    })
    optionsMenuConfig.value = optionsMenuConfig.value.filter((option) => clientOptions.value.includes(option.option_type))
    if (agreementProductTypeAddedToCart.value) {
      changeActiveView({ id: agreementProductTypeAddedToCart.value })
      return
    }
    const routeAgreementId = route.query?.agreement_id
    if (routeAgreementId) {
      changeActiveView({ id: routeAgreementId })
    } else {
      changeActiveView(optionsMenuConfig.value[0])
    }
  })
}

function sizeWindow(getData = true) {
  if (router.currentRoute.value.name !== 'agreement') return
  if (window.innerWidth <= 767 && limit.value !== 14) {
    limit.value = 14
    if (getData) getProducts()
  }
  if (window.innerWidth >= 768 && window.innerWidth < 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 1400 && limit.value !== 28) {
    limit.value = 28
    if (getData) getProducts()
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', sizeWindow)
})

onMounted(() => {
  searchValue.value = ''
  window.addEventListener('resize', sizeWindow)
  sizeWindow(false)
  getSections()
  if (categorySelected.value) {
    getProducts()
  }
})
</script>
