export default (axios) => ({
  // CRUD functions
  list(data = {}) {
    return axios.get('v1/banner_options', { params: data })
  },
  create(data) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axios.post('v1/banner_options', data, { headers })
  },
  update(id, data) {
    return axios.put(`v1/banner_options/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/banner_options/${id}`)
  },

  // No CRUD functions
  toggle_banner_option(id) {
    return axios.put(`v1/banner_options/${id}/toggle_banner_option`)
  }
})
