<template>
  <div>
    <AgreementView v-if="$route.name === 'agreement'" />
    <router-view v-else />
  </div>
</template>

<script setup>
import { onBeforeRouteLeave } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useShoppingStore } from '@/stores/shopping'
import { useUserStore } from '@/stores/user'
import AgreementView from '@/views/AgreementView.vue'
import Swal from 'sweetalert2'
import { computed, ref } from 'vue'
import { instance, factories } from '@/api'
import router from '@/router'

const api = factories(instance, ['user'])
const countLeave = ref(0)
const authStore = useAuthStore()
const userStore = useUserStore()
const isBalanceActive = computed(() => userStore.isBalanceActive)
const isLoggedIn = computed(() => authStore.isLoggedIn)
const shoppingStore = useShoppingStore()
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const clickOnAddOrFavorite = computed({
  get: () => shoppingStore.getCardClickPostion,
  set: (newValue) => {
    shoppingStore.updateCardClickPostion(newValue)
  }
})

const updateProfile = async () => {
  return Promise.all([
    api.user.profile().then((response) => {
      userBalance.value = response?.data?.balance || 0
      userStore.updateUserInfo(response, false)
    })
  ])
}

onBeforeRouteLeave(async (to, from, next) => {
  countLeave.value++
  if (countLeave.value > 1) {
    next()
    return
  }
  await updateProfile()

  // Guardar el next callback para llamarlo manualmente después
  const navigate = next
  next(false)
  // Verificar si es necesario cancelar la navegación debido a un clic en agregar/favorito
  if (clickOnAddOrFavorite.value) {
    clickOnAddOrFavorite.value = false
    navigate(false)
    return
  }
  

  // Verificar si estamos saliendo de la sección de "agreement"
  const isLeavingAgreementRoute =
    from?.name?.includes('agreement') && !to?.name?.includes('agreement')

  if (isLoggedIn.value && isLeavingAgreementRoute && isBalanceActive.value) {
    Swal.fire({
      title: '¿Salir del Convenio?',
      html: `
        <div style="text-align: left;">
          <p>
            <strong>Nota:</strong> 
            Los productos añadidos en <strong>Convenio</strong> solo estarán disponibles en esa sección y 
            no se mezclarán con el carrito general.
          </p>
          <p>
            Al salir del convenio, no podrás ver ni editar estos productos en el carrito general.
          </p>
          <p>
            ¿Deseas <strong>continuar</strong> para salir del convenio o 
            <strong>cancelar</strong> para seguir creando tu orden aquí?
          </p>
        </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fas fa-door-open"></i> Salir del convenio',
      cancelButtonText: '<i class="fas fa-times-circle"></i> Cancelar',
      reverseButtons: true,
      width: '600px'
    })
      .then((result) => {
        if (result.isConfirmed) {
          router.push(to) // Redirigir a la página de inicio
        } else {
          countLeave.value = 0
        }
      })
  } else {
    // Si no se cumplen las condiciones para mostrar el Swal, continuar con la navegación
    router.push(to) // Redirigir a la página de inicio
  }
})
</script>
