<template>
  <div>
    <InfoVideo ref="infoVideoModalBuy" :videoSrcProps="videoHelpUrl" />
    <div
      v-if="cartTotalPriceIsLessThanMinPurchase && !getBalanceLogicState"
      class="alert alert-warning text-center"
      role="alert"
      v-html="minPurchasePriceMessage"
    ></div>
    <EmptyCart v-if="cartProductContent?.length === 0" />
    <div v-else class="row g-2 g-lg-5">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="card border-0 shadow-sm rounded-4 p-4 min-vh-50">
          <div class="card-body">
            <h4 class="fw-medium text-black">
              {{ titleProductList }}
            </h4>
            <hr class="bg-tertiary-app-admin opacity-25 mb-4" />
            <div>
              <ProductCartList
                @showProductDetail="showProductDetail"
                @addOne="addOne"
                @changeAmount="changeAmount"
                @subtractOne="subtractOne"
                @removeProduct="removeProduct"
                splitProductListEnd
              />
            </div>
          </div>
        </div>
        <p class="mt-4 text-center">
          ¿Falta un producto?
          <router-link class="navbar-brand me-lg-5 text-decoration-none" :to="{ name: 'home' }">
            <u>
              {{ labelRequireMoreProduct }}
            </u>
          </router-link>
        </p>
        <p class="mt-2 mb-0 text-center" v-if="requireHelpVideo">
          ¿Necesitas ayuda?
          <span class="cursor-pointer" @click="showHelpBuyVideo"><u>Ver video</u></span>
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-6 position-relative">
        <transition name="fade">
          <SelectClientComponent
            ref="selectClientComponent"
            @clientSelectedAction="getClientInfo"
            v-if="userStore.canViewMyAccountByClientNum"
          />
        </transition>
        <transition name="fade">
          <div
            v-if="
              (!userStore.isClientProspect && !userStore.canViewMyAccountByClientNum) ||
              (userStore.canViewMyAccountByClientNum && clientSelected)
            "
            class="sticky-top z-1"
          >
            <div class="card border-0 shadow-sm rounded-4 p-4 min-vh-50">
              <div class="card-body">
                <h4 class="fw-medium text-black">
                  {{ titleInfoOrder }}
                </h4>
                <hr class="bg-tertiary-app-admin opacity-25 mb-0" />
                <div class="mt-3 mb-2" v-if="requireShowClientAddress">
                  <p class="mb-2 fw-semibold">Dirección de entrega:</p>
                  <p class="mb-1">
                    {{
                      `${addressesDelivery.street} ${addressesDelivery.num}, ${addressesDelivery.suburb}`
                    }}
                  </p>
                  <p
                    class="mb-1"
                    v-if="addressesDelivery.num_int && Number(addressesDelivery.num_int) !== 0"
                  >
                    {{
                      `${
                        addressesDelivery.num_int
                          ? 'Número interior: ' + addressesDelivery.num_int
                          : ''
                      }`
                    }}
                  </p>
                  <p class="mb-1">
                    {{ `${addressesDelivery.city_name}, ${addressesDelivery.state_name}` }}
                  </p>
                  <p class="mb-1" v-if="addressesDelivery.zip && addressesDelivery.zip !== 0">
                    {{ addressesDelivery.zip }}
                  </p>
                </div>
                <div class="pt-4">
                  <p class="mb-2 fw-semibold">Nombre:</p>
                  <p>{{ infoAccount?.brand_name || infoAccount?.name || 'Sin datos' }}</p>
                </div>
                <div>
                  <p class="mb-2 fw-semibold">Correo:</p>
                  <p>{{ infoAccount?.contact_email_web || infoAccount?.email || 'Sin datos' }}</p>
                </div>
                <div>
                  <p class="mb-2 fw-semibold">Móvil:</p>
                  <p>
                    {{ infoAccount?.contact_phone_web || infoAccount?.phone_number || 'Sin datos' }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="mb-2 fw-semibold">Referencia del cliente( orden de compra )</p>
                  <input type="text" v-model="reference" class="form-control" v-limit-chars="30" />
                </div>
                <div class="mb-5">
                  <p class="mb-2 fw-semibold">Comentarios:</p>
                  <textarea
                    v-model="comment"
                    style="resize: none"
                    class="form-control"
                    maxlength="120"
                    v-limit-chars="120"
                  ></textarea>
                  <small class="text-primary">Máximo 120 caracteres permitidos</small>
                </div>
                <div class="row">
                  <div class="col" v-if="!getBalanceLogicState">
                    <button
                      @click="generateQuotation"
                      v-if="!onlyQuotationsMinPurchase"
                      class="btn btn-hover-quotation bg-blue-400 w-100 text-white"
                    >
                      {{ quotationsBtn }}
                    </button>
                  </div>
                  <div
                    class="col"
                    v-if="
                      (!cartTotalPriceIsLessThanMinPurchase || getBalanceLogicState) &&
                      !onlyQuotations
                    "
                  >
                    <button @click="createOrder" class="btn btn-primary-app w-100 h-100">
                      Realizar pedido
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <ConfirmationModal ref="confirmationModalRed" :addressesDelivery="addressesDelivery || {}" />
    <ConfirmationQuotation ref="confirmationQuotation" :clientData="clientData" />
    <PromotionalProduct v-if="isNew" ref="promotionalProductRef" @sendOrder="continueCreateOrder" />
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import Swal from 'sweetalert2'
import { instance, factories } from '@/api'
import { useShoppingStore } from '@/stores/shopping'
import router from '@/router'
import ProductCartList from '@/components/products/ProductCartList.vue'
import ConfirmationModal from '@/components/admin/users/ConfirmationModal.vue'
import ConfirmationQuotation from '@/components/admin/users/ConfirmationQuotation.vue'
import EmptyCart from '@/components/shoppingcart/EmptyCart.vue'
import { useUserStore } from '@/stores/user'
import SelectClientComponent from '@/components/account/SelectClientComponent.vue'
import InfoVideo from '@/components/shared/InfoVideo.vue'
import PromotionalProduct from '@/components/shoppingcart/PromotionalProduct.vue'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/../firebaseConfig'
import { useConfigStore } from '@/stores/config'

const environment = import.meta.env.VITE_ENVIRONMENT
const configStore = useConfigStore()
const onlyQuotations = computed(() => configStore.onlyQuotations)
const onlyQuotationsMinPurchase = computed(
  () => configStore.onlyQuotations && cartTotalPriceIsLessThanMinPurchase.value
)
const shoppingCartViewConfig = computed(() => configStore.getShoppingCartViewConfig)
const minPurchasePriceMessage = computed(
  () => shoppingCartViewConfig.value?.minPurchasePriceMessage
)
const titleProductList = computed(() => shoppingCartViewConfig.value?.titleProductList)
const labelRequireMoreProduct = computed(
  () => shoppingCartViewConfig.value?.labelRequireMoreProduct
)
const requireHelpVideo = computed(() => shoppingCartViewConfig.value?.requireHelpVideo)
const quotationsBtn = computed(() => shoppingCartViewConfig.value?.quotationsBtn)
const titleInfoOrder = computed(() => shoppingCartViewConfig.value?.titleInfoOrder)
const requireShowClientAddress = computed(
  () => shoppingCartViewConfig.value?.requireShowClientAddress
)
const shoppingStore = useShoppingStore()
const userStore = useUserStore()
const promotionalProductRef = ref(null)

const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})

const infoVideoModalBuy = ref(null)
const videoHelpUrl = ref('')

const api = factories(instance, [
  'order',
  'client_address',
  'country',
  'state',
  'city',
  'clients',
  'user'
])

const comment = ref('')
const reference = ref('')
const confirmationModalRed = ref(null)
const confirmationQuotation = ref(null)
const infoAccount = computed({
  get: () => userStore.getFilterByClient['item'],
  set: (newValue) => {
    userStore.setFilterByClient('item', newValue, clientSelected.value)
  }
})
const clientData = ref({})
const cartProductContent = computed(() => shoppingStore.cartContent)

const cartTotalPriceIsLessThanMinPurchase = computed(
  () => shoppingStore.cartTotalPriceIsLessThanMinPurchase
)

const addressesDelivery = computed(() => {
  if (
    !infoAccount.value ||
    Object.keys(infoAccount.value).length === 0 ||
    !infoAccount.value?.addresses?.length
  )
    return false
  const address = infoAccount.value.addresses.find((address) => address.address_name === 'Entrega')
  return address || false
})
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const agreementOnCart = computed(() => shoppingStore.getAgreementProductTypeAddedToCart)
const nameAgreementOnCart = computed(() => userStore.getAgreementNameOnCart(agreementOnCart.value))
const isNew = computed(() => userStore.isNew)
const client_num = computed(() => userStore.client_num)
const email = computed(() => userStore.email)

const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

const showHelpBuyVideo = () => {
  videoHelpUrl.value = 'https://www.youtube.com/embed/k3aT1qgmFyA?autoplay=1'
  infoVideoModalBuy.value.showModal()
}

const removeProduct = (index) => {
  shoppingStore.deleteItem(index)
  Swal.fire({
    timer: 1000,
    position: 'top-end',
    showConfirmButton: false,
    text: 'Carrito vaciado',
    icon: 'success'
  })
}

const showProductDetail = (id) => {
  router.push({ name: 'productDetail', params: { id } })
}

const createOrder = () => {
  if (isNew.value) {
    promotionalProductRef.value.showModal()
  } else {
    continueCreateOrder()
  }
}

const continueCreateOrder = (product_id = false) => {
  const products = []
  let endpoint = 'orders'
  const keyByProductId = {
    9365: 'PROM-GORRA',
    5525: 'PROM-CAMISETA'
  }
  shoppingStore.cartContent.forEach((product) => {
    products.push({
      id: product.item.id,
      quantity: product.amount
    })
  })
  const payload = {
    products,
    comment: comment.value,
    reference: reference.value
  }
  if (getBalanceLogicState.value) {
    payload.agreement = nameAgreementOnCart.value
  }
  if (isNew.value) {
    payload.promo_products = [{ key: keyByProductId[product_id] }]
  }
  if (environment === 'production') {
    logEvent(analytics, 'custom_purchase_try', {
      client_num: client_num.value,
      email: email.value,
      products_id: products.map((x) => x.id)?.join(','),
      products_quantity: products.map((x) => x.quantity)?.join(','),
      total_order_price: shoppingStore.cartTotalPrice,
      promo_product: isNew.value ? product_id : false
    })
  }

  api.order
    .create(clientSelected.value, payload, endpoint)
    .then((response) => {
      shoppingStore.emptyCart()
      if (getBalanceLogicState.value || isNew.value) {
        updateProfile()
      }
      confirmationModalRed.value.showModal(response.data)
      if (environment === 'production') {
        logEvent(analytics, 'custom_purchase', {
          client_num: client_num.value,
          email: email.value,
          products_id: products.map((x) => x.id)?.join(','),
          products_quantity: products.map((x) => x.quantity)?.join(','),
          total_order_price: shoppingStore.cartTotalPrice,
          promo_product: isNew.value ? product_id : false
        })
      }
    })
    .catch((error) => {
      const { status, data } = error.response
      if (status === 400 && data?.products.length > 0) {
        shoppingStore.updateStocks(data.products)
      }
      if (environment === 'production') {
        logEvent(analytics, 'custom_purchase_error', {
          client_num: client_num.value,
          email: email.value,
          error_status: status,
          error_message: data.message,
          products_id: products.map((x) => x.id)?.join(','),
          products_quantity: products.map((x) => x.quantity)?.join(','),
          total_order_price: shoppingStore.cartTotalPrice,
          promo_product: isNew.value ? product_id : false
        })
      }
    })
}

const updateProfile = () => {
  api.user.profile().then((response) => {
    userBalance.value = response?.data?.balance || 0
    // Actualizo la información del usuario en el store y cambio la ruta
    userStore.updateUserInfo(response, false)
  })
}

const generateQuotation = ({
  client_name = '',
  client_email = '',
  client_phone_number = '',
  client_comment = ''
}) => {
  const products = []
  shoppingStore.cartContent.forEach((product) => {
    products.push({
      id: product.item.id,
      quantity: product.amount
    })
  })
  const payload = {
    products,
    comment: comment.value || client_comment,
    reference: reference.value,
    quotation: 1
  }
  if (userStore?.isSeller) {
    payload.client_name = client_name
    payload.client_email = client_email
    payload.client_phone_number = client_phone_number
    clientData.value = payload
  }
  api.order.create(clientSelected.value, payload).then(() => {
    shoppingStore.emptyCart()
    confirmationQuotation.value.showModal()
  })
}

const addOne = (id) => {
  shoppingStore.addOne(id)
}

const changeAmount = (id, amount) => {
  shoppingStore.changeAmount(id, amount)
}

const subtractOne = (id) => {
  shoppingStore.subtractOne(id)
}

const getClientInfo = () => {
  if (!clientSelected.value) return
  api.clients.info(clientSelected.value).then((res) => {
    infoAccount.value = res.data
  })
}

onMounted(() => {
  updateProfile()
  if (!userStore.isClientProspect && !userStore.canViewMyAccountByClientNum) {
    getClientInfo()
  }
})
</script>
