<template>
  <div>
    <div class="d-flex justify-content-end align-items-center" v-if="activeView === 'backorder'">
      <p class="m-0 ms-3 p-2 text-primary-app me-2 rounded-2">
        Selecciona las órdenes con backorder que deseas enviar al carrito
      </p>
    </div>
    <TableHelper :fields="tableFieldsByActiveView" :records="records">
      <template #cell(checkboxes)="data">
        <input
          type="checkbox"
          :id="'checkbox-' + data.record.id"
          :value="data.record.id"
          v-model="backOrderSelected[data.index]"
          @change="selectRecord(data.record.id)"
        />
      </template>
      <template #cell(channel)="record">
        <div v-if="record.channel">
          <img
            :src="svgByChannel(record.channel)"
            class="img-fluid"
            :title="channelLabel[record.channel]"
            alt=""
            :class="{
              'old-channel': record.channel === 'ecwid',
              'base-channel': record.channel !== 'ecwid'
            }"
          />
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(status)="record">
        <div
          class="badge bg-app rounded-3 p-2 px-3 fw-normal position-relative"
          :class="$actionsstatusbadge.getClass(record.status)"
          data-bs-toggle="tooltip"
        >
          <span>{{ $actionsstatusbadge.getLabel(record.status) }}</span>
          <div
            class="position-absolute float_element-right"
            title="Pedido de convenio"
            v-if="record.agreement"
          >
            <font-awesome-layers full-width class="fa-3x">
              <font-awesome-icon :icon="['fas', 'tag']" class="ms-4 ps-1 pb-2 text-danger" />
              <font-awesome-layers-text
                class="text-white ms-4 ps-1 pb-4"
                transform="shrink-11 up-1 down-1"
                :value="`C`"
              />
            </font-awesome-layers>
          </div>
        </div>
      </template>
      <template #cell(order_cres_num)="record">
        <span>{{ record.order_cres_num || '-' }}</span>
      </template>
      <template #cell(order_num)="record">
        <span>{{ record.order_num || '-' }}</span>
      </template>
      <template #cell(invoice_num)="record">
        <span>{{ record.invoice_num || '-' }}</span>
      </template>
      <template #cell(created_at)="record">
        <span v-if="record.channel === 'web'">{{ $formatdatetime(record.created_at) }}</span>
        <span v-else>{{ $formatdatetime(record.order_cres_date) }}</span>
      </template>
      <template #cell(invoice_date)="record">
        <span>{{ $formatdatetime(record.invoice_date, 'DD-MM-YYYY') || '-' }}</span>
      </template>
      <template #cell(total_invoice)="record">
        <span>{{
          $formatcurrency(
            statusNotTotalInovice.includes(record.status) || record.channel === 'ecwid'
              ? record.total_invoice
              : record.total
          )
        }}</span>
      </template>
      <template #cell(total)="record">
        <span>{{ $formatcurrency(record.total) }}</span>
      </template>
      <template #cell(used_balance)="record">
        <span>{{ $formatcurrency(record.used_balance) }}</span>
      </template>
      <template #cell(total_backorder)="record">
        <span>{{ $formatcurrency(record.total_backorder) }}</span>
      </template>
      <template #cell(actions)="record" v-if="!hidePointsMenu">
        <PointsMenu
          @action="actionPoint"
          :tableLength="records.length"
          :iconsRender="iconsRenderByStatus(record)"
          :info="record"
        />
      </template>
    </TableHelper>
    <div
      class="d-flex justify-content-between align-items-center"
      v-if="activeView === 'backorder'"
    >
      <button class="btn btn-primary-app text-start float-start" @click="emit('showHelp')">
        Ayuda
      </button>
      <div>
        <button
          class="btn btn-primary-app text-end float-end"
          :disabled="!hasAtLeastOneSelected"
          @click="actionOfSelectedByView('add')"
        >
          Enviar al carrito
        </button>
        <button
          class="btn btn-secondary text-end float-end me-2"
          :disabled="!hasAtLeastOneSelected"
          @click="actionOfSelectedByView('remove')"
        >
          Eliminar
        </button>
      </div>
    </div>
    <PaginationHelper
      v-if="!hidePagination"
      :showCountText="false"
      :activeClass="true"
      :justifyClass="'end'"
      :totalRecords="totalRecords"
      :limit="limit"
      @changePage="changePage"
    />
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue'
import { instance, factories } from '@/api'
import TableHelper from '@/components/shared/TableHelper.vue'
import PaginationHelper from '@/components/shared/PaginationHelper.vue'
import WebSVG from '@/assets/images/shared/channels_icons/web.svg'
import TabletSVG from '@/assets/images/shared/channels_icons/tablet.svg'
import CustomerServiceSVG from '@/assets/images/shared/channels_icons/customerService.svg'
import PointsMenu from '@/components/shared/PointsMenu.vue'
import { useShoppingStore } from '@/stores/shopping'

const statusNotTotalInovice = ['invoiced', 'sent', 'delivered']
const shoppingStore = useShoppingStore()
const props = defineProps({
  totalRecords: { type: Number, required: true },
  activeView: { type: String, required: true },
  limit: { type: Number, required: true },
  info: { type: Object, required: false, default: () => ({}) },
  records: { type: Array, required: true },
  backOrderSelected: { type: Array, required: false, default: () => [] },
  hidePointsMenu: { type: Boolean, required: false, default: false },
  hidePagination: { type: Boolean, required: false, default: false }
})

const payloadBackorderIds = ref([])
const api = factories(instance, ['order'])

const emit = defineEmits(['action', 'changePage', 'bgBackorder', 'deactiveBackOrders', 'showHelp'])

const { totalRecords, limit, records, activeView, backOrderSelected, hidePointsMenu } =
  toRefs(props)

const channelLabel = {
  web: 'Web',
  ecwid: 'Web-Ecwid',
  seller: 'Vendedor',
  customer_service: 'Atención al Cliente'
}

const tableFields = [
  {
    key: 'channel',
    label: 'Canal',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'order_num',
    label: 'Pedido',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'order_cres_num',
    label: 'N. Venta',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'created_at',
    label: 'Fecha de pedido',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'status',
    label: 'Estatus',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'invoice_num',
    label: 'N. Factura',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'invoice_date',
    label: 'Fecha de Factura',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'total_invoice',
    label: 'Total',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'actions',
    label: 'Acciones',
    thClass: 'text-center fw-normal text-app',
    tdClass: 'text-center align-middle'
  }
]

const tableFieldsByActiveView = computed(() => {
  if (activeView.value === 'backorder') {
    const returnFields = [
      ...tableFields,
      {
        key: 'checkboxes',
        label: 'Seleccionar',
        thClass: 'text-center fw-normal text-app',
        tdClass: 'text-center align-middle',
        requireIndex: true
      }
    ]
    returnFields[returnFields.length - 3].key = 'total_backorder'
    return returnFields.filter((field) => field.key !== 'actions' && field.key !== 'order_num')
  }
  if (hidePointsMenu.value) {
    return tableFields.filter((field) => field.key !== 'actions')
  }
  // if (isClientCredit.value) {
  //   const excludedFieldsByClientCredit = [
  //     'status',
  //     'order_cres_num',
  //     'invoice_num',
  //     'invoice_date',
  //     'total_invoice',
  //     'actions'
  //   ]
  //   let tableFieldsToReturn = tableFields.filter(
  //     (field) => !excludedFieldsByClientCredit.includes(field.key)
  //   )
  //   tableFieldsToReturn = [
  //     ...tableFieldsToReturn,
  //     {
  //       key: 'used_balance',
  //       label: 'Crédito utilizado',
  //       thClass: 'text-center fw-normal text-app',
  //       tdClass: 'text-center align-middle'
  //     },
  //     {
  //       key: 'total',
  //       label: 'Total',
  //       thClass: 'text-center fw-normal text-app',
  //       tdClass: 'text-center align-middle',
  //       sortable: true
  //     },
  //     {
  //       key: 'actions',
  //       label: 'Acciones',
  //       thClass: 'text-center fw-normal text-app',
  //       tdClass: 'text-center align-middle'
  //     }
  //   ]
  //   return tableFieldsToReturn
  // }
  return tableFields
})

const changePage = (page) => {
  emit('changePage', { page })
  window.scrollTo(0, 0)
}

const svgByChannel = (channel) => {
  if (!channel) {
    channel = 'web'
  }
  const channelObject = {
    customer_service: CustomerServiceSVG,
    seller: TabletSVG,
    web: WebSVG,
    ecwid: WebSVG
  }
  return channelObject[channel]
}

const actionOfSelectedByView = (action) => {
  if (activeView.value === 'backorder' && action === 'remove') {
    emit('deactiveBackOrders', { orders: payloadBackorderIds.value })
    return
  }
  if (activeView.value === 'backorder' && action === 'add') {
    addProductsToCart()
    emit('deactiveBackOrders', { orders: payloadBackorderIds.value })
    return
  }

  addProductsToCart()
}

const addProductsToCart = () => {
  if (payloadBackorderIds.value.length === 0) return
  api.order.backOrderProducts({ orders: payloadBackorderIds.value }).then((response) => {
    shoppingStore.addMultipleItems(response.data.products)
  })
}

const iconsRenderByStatus = (record) => {
  const { status, id } = record
  let newIconsRender = []
  const hasInvoice = ['invoiced', 'sent', 'delivered'].includes(status)
  const isFakeOrder = id.toString().includes('W000')
  newIconsRender.push({
    key: 'orderDetail',
    text: 'Ver detalle de pedido',
    icon: 'file',
    iconSize: 'lg',
    iconType: 'far',
    action: 'orderDetail'
  })
  if (hasInvoice) {
    newIconsRender.push({
      key: 'downloadInvoice',
      text: 'Factura Dinámica Ferretera',
      icon: 'file-pdf',
      iconSize: 'lg',
      iconType: 'far',
      action: 'downloadInvoice'
    })
    newIconsRender.push({
      key: 'downloadPDFXML',
      text: 'Descargar zip PDF & XML',
      icon: 'file-zipper',
      iconSize: 'lg',
      iconType: 'fa',
      action: 'downloadPDFXML'
    })
  }

  // if (backorder) {
  //   newIconsRender.push({
  //     key: 'backorder',
  //     text: 'Backorder',
  //     icon: 'eye',
  //     iconSize: 'lg',
  //     iconType: 'far',
  //     action: 'backorder',
  //     class: 'bg-yellow-100'
  //   })
  // }

  if (isFakeOrder) {
    newIconsRender = newIconsRender.filter((icon) => icon.key !== 'orderDetail')
  }

  return newIconsRender
}

const hasAtLeastOneSelected = computed(() => {
  return backOrderSelected.value.some((selected) => selected)
})

const resetSelectedBackorders = () => {
  payloadBackorderIds.value = []
}

const selectRecord = (id) => {
  payloadBackorderIds.value.push({ id })
}

const actionPoint = (action, info) => {
  emit('action', { action, info })
}

defineExpose({
  resetSelectedBackorders
})
</script>

<style lang="scss" scoped></style>
