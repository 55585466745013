<template>
  <transition name="slide">
    <div v-if="isVisible" class="sidebarSlide position-absolute start-0 top-0 w-100 bg-white z-2">
      <dl class="list-unstyled px-3 py-3">
        <li
          class="py-3 my-3 mx-4 px-4 text-black cursor-pointer fw-semibold"
          v-if="isClientProspect"
          @click="
            router.push({
              name: signUpProspect
            })
          "
        >
          Registrate
        </li>
        <li class="py-3 my-3 mx-4 px-4 text-black cursor-pointer fw-semibold" v-if="!isRoutePublic">
          <label for="username">Usuario:</label>
          {{ userName }}
          <span class="float-end" v-if="getBalanceLogicState">
            <span class="me-3">Saldo de bono:</span>
            <span class="float-end pulse-animation">{{
              remainingBalance < 0 ? 0 : $formatcurrency(remainingBalance)
            }}</span>
          </span>
        </li>
        <CategoriesComponent v-if="!isRoutePublic && !getBalanceLogicState && !isSeller" />
        <li
          v-if="!isRoutePublic && !isSeller"
          class="py-3 my-3 mx-4 px-4 text-black cursor-pointer optionHover"
          @click="goToRoute('home')"
          :class="{
            'text-white bg-primary-app fw-medium opacity-75': route?.name === 'home',
            'fw-light': route?.name !== 'home'
          }"
        >
          Tienda
        </li>
        <li
          v-if="!isRoutePublic && !isSeller"
          class="py-3 my-3 mx-4 px-4 text-black cursor-pointer optionHover"
          @click="goToRoute('shoppingCart')"
          :class="{
            'text-white bg-primary-app fw-medium opacity-75': route?.name === 'shoppingCart',
            'fw-light': route?.name !== 'shoppingCart'
          }"
        >
          Carrito
        </li>
        <li
          v-if="!isRoutePublic"
          class="py-3 my-3 mx-4 px-4 text-black cursor-pointer fw-light optionHover"
          @click="logout"
        >
          Salir
        </li>
      </dl>
    </div>
  </transition>
</template>

<script setup>
import CategoriesComponent from '@/components/layout/navigation/CategoriesComponent.vue'
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useConfigStore } from '@/stores/config'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useShoppingStore } from '@/stores/shopping'

const route = useRoute()
const router = useRouter()
const publicRoutesRequiredValidations = [
  'product_detail_public',
  'product_detail_only_list_pack_public',
  'category_public'
]
const shoppingStore = useShoppingStore()
const configStore = useConfigStore()
const userStore = useUserStore()
const authStore = useAuthStore()
const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const isClientProspect = computed(() => userStore.isClientProspect)
const isSeller = computed(() => userStore.isSeller)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)

const isVisible = computed({
  get: () => configStore.sidebarVisible,
  set: () => {
    configStore.toggleSidebar()
  }
})

const isRoutePublic = computed(() => publicRoutesRequiredValidations.includes(route.name))

const userName = computed(() => {
  if (!userStore.userInfo.name) return '---'
  return userStore.userInfo.name.substring(0, 40)
})

watch(isVisible, (newVal) => {
  if (newVal) {
    document.body.scrollTop = 0 // Para Safari
    document.documentElement.scrollTop = 0 // Para Chrome, Firefox, IE y Opera
    // document.body.classList.add('overflow-hidden')
    document.body.classList.add('bodyOverFlow')
  } else document.body.classList.remove('bodyOverFlow')
})

function logout() {
  authStore.logout()
}

function goToRoute(routeName) {
  if (isVisible.value) {
    isVisible.value = false
  }
  router.push({
    name: routeName
  })
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
