<script setup>
import LoadingComponent from '@/components/shared/LoadingComponent.vue'
import ModalSelectAgreement from '@/components/agreement/ModalSelectAgreement.vue'
import BaseLayout from '@/components/layout/BaseLayout.vue'
import AdminLayout from '@/components/layout/admin/AdminLayout.vue'
import ChangePassword from '@/components/admin/users/ChangePassword.vue'
import NotificationsComponent from '@/components/shared/notifications/NotificationsComponent.vue'
import useInternetStatus from '@/helpers/internetStatus'
import { useVersionCheck } from '@/helpers/useVersionCheck'
import { useLoadingStore } from '@/stores/loading'
import { useUserStore } from '@/stores/user'
import { computed, watch, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { instance, factories } from '@/api'
import { useShoppingStore } from '@/stores/shopping'
import { useAuthStore } from '@/stores/auth'
import { useConfigStore } from '@/stores/config'
import Swal from 'sweetalert2'

// helper to check if browser has internet connection
useInternetStatus()

const environment = import.meta.env.VITE_ENVIRONMENT

const { timestampChanged, initVersionCheck, reloadApp } = useVersionCheck()
const authStoreData = useAuthStore()
const isLogged = computed(() => authStoreData.isLoggedIn)
const routeLabels = {
  signUp: 'Registro',
  signUpProspect: 'Registro de Prospectos',
  privacy_policy_public: 'Aviso de Privacidad Público',
  privacy_policy_public_2: 'Aviso de Privacidad Público 2',
  terms_and_conditions_public: 'Términos y Condiciones Público',
  terms_and_conditions_public_2: 'Términos y Condiciones Público 2',
  about_public: 'Acerca de Nosotros Público',
  contact_public: 'Contacto Público',
  job_board_public: 'Bolsa de Trabajo Público',
  not_found: 'Página No Encontrada',
  login: 'Acceso',
  loginPropects: 'Acceso Prospectos',
  home: 'Inicio',
  product_detail_public_pack_content: 'Detalle del Producto - Contenido Paquete Público',
  product_detail_public: 'Detalle del Producto Público',
  product_detail_only_list_pack_public: 'Detalle del Producto - Solo Lista Paquete Público',
  category_public: 'Categoría Pública',
  example_mail_order: 'Ejemplo de Correo de Orden',
  privacy_policy: 'Aviso de Privacidad',
  terms_and_conditions: 'Términos y Condiciones',
  productsByCategory: 'Productos por Categoría',
  productDetail: 'Detalle del Producto',
  searchProducts: 'Buscar Productos',
  shoppingCart: 'Carrito de Compras',
  about: 'Acerca de Nosotros',
  branches: 'Sucursales',
  contact: 'Contacto',
  orderDetail: 'Detalle de Orden',
  backorder: 'Backorder',
  myAccount: 'Mi Cuenta',
  'admin.users': 'Administración de Usuarios',
  'admin.client_account': 'Cuenta del Cliente',
  'admin.product_pdf': 'PDF de Producto',
  'admin.generate_pdf': 'Generador de PDF',
  'admin.clients': 'Clientes',
  'admin.orders_list': 'Lista de Órdenes',
  'admin.agreement_orders_list': 'Lista de Órdenes de Convenio',
  'admin.quoations_list': 'Lista de Cotizaciones',
  'admin.reports': 'Informes',
  'admin.client_active': 'Clientes Activos',
  'admin.order_detail': 'Detalle de la Orden',
  'admin.orders': 'Órdenes',
  'admin.products_list': 'Lista de Productos',
  'admin.agreement_products_list': 'Lista de Productos de Convenio',
  'admin.categories_list': 'Lista de Categorías',
  'admin.configuration': 'Configuración'
}

const api = factories(instance, ['user'])

const route = useRoute()

const actionsUsersAppHome = ref(null)
const modalSelectAgreement = ref(null)

const isStoreView = computed(() => {
  const publicRoutes = [
    'example_mail_order',
    'privacy_policy_public',
    'product_detail_public_pack_content',
    'terms_and_conditions_public',
    'signUp',
    'signUpProspect',
    'loginPropects',
    'not_found',
    'admin.product_pdf'
  ]
  return !publicRoutes.includes(route?.name) && !route?.name?.includes('admin')
})

const isAdminView = computed(() => {
  return route?.name?.includes('admin') && route?.name !== 'admin.product_pdf'
})

const loadingStore = useLoadingStore()
const shoppingStore = useShoppingStore()
const userStore = useUserStore()
const configStore = useConfigStore()
const baseTitleByBrand = computed(() => configStore.getBaseTitleByBrand)
const agreementLoginShowInfo = computed(() => userStore.getAgrementLoginShowInfo)
const isClient = computed(() => userStore.isClient)
const getBalanceLogicState = computed(() => userStore.getIsAgreementActive)
const requireChangePassword = computed(() => userStore.requireChangePassword)
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})

const updateProfile = () => {
  api.user.profile().then((response) => {
    userBalance.value = response?.data?.balance || 0
    userStore.updateUserInfo(response, false)
  })
}

watch(userStore, (newVal) => {
  if (newVal?.userInfo?.change_password) {
    actionsUsersAppHome?.value?.showModal()
  }
})

watch(
  () => route.name,
  (newRouteName) => {
    userStore.updateCurrentRoute(newRouteName)
    const title = routeLabels[newRouteName] || 'Bienvenido'
    document.title = `${baseTitleByBrand.value} - ${title}`
    if (requireChangePassword.value) {
      actionsUsersAppHome.value?.showModal()
      return
    }
    if (
      isLogged.value &&
      !agreementLoginShowInfo.value &&
      getBalanceLogicState.value &&
      isClient.value
    ) {
      modalSelectAgreement?.value?.showModal()
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (requireChangePassword.value) {
    actionsUsersAppHome.value?.showModal()
    return
  }
  if (isLogged.value) {
    updateProfile()
    if (!agreementLoginShowInfo.value && getBalanceLogicState.value && isClient.value) {
      modalSelectAgreement?.value?.showModal()
    }
  }
  if (environment !== 'development') {
    initVersionCheck(`${window.location.origin}/version.json`)
  }

  if (timestampChanged.value) {
    Swal.fire({
      title: 'Actualización disponible',
      text: 'Una nueva versión está disponible. Por favor, recarga la página.',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Recargar'
    }).then((result) => {
      if (result.isConfirmed) {
        reloadApp()
      }
    })
  }
})
</script>

<template>
  <ModalSelectAgreement ref="modalSelectAgreement" />
  <NotificationsComponent />
  <LoadingComponent v-if="loadingStore.getLoaderCount > 0" />
  <template v-if="isStoreView">
    <BaseLayout />
  </template>
  <template v-else-if="isAdminView">
    <AdminLayout />
  </template>
  <template v-else>
    <router-view />
  </template>
  <ChangePassword ref="actionsUsersAppHome" />
  <!-- <ConfirmationModal /> -->
</template>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
