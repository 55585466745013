// Vue
import App from './App.vue'
import { createApp } from 'vue'

// Pinia
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

// Lottie
import Vue3Lottie from 'vue3-lottie'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Fontawesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import registerComponents from '@/fontawesome/icons'

// Router
import router from './router'

// Bootstrap
import 'bootstrap'

// Datepicker
import Datepicker from 'vue3-datepicker'
// PDF Vue 3
import vue3ToPdf from 'vue3-to-pdf'

// v-calendar
import { setupCalendar } from 'v-calendar'

import { formatDateTime } from '@/helpers/formatDate'
import { actionsStatusBadge } from '@/helpers/statusBadge'
import { initSentry } from './sentry' // Importa la configuración de Sentry
import directives from '@/helpers/directives'
import { formatCurrency } from '@/helpers/formatCurrency'
import Swal from 'sweetalert2'

import { setSiteConfig } from '@/helpers/setSiteConfig'

const environment = import.meta.env.VITE_ENVIRONMENT // Agrega esta línea

// Función para manejar errores específicos
function handlePreloadError(errorMessage) {
  if (
    errorMessage.includes('Unable to preload') ||
    errorMessage.includes('Failed to fetch dynamically imported module')
  ) {
    // Aquí puedes hacer la acción para salvar la app
    console.log('Error capturado: ', errorMessage)
    Swal.fire({
      title: 'Actualización disponible',
      text: 'Una nueva versión está disponible.',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Recargar'
    }).then(() => {
      window.location.reload(true)
    })
  }
}

// Configuración del manejador global de errores
window.onerror = function (message) {
  console.error('Error global capturado:', message)
  handlePreloadError(message)
  return false // Retornar `false` para que el error continúe siendo lanzado en consola
}

const app = createApp(App)

app.config.errorHandler = (err, vm, info) => {
  console.error('Error en Vue:', err, info)
  handlePreloadError(err.message || '')
}

// Register directives
app.use(directives)

// Register v-calendar
app.use(setupCalendar, {})

// Create currency constant function
const currency = Intl.NumberFormat('es-MX')
app.config.globalProperties.$currency = currency

app.component('v-select', vSelect)

app.config.globalProperties.$formatcurrency = formatCurrency
app.config.globalProperties.$formatdatetime = formatDateTime
app.config.globalProperties.$actionsstatusbadge = actionsStatusBadge

// Example
// {{ $formatcurrency(value) }}

// Create pinia
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(pinia)

// Set site config
setSiteConfig().then(() => {
  // Register lottie
  app.use(Vue3Lottie)

  // Register fontawesome
  registerComponents()
  app.component('font-awesome-icon', FontAwesomeIcon)
  app.component('font-awesome-layers', FontAwesomeLayers)
  app.component('font-awesome-layers-text', FontAwesomeLayersText)

  if (environment === 'production') initSentry(app, router) // Inicializa Sentry con la instrumentación de vue-router

  // Register router
  app.use(router)

  // Register Datepicker
  app.component('DatePicker', Datepicker)
  // Register PDF
  app.use(vue3ToPdf)

  // Mount
  app.mount('#app')
})
