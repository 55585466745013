import '@/assets/scss/main.scss' // Importa tu archivo SCSS
import { useConfigStore } from '@/stores/config'

const brandName = import.meta.env.VITE_BRAND_NAME

const loadConfig = async () => {
  const configStore = useConfigStore() // Ahora que Pinia está activo
  const response = await fetch(`/config/${brandName}.json`)
  const config = await response.json()
  configStore.setConfig(config)
}

// Configura los colores basados en la marca
const setBrandStyles = () => {
  const root = document.documentElement
  const configStore = useConfigStore() // Ahora que Pinia está activo
  const colors = configStore.getSiteConfigColors
  const requireLightenAndDarken = [
    '--color-primary',
    '--color-secondary',
    '--color-tertiary',
    '--color-tertiary-alternative',
    '--color-text-app-alternative'
  ]
  Object.keys(colors).forEach((key) => {
    const color = key
    const value = colors[key]
    root.style.setProperty(color, value)
    if (requireLightenAndDarken.includes(color)) {
      lightenColor(value, 10).then((lightenValue) => {
        root.style.setProperty(`${color}-light`, lightenValue)
      })
      darkenColor(value, 10).then((darkenValue) => {
        root.style.setProperty(`${color}-dark`, darkenValue)
      })
    }
  })
}

const darkenColor = async (hex, percent) => {
  // Asegurarse de que el hex esté en el formato correcto
  const num = parseInt(hex.slice(1), 16)

  // Calcular los valores RGB
  let R = (num >> 16) & 0xff
  let G = (num >> 8) & 0xff
  let B = num & 0xff

  // Aplicar el oscurecimiento proporcional
  R = Math.round(R * (1 - percent / 100))
  G = Math.round(G * (1 - percent / 100))
  B = Math.round(B * (1 - percent / 100))

  // Asegurarse de que los valores estén dentro del rango 0-255
  R = Math.max(0, Math.min(255, R))
  G = Math.max(0, Math.min(255, G))
  B = Math.max(0, Math.min(255, B))

  // Convertir de nuevo a formato hexadecimal
  return `#${((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1).toUpperCase()}`
}

const lightenColor = async (hex, percent) => {
  const num = parseInt(hex.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

// Llama a la función con el nombre de la marca
export const setSiteConfig = async () => {
  await loadConfig()
  await setBrandStyles()
  return true
}
