import Auth from './auth'
import User from './user'
import Clients from './clients'
import Product from './product'
import Price from './price'
import Contact from './contact'
import Order from './order'
import ClientAddress from './client_address'
import Location from './location'
import BannerOption from './banner_option'
import LoginImage from './login_image'
import Category from './category'
import Line from './line'
import Subline from './subline'
import Brand from './brand'
import Agreements from './agreements'

const globalFactories = {
  auth: Auth,
  user: User,
  clients: Clients,
  product: Product,
  price: Price,
  contact: Contact,
  order: Order,
  client_address: ClientAddress,
  location: Location,
  category: Category,
  banner_option: BannerOption,
  login_image: LoginImage,
  brand: Brand,
  line: Line,
  subline: Subline,
  agreements: Agreements
}

function getFactoriesWithClient(factories) {
  return (client) => {
    const factoriesWithClient = {}
    Object.keys(factories).forEach((key) => {
      factoriesWithClient[key] = factories[key](client)
    })
    return factoriesWithClient
  }
}

const factories = (client, selectedFactories = []) => {
  let responseFactories = null
  if (selectedFactories.length === 0) {
    responseFactories = getFactoriesWithClient(globalFactories)
  } else {
    const factoriesSelected = Object.fromEntries(
      Object.entries(globalFactories).filter(([key]) => selectedFactories.includes(key))
    )
    responseFactories = getFactoriesWithClient(factoriesSelected)
  }
  return responseFactories(client)
}

export default factories
