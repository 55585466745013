export default (axios) => ({
  // CRUD functions
  list(payload) {
    return axios.get('v1/users', { params: payload })
  },
  create(data) {
    return axios.post('v1/users', data)
  },
  bulk_create(data) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axios.post('v1/users/bulk_create', data, { headers })
  },
  update(id, data) {
    return axios.put(`v1/users/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/users/${id}`)
  },

  // NO-CRUD functions
  profile() {
    return axios.get('v1/users/profile')
  },
  change_password(id, payload) {
    return axios.put(`v1/users/${id}/change_password`, payload)
  },
  change_current_user_password(data) {
    return axios.post('v1/users/change_current_user_password', data)
  },
  recovery_password(data) {
    return axios.post('v1/users/recovery_password', data)
  },
  toggle_user(id, payload) {
    return axios.put(`v1/users/${id}/toggle_user`, payload)
  }
})
