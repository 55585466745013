<script setup>
import ProductCard from '@/components/products/ProductCard.vue'
import PaginationHelper from '@/components/shared/PaginationHelper.vue'
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const isSeller = computed(() => userStore.isSeller)
const textNotFound = computed(() => {
  if (props.categorySelected && !searchValue.value) {
    return `- No se encontraron productos en la categoría <b class='text-decoration-underline link-underline-danger'>${props.categorySelected.name}</b> -`
  }
  if (props.categorySelected && searchValue.value) {
    return `- No se encontraron productos en la categoría <b class='text-decoration-underline link-underline-danger'>${props.categorySelected.name}</b> con el término <b class='text-decoration-underline link-underline-danger'>${searchValue.value}</b> -`
  }
  if (!props.categorySelected && searchValue.value) {
    return `- No se encontraron productos con el término <b class='text-decoration-underline link-underline-danger'>${searchValue.value}</b> -`
  }
  return '- No se encontraron productos -'
})
const searchValue = computed(() => {
  return userStore.searchValue
})
const props = defineProps({
  categorySelected: {
    type: Object,
    required: false,
    default: null
  },
  paginationTop: {
    type: Boolean,
    required: false,
    default: false
  },
  paginationBottom: {
    type: Boolean,
    required: false,
    default: false
  },
  products: {
    type: Array,
    required: true
  },
  limit: {
    type: Number,
    required: true
  },
  total: {
    type: Number,
    required: true
  }
})
const emit = defineEmits(['getProducts'])
const activePage = ref(1)

function changePage(page) {
  emit('getProducts', page)
  activePage.value = page
  window.scrollTo(0, 0)
}
</script>

<template>
  <div class="row mb-5 mt-4" v-if="props.products.length > 0">
    <div
      v-for="(product, index) in props.products"
      :key="product + index"
      class="col-10 offset-1 col-sm-6 offset-sm-0 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-5"
    >
      <ProductCard :product="product" :hideAddBtn="isSeller" />
    </div>
    <PaginationHelper
      v-if="paginationBottom"
      :totalRecords="total"
      :showCountText="false"
      :activeClass="true"
      :justifyClass="'end'"
      :limit="limit"
      :externalPage="activePage"
      @changePage="changePage"
    />
  </div>
  <div class="min-vh-69" v-else>
    <div class="d-flex justify-content-center align-items-start vh-50">
      <h4 class="text-app mt-5" v-html="textNotFound"></h4>
    </div>
  </div>
</template>
