<template>
  <div class="d-md-block d-lg-none d-xl-none bg-white">
    <nav class="d-flex align-items-center bg-white d-md-flex d-lg-none d-xl-none pt-4">
      <div class="col-1 col-sm-2">
        <font-awesome-icon
          @click="toggleSidebar"
          class="text-app ms-4 scaleHover"
          :icon="['fa', 'bars']"
          size="lg"
        />
      </div>
      <div class="col-9 col-sm-8 text-center">
        <router-link class="navbar-brand ms-5 cursor-pointer" :to="{ name: 'home' }">
          <img
            :src="headerLogo"
            class="img-fluid cursor-pointer scaleHover headerLogo"
            alt="header-logo-company"
          />
        </router-link>
      </div>

      <div class="col-2 col-sm-2">
        <NavbarOptionsList onlyLastOption />
      </div>
    </nav>
    <hr class="col-12 text-primary-app" />
    <div class="m-auto w-70">
      <div class="d-flex justify-content-center">
        <SearchHelper
          v-if="isLoggedIn.value && !isRouteWithoutSearch.value"
          @search="search"
          aditionalInputClass="py-2"
          aditionalMainClass="flex-fill justify-content-center"
          :placeholder="'Buscar productos por código, clave, marcas...'"
          :colValue="12"
        />
        <div class="ms-3" v-else-if="isLoggedIn.value">
          <p class="m-0">
            Hola, <strong>{{ onlyTwoNames.value }}</strong>
            <span class="float-end" v-if="getBalanceLogicState.value">
              <span class="me-3">Saldo de bono:</span>
              <strong class="float-end pulse-animation">{{
                remainingBalance.value < 0 ? 0 : $formatcurrency(remainingBalance.value)
              }}</strong>
            </span>
          </p>
        </div>
      </div>
    </div>

    <hr class="col-12 text-app" />
  </div>
</template>

<script setup>
import SearchHelper from '@/components/shared/SearchHelper.vue'
import NavbarOptionsList from '@/components/layout/navigation/NavbarOptionsList.vue'
import { toRefs, computed, onMounted, ref } from 'vue'
import { useConfigStore } from '@/stores/config'

const props = defineProps({
  config: { type: Object, required: true }
})

const { config } = toRefs(props)
const configStore = useConfigStore()
const headerLogo = ref('')

const isLoggedIn = computed(() => config.value.isLoggedIn)
const isRouteWithoutSearch = computed(() => config.value.isRouteWithoutSearch)
const userName = computed(() => config.value.userName.value)
const remainingBalance = computed(() => config.value.remainingBalance)
const getBalanceLogicState = computed(() => config?.value?.getBalanceLogicState)
const search = config.value.search
const toggleSidebar = config.value.toggleSidebar
const onlyTwoNames = computed(() => userName.value.split(' ').slice(0, 2).join(' '))

// Al montar el componente, buscamos la imagen con el nombre y la extensión correcta
onMounted(async () => {
  const logoPath = await configStore.findLogoImage('headerLogo')
  headerLogo.value = logoPath || '@/assets/images/default.png'
})
</script>

<style lang="scss" scoped></style>
