// uppercase.js (Directiva Vue)
export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      event.target.value = event.target.value.toUpperCase()
      event.target.dispatchEvent(new Event('input')) // Emitir un evento input para actualizaciones en Vue
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
