import moment from 'moment-timezone'

const formatDateTime = (date, format = 'DD-MM-YYYY HH:mm') => {
  if (!date) return ''

  let validDate = null

  // Verificar si el valor es un número (epoch time)
  if (typeof date === 'number') {
    validDate = moment.unix(date)
  } else {
    // Preprocesar la fecha para convertir "13:06 PM" a "1:06 PM"
    const regex = /(\d{2}):(\d{2})\s(PM|AM)/
    const match = date.match(regex)
    if (match && parseInt(match[1], 10) > 12) {
      const hours = parseInt(match[1], 10) - 12
      const newTime = `${hours}:${match[2]} ${match[3]}`
      date = date.replace(regex, newTime)
    }

    // Define los formatos de fecha posibles
    const formats = [
      moment.ISO_8601,
      'YYYY-MM-DD', // Formato simple de fecha
      'YYYY/MM/DD', // Formato simple de fecha
      'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO formato específico
      'MMM D, YYYY, h:mm A', // Formato de fecha como "May 28, 2024, 12:05 PM"
      'DD-MM-YYYY HH:mm:ss', // Formato de fecha como "05-06-2024 17:41:39"
      'MM-DD-YYYY HH:mm:ss', // Formato de fecha como "05-06-2024 17:41:39"
      'YYYY-MM-DD HH:mm:ss', // Formato de fecha común con segundos
      'YYYY-MM-DD HH:mm', // Formato de fecha común sin segundos
      'MMMM D, YYYY, h:mm A' // Formato de fecha como "June 11, 2024, 1:06 PM"
    ]

    // Intentar analizar la fecha con cada formato
    for (const format of formats) {
      const parsedDate = moment(date, format, true)
      if (parsedDate.isValid()) {
        validDate = parsedDate
        break
      }
    }
  }

  if (!validDate) {
    return 'Invalid date' // Manejo de error para fechas no válidas
  }

  return validDate.tz('America/Mexico_City').format(format)
}

export { formatDateTime }
