<template>
  <div class="notification-container">
    <NotificationCard
      v-for="(item, index) in getAlerts"
      :key="item.id || item.message"
      :id="index"
      :icon="item.icon"
      :message="item.message"
      :permanent="item.permanent"
      :status="item.status"
      @hide="hideNotification"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useNotificationStore } from '@/stores/notification'
import NotificationCard from '@/components/shared/notifications/NotificationCard.vue'

const notificationStore = useNotificationStore()

const getAlerts = computed(() => notificationStore.getAlerts)

const hideNotification = (id) => {
  notificationStore.removeAlertByKey(id)
}
</script>
