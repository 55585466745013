// Axios
import axios from 'axios'

// Store
import { useAuthStore } from '@/stores/auth'
import { useLoadingStore } from '@/stores/loading'

// Refresh access endpoint
import { refreshAccessToken } from '@/helpers/auth'

// Success message
import { succesfullAlertsByUrl } from '@/constants/alertMessages'
// import { loaderBlackList, authHeaderBlackList } from '@/constants/auth'

// SweetAlerts
import Swal from 'sweetalert2'

// Axios backend url and headers
const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    Accept: 'application/json'
  },
  responseType: 'json'
})

// Interceptors for request
instance.interceptors.request.use((config) => {
  const authStoreData = useAuthStore()
  const loadingStoreData = useLoadingStore()
  loadingStoreData.startLoading()
  if (authStoreData.isLoggedIn) {
    config.headers.Authorization = `Bearer ${authStoreData.token}`
  }
  return config
})

// const endpoint = config.url.replace(config.baseURL, "");
// const foundAuth = authHeaderBlackList.find((element) =>
//   config.url.includes(element)
// );
// const found = loaderBlackList.find((element) => endpoint.includes(element));

// if (!foundAuth) {
//   const tokens = store.getters.getLoginTokens;
//   if (tokens) {
//     config.headers.Authorization = `Bearer ${tokens.access_token}`;
//   }
// }

// if (!found) {
//   store.dispatch("updateLoaderCount", +1);
// }
// return config;
// });

// Interceptors for response
instance.interceptors.response.use(
  (res) => {
    const loadingStoreData = useLoadingStore()
    let configUrl = res.config.url
    const lastIndex = configUrl.lastIndexOf('/')
    const lastPart = configUrl.slice(lastIndex)
    if (/\d/.test(lastPart)) {
      configUrl = configUrl.slice(0, lastIndex)
    }
    // const isInBlackList = blackList.includes(configUrl);
    const isRefreshToken =
      configUrl === '/oauth/token' && res.config.data?.includes('grant_type=refresh_token')
    // store.dispatch("updateLoaderCount", -1);
    if ((res.config.method === 'put' || res.config.method === 'post') && !isRefreshToken) {
      const successGeneralMessage = 'Acción realizada con éxito'
      let succesfullMessage = Object.keys(succesfullAlertsByUrl).includes(configUrl)
        ? succesfullAlertsByUrl[configUrl]
        : successGeneralMessage
      if (res.config.method === 'post') {
        succesfullMessage = succesfullMessage.replace('actualizada', 'creada')
        succesfullMessage = succesfullMessage.replace('actualizado', 'creado')
      }
      if (configUrl !== '/oauth/token') {
        if (res.config.method !== 'post' && !configUrl.includes('orders')) {
          Swal.fire({
            timer: 1500,
            showConfirmButton: false,
            text: succesfullMessage,
            icon: 'success'
          })
        }
      }
    }
    loadingStoreData.finishLoading()
    return res
  },
  (error) => {
    const loadingStoreData = useLoadingStore()
    const authStoreData = useAuthStore()
    loadingStoreData.finishLoading()
    const message = error.response?.data?.message
      ? error.response?.data?.message
      : 'Error en la aplicación'
    if (error.response?.status === 401 && !message) {
      // store.dispatch("updateAlerts", {
      //   content: `Error 401 en ${error.response.url}`,
      //   status: "error",
      // });
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        icon: 'error',
        title: 'Oops...',
        text: `Error 401 en ${error.response.url}`
      })
    } else {
      // store.dispatch("updateAlerts", {
      //   content: message,
      //   status: "error",
      // });
      Swal.fire({
        timer: 1500,
        showConfirmButton: false,
        icon: 'error',
        title: 'Oops...',
        text: message
      })
    }
    if (error.response && error.response.status === 401) {
      if (error.config.url === '/oauth/token') return Promise.reject(error)
      if (!authStoreData.token) {
        authStoreData.logout()
        return Promise.reject(error)
      }
      refreshAccessToken(authStoreData.refresh_token)
        .then((res) => {
          authStoreData.login(res)
          window.location.reload(true)
        })
        .catch(() => {
          authStoreData.$reset()
          authStoreData.logout()
        })
    }
    return Promise.reject(error)
  }
)

export default instance
