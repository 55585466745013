<template>
  <nav
    class="d-flex align-items-center bg-white d-none d-md-none d-lg-flex justify-content-between"
    :class="{ 'py-3': !isLoggedIn.value || isRouteWithoutSearch.value }"
  >
    <div class="d-flex align-items-center col-4 col-lg-3">
      <router-link class="navbar-brand ms-5" :to="{ name: 'home' }">
        <img :src="headerLogo" class="img-fluid cursor-pointer scaleHover headerLogo" alt="header-logo-company" />
      </router-link>
    </div>

    <div class="col-4">
      <SearchHelper
        v-if="isLoggedIn.value && !isRouteWithoutSearch.value"
        @search="search"
        aditionalInputClass="py-2"
        aditionalMainClass="my-4"
        :placeholder="'Buscar productos por código, clave, marcas...'"
        :colValue="12"
      >
        <template #beforeInput>
          <div class="ms-3">
            <p class="mb-1">
              Hola, <strong>{{ onlyTwoNames }}</strong>
              <span
                id="focusSaldoBono"
                class="float-end"
                v-if="agreementState.value"
              >
                <span class="me-3">Saldo de bono:</span>
                <strong class="float-end pulse-animation">
                  {{ remainingBalance.value < 0 ? 0 : $formatcurrency(remainingBalance.value) }}
                </strong>
              </span>
            </p>
          </div>
        </template>
      </SearchHelper>
      <div class="ms-3" v-else-if="isLoggedIn.value">
        <p class="m-0">
          Hola, <strong>{{ onlyTwoNames }}</strong>
          <span
            id="focusSaldoBono"
            class="float-end"
            v-if="agreementState.value"
          >
            <span class="me-3">Saldo de bono:</span>
            <strong class="float-end pulse-animation">
              {{ remainingBalance.value < 0 ? 0 : $formatcurrency(remainingBalance.value) }}
            </strong>
          </span>
        </p>
      </div>
    </div>

    <div class="col-4 text-end">
      <NavbarOptionsList />
    </div>
  </nav>
</template>

<script setup>
import SearchHelper from '@/components/shared/SearchHelper.vue'
import NavbarOptionsList from '@/components/layout/navigation/NavbarOptionsList.vue'
import { toRefs, computed, ref, onMounted } from 'vue'
import { useConfigStore } from '@/stores/config'

const configStore = useConfigStore()
const props = defineProps({
  config: { type: Object, required: true }
})

const { config } = toRefs(props)

const headerLogo = ref('')

// Al montar el componente, buscamos la imagen con el nombre y la extensión correcta
onMounted(async () => {
  const logoPath = await configStore.findLogoImage('headerLogo')
  headerLogo.value = logoPath || '@/assets/images/default.png'
})

const agreementState = computed(() => config.value.agreementState)
const isLoggedIn = computed(() => config.value.isLoggedIn)
const isRouteWithoutSearch = computed(() => config.value.isRouteWithoutSearch)
const userName = computed(() => config.value.userName.value)
const remainingBalance = computed(() => config.value.remainingBalance)
const search = config.value.search
const onlyTwoNames = computed(() => userName.value.split(' ').slice(0, 2).join(' '))
</script>

<style lang="scss" scoped></style>
