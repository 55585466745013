import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { useNotificationStore } from '@/stores/notification'
import { useConfigStore } from '@/stores/config'
import { useUserStore } from '@/stores/user'

export const useShoppingStore = defineStore('shop', {
  state: () => ({
    cart: reactive([]),
    cartAgreement: reactive([]),
    isVisible: false,
    userBalance: 0,
    hasUserBalanceLogic: false,
    agreementProductTypeAddedToCart: false,
    cardClickPostion: false
  }),
  getters: {
    getCardClickPostion: (state) => state.cardClickPostion,
    getAgreementProductTypeAddedToCart: (state) => state.agreementProductTypeAddedToCart,
    isThisProductInCart: (state) => (id) => {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      return isAgreementActive
        ? state.cartAgreement.some((cartItem) => cartItem.item.id === id)
        : state.cart.some((cartItem) => cartItem.item.id === id)
    },
    getAmountByID: (state) => (id) => {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const item = isAgreementActive
        ? state.cartAgreement.find((cartItem) => cartItem.item.id === id)
        : state.cart.find((cartItem) => cartItem.item.id === id)
      return item ? item.amount : 0
    },
    getBalanceLogicState() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      return this.hasUserBalanceLogic && isAgreementActive
    },
    getUserBalance(state) {
      return state.userBalance
    },
    getUserBalanceMinusTotal(state) {
      return state.userBalance - state.cartTotalPrice < 0
        ? 0
        : state.userBalance - state.cartTotalPrice
    },
    cartIsVisible(state) {
      return state.isVisible
    },
    cartTotalPriceIsLessThanMinPurchase(state) {
      const configStore = useConfigStore()
      const minimumPurchasePrice = configStore.getMinimumPurchasePriceByBrand
      return state.cartTotalPrice < minimumPurchasePrice
    },
    cartContent() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      return isAgreementActive ? this.cartAgreement : this.cart
    },
    cartTotalPrice() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      let total = 0
      if (cartToUse.length > 0) {
        cartToUse.forEach((cartItem) => {
          total += cartItem.item.price_discount * cartItem.amount
        })
      }
      return total
    },
    cartTotalDiscount() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      let total = 0
      if (cartToUse.length > 0) {
        cartToUse.forEach((cartItem) => {
          const price = cartItem.item.price
          const discount = price - cartItem.item.price_discount
          const totalDiscount = discount * cartItem.amount
          total += totalDiscount
        })
      }
      return total
    },
    cartSubTotalPrice() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      let subtotal = 0
      let price = 0
      if (cartToUse.length > 0) {
        cartToUse.forEach((cartItem) => {
          price = cartItem.item.price
          const withoutDiscount = price * cartItem.amount
          subtotal += withoutDiscount
        })
      }
      return subtotal
    },
    cartTotalItems() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      return cartToUse.length
    },
    cartProductsPayload() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      return cartToUse.map((cartItem) => ({
        id: cartItem.item.id,
        quantity: cartItem.amount
      }))
    },
    isEmptyCart() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      return cartToUse.length === 0
    }
  },
  actions: {
    updateCardClickPostion(value) {
      this.cardClickPostion = value
    },
    updateAgreementProductTypeAddedToCart(value) {
      this.agreementProductTypeAddedToCart = value
    },
    updateBalanceLogic(value) {
      this.hasUserBalanceLogic = value
    },
    updateUserBalance(newBalance) {
      this.userBalance = newBalance
    },
    showCart() {
      this.isVisible = true
    },
    hideCart() {
      this.isVisible = false
    },
    updateCartVisibility(visibility) {
      this.isVisible = visibility
    },
    emptyCart() {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      if (isAgreementActive) {
        this.cartAgreement = []
        this.updateAgreementProductTypeAddedToCart(false)
      } else {
        this.cart = []
      }
    },
    addItem(item, amount) {
      if (amount === 0) return

      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart

      if (isAgreementActive) {
        const isOutOfAlreadySelectedCategoryAgreement =
          this.isOutOfAlreadySelectedCategoryAgreement(item)
        if (isOutOfAlreadySelectedCategoryAgreement) return
        const isOutOfBalance = this.isOutOfBalance(item, amount)
        if (isOutOfBalance) return
      }
      const existingItem = cartToUse.find((cartItem) => cartItem.item.id === item.id)
      if (existingItem) {
        const isOutOfStock = this.isOutOfStock(item.stock, existingItem.amount + amount)
        if (isOutOfStock) return
      } else {
        cartToUse.push({ item, amount })
      }
      this.showSuccessMessage('Producto agregado')
    },
    addSingleItem(item, amount) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const existingItem = cartToUse.find((cartItem) => cartItem.item.id === item.id)
      if (existingItem) {
        this.showRepeatedItemMessage(item.title, item.code)
        return
      }
      cartToUse.push({ item, amount })
    },
    deleteItem(index) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      cartToUse.splice(index, 1)
      if (isAgreementActive && cartToUse.length === 0) {
        this.updateAgreementProductTypeAddedToCart(false)
      }
      this.showSuccessMessage('Producto eliminado')
    },
    changeAmount(id, amount) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const item = cartToUse.find((cartItem) => cartItem.item.id === id)
      if (item) {
        if (isAgreementActive) {
          if (this.isOutOfStock(item.item.stock, amount)) {
            item.amount = item.item.stock
            return
          }
          if (this.isOutOfBalance(item.item, amount)) return
        }
        item.amount = amount
        this.showSuccessMessage('Producto actualizado')
      }
    },
    changeAmountWithoutNotification(id, amount) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const item = cartToUse.find((cartItem) => cartItem.item.id === id)
      if (item) {
        if (isAgreementActive) {
          if (this.isOutOfStock(item.item.stock, amount)) return
          if (this.isOutOfBalance(item.item, amount)) return
        }
        item.amount = amount
      }
    },
    addMultipleItems(items) {
      items.forEach((item) => {
        this.addSingleItem(item, 1)
      })
      this.showSuccessMessage('Productos agregados')
    },
    labelAvailableStocks(stock) {
      return stock > 1 ? 'disponibles' : 'disponible'
    },
    updateStocks(items) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      items.forEach((item) => {
        const product = cartToUse.find((cartItem) => cartItem.item.id === item.id)
        if (product) {
          if (item.stock !== null && item.stock > 0 && product.amount > item.stock) {
            this.showErrorMessage(
              `Stock insuficiente <b>${product.item.code}-${product.item.title}</b>. Solo hay <b>${item.stock}</b> ${this.labelAvailableStocks(item.stock)}. Se actualizará la cantidad.`,
              true
            )
            product.amount = item.stock
            product.item.stock = item.stock
          }
          if (item.stock === 0) {
            this.showErrorMessage(
              `El producto <b>${product.item.code}-${product.item.title}</b> se ha agotado. Se eliminará del carrito.`,
              true
            )
            this.deleteItem(cartToUse.indexOf(product))
          }
        }
      })
    },
    addOne(id) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const item = cartToUse.find((cartItem) => cartItem.item.id === id)

      if (item) {
        if (isAgreementActive) {
          if (this.isOutOfStock(item?.item?.stock, item?.amount + 1)) return
          if (this.isOutOfBalance(item?.item, 1)) return
        }
        item.amount += 1
        this.showSuccessMessage('Producto actualizado')
      }
    },
    subtractOne(id) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const item = cartToUse.find((cartItem) => cartItem.item.id === id)
      if (item && item.amount > 0) {
        item.amount -= 1
        this.showSuccessMessage('Producto actualizado')
      }
      if (item.amount === 0) {
        this.deleteItem(cartToUse.indexOf(item))
      }
    },
    checkAmount(id) {
      const userStore = useUserStore()
      const isAgreementActive = userStore.agreementStateByRoute
      const cartToUse = isAgreementActive ? this.cartAgreement : this.cart
      const product = cartToUse.find((cartItem) => cartItem.item.id === id)
      return product ? product.amount : 0
    },
    isOutOfStock(stock, amount) {
      const isOutOfStock = stock !== null && amount > stock
      if (isOutOfStock) {
        this.showErrorMessage(`Stock insuficiente. Solo hay ${stock} disponibles.`)
        return true
      }
      return false
    },
    isOutOfBalance(item, amount) {
      const userStore = useUserStore()
      const souvenirsId = userStore.agreementCategories.find(
        (category) => category.option_type === 'souvenirs'
      ).id
      const remaingBalance = this.getUserBalanceMinusTotal
      const productTotalPrice = item.price_discount * amount
      const isSouvenir = item?.categories?.includes(souvenirsId)
      if (isSouvenir && remaingBalance < productTotalPrice) {
        this.showErrorMessage(
          `No tienes saldo suficiente para agregar este producto. Tu saldo actual es de $${remaingBalance}.`
        )
        return true
      }
      return false
    },
    isOutOfAlreadySelectedCategoryAgreement(item) {
      const userStore = useUserStore()
      const categoriesIds = userStore.agreementCategoriesIDs
      if (
        this.agreementProductTypeAddedToCart &&
        !item?.categories?.includes(this.agreementProductTypeAddedToCart)
      ) {
        this.showErrorMessage(
          'No puedes agregar productos de diferentes categorias de convenio en el mismo carrito.'
        )
        return true
      } else if (categoriesIds.some((id) => item?.categories?.includes(id))) {
        const categoryAgreementOnProduct = item?.categories?.find((category) =>
          categoriesIds.includes(category)
        )
        this.updateAgreementProductTypeAddedToCart(categoryAgreementOnProduct)
      }
      return false
    },
    showRepeatedItemMessage(title, code) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        permanent: true,
        status: 'warning',
        message: `<p class="fw-semibold mb-1">Producto repetido. Omitido</p>
        <small>El producto <span class="fw-semibold">${code}-${title}</span> ya se encuentra en el carrito, se <span class="fw-semibold">omitirá</span>.</small>`
      })
    },
    showSuccessMessage(message) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        status: 'success',
        icon: ['fa', 'circle-check'],
        message: `${message}`
      })
    },
    showErrorMessage(message, permanent = false) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        status: 'danger',
        icon: ['fa', 'circle-xmark'],
        message: `${message}`,
        permanent
      })
    }
  },
  persist: true
})
