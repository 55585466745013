<template>
  <div>
    <div
      class="row cursor-pointer sidebarOptions"
      @click="toggleDropdown"
      v-if="subOption"
      :class="{ active: subOption.key.includes($route.name) }"
    >
      <div class="col fw-medium">{{ subOption.label }}</div>
      <div class="col text-end">
        <font-awesome-icon icon="fa-solid fa-angle-down" />
      </div>
    </div>
    <transition name="fade">
      <div class="row mt-2" v-if="isDropdownOpen">
        <div class="col">
          <ul class="suboptions">
            <li
              class="suboptions-item"
              v-for="option in subOptionsByUser"
              :key="option.label"
              :class="{ active: option.key.includes($route.name) }"
            >
              <a @click="goToRoute(option.link)" class="text-app cursor-pointer">{{
                option.label
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import permissionsByUserType from '@/constants/config/permissions'

const configStore = useConfigStore()
const userStore = useUserStore()
const userType = computed(() => userStore.userType)
const isAgreementActive = computed(() => userStore.isAgreementActive)
const permissions = computed(() => {
  const permissionsBase = permissionsByUserType
  if (isAgreementActive.value) {
    permissionsBase.client.push('agreement')
    permissionsBase.client.push('agreement.shopping_cart')
    permissionsBase.client.push('agreement.search_products')
  }
  return permissionsBase[userType.value]
})

const router = useRouter()
const props = defineProps(['subOption'])
const { subOption } = toRefs(props)
const isDropdownOpen = ref(false)

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const subOptionsByUser = computed(() =>
  subOption.value.subMenuOptions.filter((option) =>
    option.key.some((key) => permissions.value.includes(key))
  )
)

function goToRoute(routeName) {
  configStore.toogleAdminSidebar()
  router.push({
    name: routeName
  })
}
</script>
