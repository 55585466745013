import * as Sentry from '@sentry/vue'

export function initSentry(app, router) {
  const dsn = import.meta.env.VITE_SENTRY_DSN
  const tracePropagationTargets = import.meta.env.VITE_TRACE_PROPAGATION_TARGETS.split(',')
  const environment = import.meta.env.VITE_ENVIRONMENT // Agrega esta línea

  Sentry.init({
    app,
    dsn,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment, // Agrega esta línea para configurar el entorno
    beforeSend(event) {
      // Ignorar errores 401 y Network Error
      if (
        event.request &&
        (event?.exception?.values[0]?.value === 'Request failed with status code 401' ||
          event?.exception?.values[0]?.value === 'Network Error')
      ) {
        return null
      }
      return event
    }
  })
}
