<template>
  <GeneralModal
    ref="modalRefSelectAgreement"
    size="lg"
    modalContentClass="p-5"
    @shown="onShown"
    @hidden="cancelAgreementSelection"
    hideHeaderClose
    hideHeader
    hideFooter
    noCloseOnBackdrop
    noCloseOnEsc
  >
    <template #bodyContent>
      <div class="row" v-if="!local">
        <h5 class="m-0">
          <font-awesome-icon icon="fa-solid fa-gift" class="text-success me-2" />
          ¡Felicidades, tienes un bono disponible de
          <strong>{{ $formatcurrency(userBalance) }}</strong
          >!
        </h5>
        <div class="mt-2 fw-semibold fs-5" v-if="clientOptions?.length > 1">
          Puedes aplicarlo exclusivamente en
          <span class="pulse-animation fw-bold">UNA</span> de las siguientes opciones que hemos
          preparado para ti. ¡Elige la que más te convenga y disfruta de los beneficios!
        </div>
        <p class="mt-2 text-muted" v-else>
          Puedes aplicarlo exclusivamente en la siguiente opción que hemos preparado para ti.
          ¡Disfruta de los beneficios!
        </p>
      </div>
      <div class="row" v-else>
        <h5>
          Selecciona las opciones que el cliente {{ clientInfo?.name }} -
          {{ clientInfo?.client_num }} podra elegir
        </h5>
      </div>

      <div class="row mt-4">
        <OptionCards
          ref="optionCardRef"
          :options="options"
          @optionSelected="onOptionSelected"
          :multiple="multiple"
        />
      </div>

      <transition name="fade-slide" appear v-if="!local">
        <div v-if="optionSelected" class="row mt-4 description-container">
          <div class="col-12">
            <h4 class="text-primary-app">{{ optionSelected.title }}</h4>
            <h5 class="mt-4">Descripción</h5>
            <p>{{ descriptionByOption[optionSelected.lottieKey] }}</p>
          </div>
        </div>
      </transition>

      <div class="row mt-4" v-if="local">
        <div class="col-12 text-end">
          <button class="btn btn-secondary me-2" @click="cancelAgreementSelection">Cancelar</button>
          <transition name="fade-slide" appear>
            <button
              v-if="optionSelected"
              class="btn btn-primary-app"
              @click="continueAgreementSelection"
            >
              {{ !local ? 'Continuar' : 'Aplicar' }}
            </button>
          </transition>
        </div>
      </div>
      <transition name="fade-slide" appear v-else>
        <div v-if="optionSelected" class="row mt-4">
          <div class="col-12 text-end">
            <button class="btn btn-primary-app" @click="continueAgreementSelection">
              {{ !local ? 'Continuar' : 'Aplicar' }}
            </button>
          </div>
        </div>
      </transition>
    </template>
  </GeneralModal>
</template>

<script setup>
import router from '@/router'
import GeneralModal from '@/components/shared/GeneralModal.vue'
import OptionCards from '@/components/shared/OptionCards.vue'
import { ref, toRefs } from 'vue'
import { useUserStore } from '@/stores/user'
import { useShoppingStore } from '@/stores/shopping'
import { instance, factories } from '@/api'
import { computed } from 'vue'
import Swal from 'sweetalert2'

const props = defineProps({
  credit: {
    type: Boolean,
    default: false
  },
  local: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  }
})

const { credit, local, multiple } = toRefs(props)
const api = factories(instance, ['product', 'category', 'agreements', 'user'])
const modalRefSelectAgreement = ref(null)
const userStore = useUserStore()
const clientOptions = computed(() => userStore.getAgreementClientOptions)
const shoppingStore = useShoppingStore()
const optionCardRef = ref(null)
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const emit = defineEmits(['actionSelect'])
const clientInfo = ref(null)

const optionsInit = ref([
  { title: 'Selecciona tu Nota de Crédito', lottieKey: 'credit_note' },
  { title: 'Aprovecha el 30% en Productos fuera de catálogo', lottieKey: 'discontinued' },
  { title: 'Souvenirs Truper', lottieKey: 'souvenirs' }
])

const options = ref([])

const optionSelected = ref(null)

const descriptionByOption = {
  credit_note:
    'Aplica tu nota de crédito disponible. ¡Es fácil y rápido!. El departamento de crédito procesará tu solicitud en los próximos días.',
  discontinued:
    'Última oportunidad para llevarte estos artículos únicos con un descuento exclusivo. ¡Hasta agotar existencias!',
  souvenirs:
    'Lleva contigo la calidad y el legado de Truper. Explora nuestra selección de artículos únicos que representan la excelencia de nuestra marca.'
}

const onOptionSelected = (option) => {
  optionSelected.value = null
  setTimeout(() => {
    if (local.value) {
      optionSelected.value = option.length === 0 ? null : option
      return
    } else {
      optionSelected.value = option
      userStore.updateAgreementCategory(option.id)
    }
  }, 50)
}

function getSections() {
  api.category.index_sections().then((res) => {
    const agreementCategories = res.data.categories.filter((category) => category.agreement_id)
    userStore.updateAgreementCategories(
      agreementCategories.map((category) => category.id),
      agreementCategories
    )
    options.value.map((option) => {
      option.id = agreementCategories.find(
        (category) => category.option_type === option.lottieKey
      )?.id
    })
  })
}

const onShown = () => {
  if (local.value) {
    options.value = optionsInit.value
    return
  }
  options.value = optionsInit.value.filter((option) =>
    clientOptions.value.includes(option.lottieKey)
  )
  if (credit.value) {
    optionCardRef.value.selectOption(
      options.value.find((option) => option.lottieKey === 'credit_note')
    )
  }
  getSections()
}

const cancelAgreementSelection = () => {
  optionSelected.value = null
  optionCardRef.value.resetSelection()
  hideModal()
}

const continueAgreementSelection = () => {
  if (local.value) {
    emit(
      'actionSelect',
      optionSelected.value.map((option) => option.lottieKey),
      clientInfo.value
    )
    return
  } else {
    if (optionSelected.value.lottieKey !== 'credit_note') {
      router.push({
        name: 'agreement',
        query: { agreement_id: optionSelected.value.id }
      })
    } else {
      selectCreditNote()
    }
    userStore.updateAgreementLoginShowInfo(true)
  }
  hideModal()
}

const updateProfile = () => {
  api.user.profile().then((response) => {
    userBalance.value = response?.data?.balance || 0
    userStore.updateUserInfo(response, false)
    router.push({
      name: 'home'
    })
  })
}

const selectCreditNote = () => {
  api.agreements.selectCreditNote().then(() => {
    updateProfile()
    Swal.fire({
      title: '¡Solicitud recibida!',
      html: `
    <p>Hemos recibido tu petición para aplicar la <strong>Nota de Crédito</strong>.</p>
    <p>El departamento de crédito procesará tu solicitud en los próximos días.</p>
  `,
      icon: 'success',
      confirmButtonText: 'Entendido'
    })
  })
}

const updateSelectedOptions = (loadOptions, infoClient) => {
  clientInfo.value = infoClient
  if (loadOptions.length === 0) return
  loadOptions.forEach((option) => {
    optionCardRef.value.selectOption(optionsInit.value.find((op) => op.lottieKey === option))
  })
}

const showModal = () => modalRefSelectAgreement.value.show()
const hideModal = () => modalRefSelectAgreement.value.hide()

defineExpose({ showModal, hideModal, updateSelectedOptions, cancelAgreementSelection })
</script>

<style scoped>
/* Animación para la transición */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

/* Contenedor de la descripción */
.description-container {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
