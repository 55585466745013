const formatCurrency = (value, locale = 'es-MX', currency = 'MXN', decimalCount = 2) => {
  if (isNaN(value) || value === null) {
    return ''
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount
  }).format(value)
}

export { formatCurrency }
