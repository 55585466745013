<template>
  <div class="card" :class="classCard" @click="actions(actionCard)">
    <div v-if="$slots.header" class="card-header" :class="classCardHeader">
      <slot name="header"></slot>
    </div>
    <div class="card-body" :class="classCardBody" @click.stop="actions(actionCardBody)">
      <slot name="body"></slot>
    </div>
    <div v-if="$slots.footer" class="card-footer" :class="classCardFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  classCard: {
    type: String,
    default: ''
  },
  classCardHeader: {
    type: String,
    default: ''
  },
  classCardBody: {
    type: String,
    default: ''
  },
  classCardFooter: {
    type: String,
    default: ''
  },
  actionCard: {
    type: Object,
    default: () => {}
  },
  actionCardBody: {
    type: Object,
    default: () => {}
  }
})

const { classCard, classCardHeader, classCardBody, classCardFooter, actionCard, actionCardBody } =
  toRefs(props)

const actions = (propAction) => {
  if (!propAction) return
  const { actionToPerfome, params } = propAction
  if (actionToPerfome) {
    actionToPerfome(params)
  }
}
</script>
