<template>
  <div>
    <div v-for="(product, index) in cartProductContent" :key="product.item.id + index">
      <hr v-if="splitProductListStart" />
      <div class="row my-5">
        <div class="col-3">
          <img
            @click="showProductDetail(product.item.id)"
            :src="product.item.image"
            class="img-fluid cursor-pointer"
            alt=""
          />
        </div>
        <div class="col-8">
          <p>{{ product.item.title }}</p>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div>
              <span class="fw-semibold">Codigo: {{ product.item.code }}</span>
              <p class="fw-semibold mb-0 mt-1" v-if="product.item.multiple">
                Múltiplo: {{ product.item.multiple }}
              </p>
            </div>
            <div>
              <span class="fw-semibold">Clave: {{ product.item.key }}</span>
            </div>
          </div>
          <div class="row align-items-center z-1">
            <div class="col-8 col-md-12">
              <div class="input-group mb-3">
                <button
                  class="btn btn-outline-secondary border z-1"
                  type="button"
                  @click="subtractOne(product.item.id)"
                >
                  <font-awesome-icon icon="fa-solid fa-minus" />
                </button>
                <input
                  type="number"
                  class="form-control text-center"
                  v-min-value
                  v-numeric
                  @keyup.enter="changeAmount(product.item.id, product.amount)"
                  @blur="changeAmount(product.item.id, product.amount)"
                  v-model="product.amount"
                />
                <button
                  class="btn btn-outline-secondary border z-1"
                  type="button"
                  @click="addOne(product.item.id)"
                >
                  <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mb-2"
              v-if="product.amount > 1 && product.item.discount > 0"
            >
              <div>
                <p class="text-decoration-line-through red-strike mb-2">
                  <span class="fw-semibold">Precio:</span>
                  {{ $formatcurrency(product.item.price) }}
                </p>
                <p class="m-0">
                  <span class="fw-semibold">Precio:</span>
                  {{ $formatcurrency(product.item.price_discount) }}
                </p>
              </div>
              <div>
                <p class="text-decoration-line-through red-strike m-0">
                  <span class="fw-semibold">Total: </span>
                  {{ $formatcurrency(product.item.price * product.amount) }}
                </p>
                <p class="m-0">
                  <span class="fw-semibold">Total: </span>
                  {{ $formatcurrency(product.item.price_discount * product.amount) }}
                </p>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mb-4"
              v-if="product.amount > 1 && product.item.discount <= 0"
            >
              <div>
                <span class="fw-semibold">Precio:</span>
                {{ $formatcurrency(product.item.price) }}
              </div>
              <div>
                <span class="fw-semibold">Total: </span>
                {{ $formatcurrency(product.item.price * product.amount) }}
              </div>
            </div>
            <div
              class="col-4 col-md-12 text-end"
              v-if="product.amount === 1 && product.item.discount > 0"
            >
              <p class="text-decoration-line-through red-strike">
                {{ $formatcurrency(product.item.price) }}
              </p>
              <p>{{ $formatcurrency(product.item.price_discount) }}</p>
            </div>
            <div
              class="col-4 col-md-12 text-end"
              v-if="product.amount === 1 && product.item.discount <= 0"
            >
              <p>
                <span class="fw-semibold">Precio:</span> {{ $formatcurrency(product.item.price) }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-1">
          <font-awesome-icon
            class="cursor-pointer text-app"
            icon="fa-solid fa-trash"
            size="lg"
            @click="removeProduct(index)"
          />
        </div>
      </div>
      <hr v-if="splitProductListEnd && index + 1 < cartProductContent.length" />
    </div>

    <div v-if="!hideOriginalFooter">
      <CartSideMenuFooter
        :configFooterContent="footerContent"
        :classFooter="'z-1'"
        noBtnContinue
        splitLineAfterTotal
      />
    </div>
  </div>
</template>

<script setup>
import { useShoppingStore } from '@/stores/shopping'
import { useConfigStore } from '@/stores/config'
import { computed, toRefs, getCurrentInstance } from 'vue'
import CartSideMenuFooter from '@/components/layout/cart/CartSideMenuFooter.vue'

const props = defineProps({
  hideOriginalFooter: { type: Boolean, required: false, default: false },
  splitProductListEnd: { type: Boolean, required: false, default: false },
  splitProductListStart: { type: Boolean, required: false, default: false }
})

const { hideOriginalFooter, splitProductListEnd, splitProductListStart } = toRefs(props)

const emit = defineEmits([
  'subtractOne',
  'addOne',
  'changeAmount',
  'removeProduct',
  'showProductDetail'
])

const globalProperties = getCurrentInstance().appContext.config.globalProperties
const formatCurrency = globalProperties.$formatcurrency

const shoppingStore = useShoppingStore()
const configStore = useConfigStore()

const sideCartMenuConfig = computed(() => configStore.getSideCartMenuConfig)
const footerValues = computed(() => sideCartMenuConfig?.value?.footerValues)
const creditLogicState = computed(
  () => isLogicCreditBalanceActive.value && getBalanceLogicState.value
)
const isLogicCreditBalanceActive = computed(() => configStore.getIsLogicCreditBalanceByUserActive)

const footerContent = computed(() => {
  const content = []
  let configData = []
  const discount = creditLogicState.value
    ? shoppingStore.cartTotalDiscount + (userBalance.value - remainingBalance.value)
    : shoppingStore.cartTotalDiscount

  const labelAndValue = {
    subtotal: {
      label: 'Subtotal',
      value: shoppingStore.cartSubTotalPrice
    },
    discount: {
      label: 'Descuento',
      classLabel: 'text-primary-app',
      classValue: 'text-primary-app',
      value: discount
    },
    total: {
      label: 'Total a pagar',
      classLabel: 'fw-bold',
      classValue: 'fw-bold',
      value: shoppingStore.cartTotalPrice
    },
    userBalance: {
      label: 'Saldo de bono',
      value: remainingBalance.value
    }
  }
  if (creditLogicState.value) {
    configData = ['userBalance', 'subtotal', 'discount', 'total']
  } else {
    configData = footerValues.value
  }
  configData.forEach((item) => {
    if (item === 'total' && creditLogicState.value) {
      content.push({
        label: labelAndValue[item].label,
        classLabel: labelAndValue[item]?.classLabel || '',
        value: formatCurrency(totalToPay.value < 0 ? 0 : totalToPay.value),
        classValue: labelAndValue[item]?.classValue || ''
      })
      return
    }
    content.push({
      label: labelAndValue[item].label,
      classLabel: labelAndValue[item]?.classLabel || '',
      value: formatCurrency(labelAndValue[item].value),
      classValue: labelAndValue[item]?.classValue || ''
    })
  })

  return content
})

const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const totalToPay = computed(() => shoppingStore.cartTotalPrice - userBalance.value)
const cartProductContent = computed(() => {
  return shoppingStore.cartContent
})

function subtractOne(id) {
  const amount = cartProductContent.value.find((product) => product.item.id === id).amount
  if (amount === 1) return
  emit('subtractOne', id)
}

function addOne(id) {
  emit('addOne', id)
}

function changeAmount(id, amount) {
  emit('changeAmount', id, amount)
}

function removeProduct(index) {
  emit('removeProduct', index)
}

function showProductDetail(id) {
  emit('showProductDetail', id)
}
</script>
