<template>
  <GeneralModal
    ref="modalRef"
    @shown="onShown"
    @hidden="onHideModal"
    title="Gracias por su cotización"
    :hideHeader="true"
    size="lg"
    modalContentClass="p-5 confirmationModal"
    hideHeaderClose
    hideFooter
  >
    <template #bodyContent>
      <div>
        <div class="d-flex align-items-center justify-content-center position-relative">
          <div>
            <span class="fs-2 fw-semibold text-black text-center"
              >¡Gracias por guardar su pedido!</span
            >
          </div>
          <div
            class="position-absolute"
            :class="{ lottieConfirmatons: !isBlocked, lottieConfirmatonsWarning: isBlocked }"
            v-if="isShown"
          >
            <Vue3Lottie
              ref="vue3LottieRef"
              :animationData="Warning"
              :loop="3"
              @onComplete="vue3LottieRef.destroy()"
              v-if="isBlocked"
            />
            <Vue3Lottie
              ref="vue3LottieRef"
              :loop="2"
              @onComplete="vue3LottieRef.destroy()"
              :animationData="Confetti"
              v-else
            />
          </div>
        </div>
        <hr />
        <div class="alert alert-danger fw-bold" role="alert" v-if="isBlocked">
          Por favor, revisa el estado de tu crédito. Tu pedido será procesado una vez que se aclare.
          En nuestros horarios de oficina.
        </div>
        <div class="mb-5 d-flex justify-content-center">
          <div class="col-12">
            <h4 class="fw-semibold">¿Tiene preguntas sobre su pedido guardado?</h4>
            <div class="fs-4">Estamos aquí para ti, haznos saber cómo podemos ayudarte.</div>
            <div class="fs-4">Tel: 8114113238</div>
            <div class="fs-4">Email:info@dinamicaenlinea.com</div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <button @click="goHome" class="fs-5 btn btn-primary-app mt-5 py-3 px-5 rounded">
            <span class="px-5 py-3">Volver a la página principal</span>
          </button>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>

<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import Confetti from '@/assets/lotties/Confetti.json'
import Warning from '@/assets/lotties/Warning.json'
import { ref, computed } from 'vue'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useRoute } from 'vue-router'

const route = useRoute()
const isAgreementRoute = computed(() => route.name.includes('agreement'))
const userStore = useUserStore()
const isBlocked = computed(() => userStore.isBlocked)
const isShown = ref(false)
const modalRef = ref(null)
const vue3LottieRef = ref(null)

const onHideModal = () => {
  router.push({ name: 'home' })
}

const showModal = () => {
  modalRef.value.show()
}

const hideModal = () => {
  isShown.value = false
  modalRef.value.hide()
}

const goHome = () => {
  modalRef.value.hide()
  if (isAgreementRoute.value) {
    router.push({ name: 'agreement' })
    return
  }
  router.push({ name: 'home' })
}

const onShown = () => {
  isShown.value = true
}

defineExpose({
  showModal,
  hideModal
})
</script>
