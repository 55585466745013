<template>
  <div>
    <div class="card border border-primary-app shadow-sm rounded-3 p-0 mt-5 position-relative">
      <div class="pb-4 px-4 bg-primary-app rounded-3 rounded-bottom-0 text-white">
        <p class="fw-semibold ms-3 pt-4 mb-0">Notas de cargo</p>
      </div>

      <div class="card-body px-1 m-0 pb-0 pt-2 position-relative">
        <TableHelper :fields="tableFields" :records="records">
          <template #cell(date_billing)="record">
            {{ $formatdatetime(record.date_billing, 'DD-MM-YYYY') }}
          </template>
          <template #cell(date_expiration)="record">
            {{ $formatdatetime(record.date_expiration, 'DD-MM-YYYY') }}
          </template>
          <template #cell(balance)="record"> {{ $formatcurrency(record.balance) }} </template>
        </TableHelper>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'
import TableHelper from '@/components/shared/TableHelper.vue'

const props = defineProps({
  info: { type: Object, required: true },
  records: { type: Array, required: true }
})

const { records } = toRefs(props)

const tableFields = [
  {
    key: 'date_billing',
    label: 'Fecha de factura',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'invoice_num',
    label: 'Referencia',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'date_expiration',
    label: 'Fecha de vencimiento',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'notes',
    label: 'Notas',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'balance',
    label: 'Saldo',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'days',
    label: 'Días transcurridos',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  }
]
</script>

<style lang="scss" scoped>
.status-circle {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.status-circle-2 {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}
</style>
