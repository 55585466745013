<template>
  <div
    class="row d-flex align-items-center"
    v-if="totalRecords > limit"
    :class="`justify-content-${justifyClass || 'between'}`"
  >
    <div v-if="showCountText" class="col-6 text-end">
      <p>
        <span class="text-app me-3">Mostrando:</span>
        <span class="text-alternative-app">{{ activePage }} de {{ totalPages }}</span>
      </p>
    </div>
    <div class="col-6">
      <nav aria-label="Page navigation">
        <ul
          class="pagination align-items-center"
          :class="`justify-content-${justifyClass || 'end'}`"
        >
          <li
            class="page-item me-1"
            v-if="totalPages > 1 && activePage > 1"
            :class="{ disabled: activePage === 1, customPageItem: activeClass }"
          >
            <button
              class="page-link"
              @click="firstPage"
              :disabled="activePage === 1"
              aria-label="Previous page"
              :class="{
                'text-primary-app': activeClass,
                'text-app': !activeClass,
                customPageActiveHover: activeClass
              }"
            >
              <font-awesome-icon icon="fa-solid fa-chevron-left" />
              <font-awesome-icon icon="fa-solid fa-chevron-left" />
            </button>
          </li>
          <li
            class="page-item me-4"
            :class="{ disabled: activePage === 1, customPageItem: activeClass }"
          >
            <button
              class="page-link"
              @click="prevPage"
              :disabled="activePage === 1"
              aria-label="Previous page"
              :class="{
                'text-primary-app': activeClass,
                'text-app': !activeClass,
                customPageActiveHover: activeClass
              }"
            >
              <font-awesome-icon icon="fa-solid fa-chevron-left" />
            </button>
          </li>
          <li
            v-for="page in activePages"
            :key="page"
            class="page-item mx-2"
            :class="{
              active: activePage === page && !activeClass,
              customPageActive: activePage === page && activeClass,
              customActiveClass: activePage === page && activeClass,
              customPageItem: activeClass
            }"
          >
            <button
              class="page-link"
              :class="{
                pageActive: activePage === page && !activeClass,
                'text-app': activePage !== page,
                customPageActive: activePage === page && activeClass,
                customPageActiveHover: activePage !== page && activeClass,
                'cursor-pointer': activePage !== page && activeClass,
                'border-0': activePage !== page || activeClass
              }"
              @click="goToPage(page)"
            >
              {{ page }}
            </button>
          </li>
          <li
            class="page-item ms-1"
            :class="{ disabled: activePage === totalPages, customPageItem: activeClass }"
          >
            <button
              class="page-link"
              :class="{
                'text-primary-app': activeClass,
                'text-app': !activeClass,
                customPageActiveHover: activeClass
              }"
              @click="nextPage"
              :disabled="activePage === totalPages"
              aria-label="Next page"
            >
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </button>
          </li>
          <li
            class="page-item ms-1"
            v-if="totalPages > 1 && activePage < totalPages"
            :class="{ disabled: activePage === totalPages, customPageItem: activeClass }"
          >
            <button
              class="page-link"
              @click="lastPage"
              :disabled="activePage === totalPages"
              aria-label="Next page"
              :class="{
                'text-primary-app': activeClass,
                'text-app': !activeClass,
                customPageActiveHover: activeClass
              }"
            >
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, watch } from 'vue'

const props = defineProps({
  totalRecords: { type: Number, required: true, default: 1 },
  limit: { type: Number, required: true, default: 1 },
  externalPage: { type: Number, required: false, default: 1 },
  activeClass: { type: Boolean, required: false, default: false },
  justifyClass: { type: String, required: false, default: 'end' },
  showCountText: { type: Boolean, required: false, default: true }
})

const { totalRecords, limit, externalPage, activeClass } = toRefs(props)
const totalPages = computed(() => Math.ceil(totalRecords.value / limit.value))
const activePage = ref(externalPage.value)

const emit = defineEmits(['changePage'])

watch(externalPage, (newPage) => {
  activePage.value = newPage
})

const activePages = computed(() => {
  const pages = []
  const maxPagesToShow = 5
  const halfMaxPages = Math.floor(maxPagesToShow / 2)

  let startPage = Math.max(activePage.value - halfMaxPages, 1)
  let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages.value)

  if (endPage - startPage + 1 < maxPagesToShow) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1)
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i)
  }

  return pages
})

const firstPage = () => {
  if (activePage.value > 1) {
    activePage.value = 1
    emit('changePage', activePage.value)
  }
}

const lastPage = () => {
  if (activePage.value < totalPages.value) {
    activePage.value = totalPages.value
    emit('changePage', activePage.value)
  }
}

const prevPage = () => {
  if (activePage.value > 1) {
    activePage.value--
    emit('changePage', activePage.value)
  }
}

const nextPage = () => {
  if (activePage.value < totalPages.value) {
    activePage.value++
    emit('changePage', activePage.value)
  }
}

const goToPage = (page) => {
  if (page !== activePage.value) {
    activePage.value = page
    emit('changePage', activePage.value)
  }
}

defineExpose({
  activePage
})
</script>
