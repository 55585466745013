// src/directives/phoneNumber.js
export default {
  beforeMount(el) {
    el.addEventListener('input', (event) => {
      const input = event.target.value
      const cleanedInput = input.replace(/\D/g, '') // Eliminar todo lo que no sea un dígito

      // Limitar a 10 dígitos
      if (cleanedInput.length > 10) {
        event.target.value = cleanedInput.slice(0, 10)
      } else {
        event.target.value = cleanedInput
      }

      // Emitir un evento input para actualizaciones en Vue
      if (input !== event.target.value) {
        event.target.dispatchEvent(new Event('input'))
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('input', null)
  }
}
