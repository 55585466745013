<template>
  <div class="row g-1">
    <div class="col-3" v-if="showCategories">
      <div ref="dropdownCategory" class="dropdown col me-2 h-100">
        <button
          class="btn w-100 btn-light bg-white h-100"
          type="button"
          data-bs-auto-close="outside"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <font-awesome-icon
            class="me-2"
            :class="{
              'text-primary-app': showCategories,
              'text-black': !showCategories
            }"
            :icon="['fa', 'bars']"
            size="lg"
          />
          Todas las categorias
        </button>
        <ul class="dropdown-menu allCategoriesMenu">
          <div>
            <li class="p-0 my-3 mx-4 text-black cursor-pointer fw-light">
              <ShowCategories :categories="allCategories" v-if="showAllCategories" />
            </li>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-9">
      <div
        id="categoryCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            :class="{ active: index === 0 }"
            v-for="(chunk, index) in chunkedOptions"
            :key="index"
          >
            <div class="d-flex justify-content-center">
              <div class="col me-2" v-for="option in chunk" :key="option.name">
                <button
                  @click="emit('goTo', option)"
                  class="btn w-100 h-100 min-h-214"
                  :class="classByState(option)"
                >
                  {{ option.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev visually-hidden"
          type="button"
          data-bs-target="#categoryCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next visually-hidden"
          type="button"
          data-bs-target="#categoryCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, computed, onMounted, toRefs } from 'vue'
import { instance, factories } from '@/api'
import ShowCategories from '@/components/layout/navigation/ShowCategories.vue'
import { useAuthStore } from '@/stores/auth'
import { Carousel } from 'bootstrap'

const authStore = useAuthStore()
const isLoggedIn = computed(() => authStore.isLoggedIn)
const api = factories(instance, ['category'])
const props = defineProps({
  configOptions: { type: Array, required: true, default: () => [] },
  classButtons: { type: String, required: false, default: 'btn-primary-app' },
  showCategories: { type: Boolean, required: false, default: false }
})

const { configOptions, classButtons } = toRefs(props)
const emit = defineEmits(['goTo'])
const route = useRoute()
const isHome = computed(() => route.name === 'home')
const allCategories = ref([])
const dropdownCategory = ref(null)
const showAllCategories = ref(false)

function getCategories() {
  if (!isLoggedIn.value) return
  api.category.all_categories().then((res) => {
    res.data.categories.forEach((category) => {
      category.active = true
    })
    allCategories.value = res.data.categories
  })
}

onMounted(() => {
  if (isHome.value) {
    dropdownCategory.value.addEventListener('shown.bs.dropdown', () => {
      showAllCategories.value = true
    })
    dropdownCategory.value.addEventListener('hide.bs.dropdown', () => {
      showAllCategories.value = false
    })
    getCategories()
  }

  // Inicializa el carousel
  const carouselElement = document.getElementById('categoryCarousel')
  // eslint-disable-next-line no-unused-vars
  const carouselInstance = new Carousel(carouselElement, {
    interval: 3000,
    ride: 'carousel'
  })
})

function classByState(option) {
  return option.class_active ? classButtons.value : 'btn-light bg-white'
}

const chunkedOptions = computed(() => {
  const numOptions = configOptions.value.length
  const chunkSize = 4

  // Si hay 4 o menos opciones, devolver un solo chunk
  if (numOptions <= chunkSize) {
    return [configOptions.value]
  }

  const chunks = []
  const extendedOptions = [...configOptions.value]

  // Calcular cuántas veces necesitamos repetir las opciones para tener al menos dos conjuntos completos
  const repeatCount = Math.ceil((chunkSize * 2) / numOptions)

  for (let i = 0; i < repeatCount; i++) {
    extendedOptions.push(...configOptions.value)
  }

  // Crear los chunks de 4 opciones cada uno
  for (let i = 0; i < extendedOptions.length; i += chunkSize) {
    chunks.push(extendedOptions.slice(i, i + chunkSize))
  }

  // Eliminar chunks adicionales para tener solo los necesarios para el efecto de carrusel infinito
  return chunks.slice(0, Math.ceil((numOptions + chunkSize - 1) / chunkSize) * 2)
})
</script>

<style lang="scss" scoped>
.min-h-214 {
  min-height: 80px;
}

.carousel-inner {
  display: flex;
  align-items: center;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px;
  height: 20px;
}

.carousel-item {
  transition: transform 0.5s ease-in-out;
}

.allCategoriesMenu {
  overflow-y: auto;
}
</style>
