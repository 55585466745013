export default (axios) => ({
  // CRUD
  list(payload) {
    return axios.get('v1/categories', { params: payload })
  },
  create(data) {
    return axios.post('v1/categories', data)
  },
  show(id, payload) {
    return axios.get(`v1/categories/${id}`, { params: payload })
  },
  update(id, data) {
    return axios.put(`v1/categories/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/categories/${id}`)
  },
  // No CRUD
  index_branches() {
    return axios.get('v1/categories/index_branches')
  },
  all_categories() {
    return axios.get('v1/categories/store_all')
  },
  index_sections() {
    return axios.get('v1/categories/index_sections')
  },
  category_add_product(data) {
    return axios.post('v1/category_add_product', data)
  },
  category_delete_product(data) {
    return axios.post('v1/category_delete_product', data)
  },
  toggle_active(id) {
    return axios.put(`v1/categories/${id}/toggle_active`)
  },
  toggle_section(id) {
    return axios.put(`v1/categories/${id}/toggle_section`)
  },
  update_order_categories(id, data) {
    return axios.put(`v1/categories/${id}/update_order`, data)
  },
  bulk_update_order_categories(data) {
    return axios.post(`v1/categories/bulk_update_order`, data)
  },
  update_order_products(data) {
    return axios.put(`v1/product_categories/update_order`, data)
  },
})
